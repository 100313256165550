import axios from '../http.interceptor'

export default {
  // 获取人员树列表
  getPersonTreeList: (params) => axios.post('/hss/tlemployee/tree', params),

  // 获取人员树列表
  getPersonDetailTreeList: (params) => axios.post('/hss/tlemployee/empltree', params),

  // 获取人员全部列表
  getPersonAllList: () => axios.post('/hss/tlemployee/getSelectList'),

  // 获取工种全部列表
  getWorkTypeAllList: (params) => axios.post('/hss/tlworktype/getSelectList',params),

  // 获取职位全部列表
  getPositionAllList: (params) => axios.post('/hss/tlposition/getSelectList',params),

  // 获取人员列表
  getPersonList: (params) => axios.post('/hss/tlemployee/getList', params),

  // 新增人员
  createPerson: (params) => axios.post('/hss/tlemployee/insert', params),

  // 编辑人员
  editPerson: (params) => axios.post('/hss/tlemployee/update', params),

  // 批量编辑人员
  editBatchPerson: (params) => axios.post('/hss/tlemployee/updatebyids', params),

  // 获取人员详情
  getPersonDetail: (params) => axios.post('/hss/tlemployee/getDetail', params),

  // 删除人员
  deletePerson: (params) => axios.post('/hss/tlemployee/deletebatchbyid', params),

  // 导出人员列表
  exportPerson: (params) => axios.post('hss/tlemployee/excelExport', params),

  // 导出人员模板
  exportPersonTem: (params) => axios.post('hss/tlemployee/excelExportTemplate', params),

  // 获取工种列表
  getWorkTypeList: (params) => axios.post('/hss/tlworktype/getList', params),

  // 新增工种
  createPersonProfession: (params) => axios.post('/hss/tlworktype/insert', params),

  // 编辑工种
  editPersonProfession: (params) => axios.post('/hss/tlworktype/update', params),

  // 获取工种详情
  getPersonProfessionDetail: (params) => axios.post('hss/tlworktype/getDetail', params),

  // 删除人员
  deletePersonProfession: (params) => axios.post('/hss/tlworktype/deletebyid', params),

  // 获取职位列表
  getPositionList: (params) => axios.post('/hss/tlposition/getList', params),

  // 新增职位
  createPosition: (params) => axios.post('/hss/tlposition/insert', params),

  // 编辑职位
  editPosition: (params) => axios.post('/hss/tlposition/update', params),

  // 获取职位详情
  getPositionDetail: (params) => axios.post('hss/tlposition/getDetail', params),

  // 删除职位
  deletePosition: (params) => axios.post('/hss/tlposition/deletebyid', params),
}