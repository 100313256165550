import vm from '../../main.js'

export default {
  state: {
    carTreeList: [],
    carTreeListVisited: false,
    workBigClassAllList: [],
    workBigClassAllListVisited: false,
    carTypeAllList: [],
    carTypeAllListVisited: false,
// 禁用 启用
    carTypeEnableAllList: [],
    carTypeEnableAllListVisited: false,

    carManufacturerAllList: [],
    carManufacturerAllListVisited: '',
    // 车辆
    carKeyMap: {
      'ids': 'ids',
      'id': 'id',
      'plateNumber': 'carnumber', // 车牌号
      'departmentTreeId': 'cardeptid', // 所属部门
      'departmentTree': 'cardeptname', // 所属部门
      'oilCard': 'oilcardnum', // 油卡编号
      'workType': 'carcategory', // 作业大类
      'workTypeName': 'carcategoryname', // 作业大类
      'workTypeItem': 'carcategoryitem', //作业大类子类
      'carType': 'cartypeid', //车辆类型id
      'carTypeName': 'carTypeName', //车辆类型
      'carModel': 'carmodelid', //车辆型号id
      'carmodelname': 'carmodelname',
      'ownership': 'carattribution', //车辆所属权  自用 、租用 、甲方
      'engine': 'enginenumber', //发动机编号
      'carframe': 'vehiclenuumber', //车架号
      'time': 'buydate', //购入日期
      'money': 'buymoney', //购入金额 元
      'drivemileage': 'drivemileage', //已行驶里程
      'mileage': 'initmileage', //初始里程
      'rfId': 'rfidcardnum', //RFID卡号
      'carPicture': 'carpicture', //车辆照片
      'fuelTankCorrectValues': 'tankvolcheck', //油箱纠正值
      'carColor': 'carcolor', // 车辆颜色
      'remark': 'remark', //备注
      'status': 'status', //运营状态 0报废 1 正常
      'driverid': 'driverid', //驾驶员id
      'bindstatus': 'bindstatus', //绑定状态 0 已绑定 1未绑定
      'bindfaclids': 'bindfaclids', //绑定设备id集合
      'bandname': 'bandname', //列表展示
      'drivingLicenseNumber': 'drivinglicense', //行驶证编号
      'drivingLicenseDate': 'openingdate', //发证日期
      'licensemainpicture': 'licensemainpicture', //行驶证主页照片
      'licenseotherpicture': 'licenseotherpicture', //行驶证副页照片
      'bindaccount': 'bindaccount', //绑定用户id --驾驶员
      'drivemodel': 'drivemodel', //驱动方式
      "bindaccountname": 'bindaccountname', // 驾驶员
      'sweepPlate': 'monitorclearDish', // 监测扫盘
      'sprayer': 'monitornozzle', // 监测喷头
      'transferType': 'ransporttype', // 转运类型
      'detail': 'details',
      'eqptype': 'eqptype',
      'equipcode': 'equipcode',
      'equipid': 'equipid',
      'channels': 'channels',
      'videoonlinestatus': 'videoonlinestatus'
    },
    detailKeyMap: {
      'fuelOil': 'fueltype', // 燃油类型
      'firm': 'manufacturername',
      'workwidth': 'workwidth',
      'fuelTank': 'tankvolume', // 油箱容积
      'fuelConsumption': 'oilmileage',
      'suttle': 'carweight',
      'load': 'carload',
      'cell': 'batterycapacity',
    },
    // 车辆型号
    carModelNumKeyMap: {
      'id': 'id',
      'modelNum': 'carmodel',
      'operationtypeid': 'operationtypeid', // 作业大类ID
      'type': 'cartypeid',
      'firm': 'manufacturer',
      'actuate': 'drivemodel',
      'fuelOil': 'fueltype',
      'fuelTank': 'tankvolume',
      'cell': 'batterycapacity',
      'load': 'carload',
      'suttle': 'carweight',
      'fuelConsumption': 'oilmileage',
      'width': 'workwidth',
      'status': 'status',
      'remark': 'remark',
    },
    // 车辆类型
    carTypeKeyMap: {
      'id': 'id',
      'name': 'typename',
      'operationtypeid': 'operationtypeid', // 作业大类ID
      'icon': 'icon',
      'type': 'ransporttype',
      'sweepPlate': 'monitorcleardish',
      'sprayer': 'monitornozzle',
      'status': 'status',
      'remark': 'remark',
    },
    // 厂商
    carManufacturerKeyMap: {
      'id': 'id',
      'name': 'dicname',
      'remark': 'remark',
      'status': 'status'
    },
    // 作业大类
    workBigTypeKeyMap: {
      'id': 'id',
      'name': 'operationtype',
      'remark': 'remark',
      'status': 'status',
      'type': 'type'
    }
  },
  getters: {
    // 获取车辆树列表
    getCarTreeList: state => state.carTreeList,
    // 获取作业大类全部列表
    getCarWorkBigClassAllList: state => state.workBigClassAllList,
    // 获取车辆类型全部列表
    getCarTypeAllList: state => state.carTypeAllList,
    // 获取车辆类型启用全部列表
    getCarTypeEnableAllList: state => state.carTypeEnableAllList,
    // 获取厂商全部列表
    getCarManufacturerAllList: state => state.carManufacturerAllList,
  },
  mutations: {
    // 设置车辆树列表
    setCarTreeList(state, arr) {
      state.carTreeList = arr
      state.carTreeListVisited = true
    },
    // 设置作业大类全部列表
    setWorkBigClassAllList(state, arr) {
      state.workBigClassAllList = arr
      state.workBigClassAllListVisited = true
    },
    // 设置车辆类型全部列表
    setCarTypeAllList(state, arr) {
      state.carTypeAllList = arr
      state.carTypeAllListVisited = true
    },
    // 设置车辆类型启用全部列表
    setCarTypeEnableAllList(state, arr) {
      state.carTypeEnableAllList = arr
      state.carTypeEnableAllListVisited = true
    },
    // 设置厂商全部列表
    setCarManufacturerAllList(state, arr) {
      state.carManufacturerAllList = arr
      state.carManufacturerAllListVisited = true
    },
  },
  actions: {
    // 更新车辆树列表
    updateCarTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.carTreeListVisited || ignorant) {
        vm.$http.getCarTreeList().then(res => {
          if (res.code === 200) {
            commit('setCarTreeList', res.result)
          }
        })
      }
    },
    // 更新作业大类全部列表
    updateCarWorkBigClassAllList({
      commit,
      state
    }, ignorant) {
      if (!state.workBigClassAllListVisited || ignorant) {
        vm.$http.getWorkBigClassAllList({
          type: 'car'
        }).then(res => {
          if (res.code === 200) {
            commit('setWorkBigClassAllList', res.result)
          }
        })
      }
    },
    // 更新车辆类型全部列表
    updateCarTypeAllList({
      commit,
      state
    }, ignorant) {
      if (!state.carTypeAllListVisited || ignorant) {
        vm.$http.getCarTypeAllList().then(res => {
          if (res.code === 200) {
            commit('setCarTypeAllList', res.result)
          }
        })
      }
    },
    // 更新车辆类型启用全部列表
    updateCarTypeEnableAllList({
      commit,
      state
    }, ignorant) {
      if (!state.carTypeEnableAllListVisited || ignorant) {
        vm.$http.getCarTypeEnableAllList({status:1}).then(res => {
          if (res.code === 200) {
            commit('setCarTypeEnableAllList', res.result)
          }
        })
      }
    },
    // 更新厂商全部列表
    updateCarManufacturerAllList({
      commit,
      state
    }, ignorant) {
      if (!state.carManufacturerAllListVisited || ignorant) {
        vm.$http.getCarManufacturerAllList({
          operationtypeid: ''
        }).then(res => {
          if (res.code === 200) {
            commit('setCarManufacturerAllList', res.result)
          }
        })
      }
    },
    // 新增/修改车辆
    createOrEditCar({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.carKeyMap)
      if (params.ids) {
        return new Promise((resolve, reject) => {
          vm.$http.editBatchCar(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        if (params.status) delete params.status
        return new Promise((resolve, reject) => {
          vm.$http.createCar(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆详情
    getCarDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carKeyMap)
            let detailKeyMap = vm.$swapKeyValue(state.detailKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            newData.detail = vm.$keyMapNew(newData.detail, detailKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改车辆型号
    createOrEditCarModelNum({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carModelNumKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarModelNum(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarModelNum(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆型号详情
    getCarModelNumDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarModelNumDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carModelNumKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改车辆类型
    createOrEditCarType({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carTypeKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(res)
          })
        })
      }
    },
    // 获取车辆类型详情
    getCarTypeDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarTypeDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改厂商
    createOrEditCarManufacturer({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carManufacturerKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarManufacturer(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarManufacturer(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取厂商详情
    getCarManufacturerDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarManufacturerDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carManufacturerKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改作业大类
    createOrEditWorkBigType({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.workBigTypeKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editWorkBigType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createWorkBigType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取作业大类详情
    getWorkBigTypeDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getWorkBigTypeDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.workBigTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}