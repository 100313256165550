/**
 * 自定义指令 - 点击小图播放视频
 */
export default {
  bind(el, binding) {
    el.addEventListener('click', (e) => {
      e.stopPropagation()
      const videoUrl = binding.value //获取当前的图片链接
      let previewVideo = document.getElementById('previewVideo')
      if (previewVideo) {
        previewVideo.style.display = 'flex';
        const childVideo = previewVideo.childNodes[0]
        childVideo.setAttribute('src', videoUrl)
      } else {
        previewVideo = document.createElement('div');
        const body = document.body
        previewVideo.innerHTML = `<video src="${videoUrl}" controls="controls" width="400" height="320" />`
        previewVideo.setAttribute('id', 'previewVideo');
        previewVideo.setAttribute('style', 'display: flex;justify-content: center;align-items:center;position:fixed;z-index:2000;left:0;top:0;right:0;bottom:0;background:rgba(0,0,0,0.7);');
        body.appendChild(previewVideo);

        previewVideo.addEventListener('click', (e) => {
          e.stopPropagation();
          const childVideo = previewVideo.childNodes[0]
          childVideo.setAttribute('src', '')
          previewVideo.style.display = 'none'
        })
      }
    })
  }
}