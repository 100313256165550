import vm from '../../main.js'

export default {
  state: {
    personTreeList: [],
    personTreeListVisited: false,
    personAllList: [],
    personAllListVisited: false,
    workTypeAllList: [],
    workTypeAllListVisited: false,
    // 工种禁用  启用
    workTypeEnableAllList: [],
    workTypeEnableAllListVisited: false,

    positionAllList: [],
    positionAllListVisited: false,

    positionEnableAllList: [],
    positionEnableAllListVisited: false,
    workBigClassAllList: [],
    workBigClassListAllVisited: false,
    personKeyMap: {
      'ids': 'ids',
      'name': 'name',
      'status': 'status',
      'workNumber': 'emplnum',
      'workBigType': 'persontype',
      'workType': 'worktype',
      'checkingIn': 'attendtype',
      'accountId': 'bindaccount',
      'account': 'userName',
      'departId': 'empldeptid',
      'departName': 'empldeptname',
      'phone': 'phonenum',
      'entryDate': 'entrytime',
      'salary': 'salarycardnum',
      'bank': 'bank',
      'position': 'position',
      'picture': 'personphoto',
      'icon': 'isusephoto',
      'remark': 'remark',
      'nation': 'nation',
      'sex': 'sex',
      'nativePlace': 'nativeplace',
      'politics': 'politicsstatus',
      'education': 'educationbackground',
      'idNumber': 'idcardnum',
      'site': 'address',
      'idPictureUp': 'idcardpictureup',
      'idPictureDown': 'idcardpicturedown',
      'emergencyContact': 'emergencycontact',
      'emergencyPhone': 'emergencyphone',
      'emergencySite': 'emergencyaddress',
      'height': 'personheight',
      'weight': 'personweight',
      'shoeSize': 'shoesize',
      'clothingSize': 'clothessize',
      'dimission': 'quitreason',
      'dimissionDate': 'quittime',
      'workTime': 'workyear',
      'eqptype': 'eqptype',
      'equipcode': 'equipcode',
      'equipid': 'equipid',
      'channels': 'channels',
    },
    // 环卫工种
    personProfessionKeyMap: {
      'id': 'id',
      'name': 'worktype',
      'driver': 'isdriver',
      'icon': 'icon',
      'status': 'status',
      'remark': 'remark'
    },
    // 职位
    positionKeyMap: {
      'id': 'id',
      'position': 'position',
      'positionIntroduce': 'positionintroduce',
      'status': 'status'
    },
  },
  getters: {
    // 获取人员树列表
    getPersonTreeList: state => state.personTreeList,
    // 获取人员全部列表
    getPersonAllList: state => state.personAllList,
    // 获取工种全部列表
    getWorkTypeAllList: state => state.workTypeAllList,
    // 获取工种全部启用列表
    getWorkTypeEnableAllList: state => state.workTypeEnableAllList,
    // 获取职位全部列表
    getPositionAllList: state => state.positionAllList,
    // 获取职位全部启用列表
    getPositionEnableAllList: state => state.positionEnableAllList,
    // 获取作业大类全部列表
    getPersonWorkBigClassAllList: state => state.workBigClassAllList,
  },
  mutations: {
    // 设置人员树列表
    setPersonTreeList(state, arr) {
      state.personTreeList = arr
      state.personTreeListVisited = true
    },
    // 设置人员全部列表
    setPersonAllList(state, arr) {
      state.personAllList = arr
      state.personAllListVisited = true
    },
    // 设置工种全部列表
    setWorkTypeAllList(state, arr) {
      state.workTypeAllList = arr
      state.workTypeAllListVisited = true
    },
    // 设置工种启用全部列表
    setWorkTypeEnableAllList(state, arr) {
      state.workTypeEnableAllList = arr
      state.workTypeEnableAllListVisited = true
    },
    // 设置职位全部列表
    setPositionAllList(state, arr) {
      state.positionAllList = arr
      state.positionAllListVisited = true
    },
    // 设置职位全部启用列表
    setPositionEnableAllList(state, arr) {
      state.positionEnableAllList = arr
      state.positionEnableAllListVisited = true
    },
    // 设置作业大类全部列表
    setWorkBigClassAllList(state, arr) {
      state.workBigClassAllList = arr
      state.workBigClassListAllVisited = true
    },
  },
  actions: {
    // 更新人员树列表
    updatePersonTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.personTreeListVisited || ignorant) {
        vm.$http.getPersonTreeList().then(res => {
          if (res.code === 200) {
            commit('setPersonTreeList', res.result)
          }
        })
      }
    },
    // 更新人员全部列表
    updatePersonAllList({
      commit,
      state
    }, ignorant) {
      if (!state.personAllListVisited || ignorant) {
        vm.$http.getPersonAllList().then(res => {
          if (res.code === 200) {
            commit('setPersonAllList', res.result)
          }
        })
      }
    },
    // 更新工种全部列表
    updateWorkTypeAllList({
      commit,
      state
    }, ignorant) {
      if (!state.workTypeAllListVisited || ignorant) {
        vm.$http.getWorkTypeAllList().then(res => {
          if (res.code === 200) {
            commit('setWorkTypeAllList', res.result)
          }
        })
      }
    },
    // 更新工种启用全部列表
    updateWorkTypeEnableAllList({
      commit,
      state
    }, ignorant) {
      if (!state.workTypeEnableAllListVisited || ignorant) {
        vm.$http.getWorkTypeAllList({status:1}).then(res => {
          if (res.code === 200) {
            commit('setWorkTypeEnableAllList', res.result)
          }
        })
      }
    },
    // 更新职位全部列表
    updatePositionAllList({
      commit,
      state
    }, ignorant) {
      if (!state.positionAllListVisited || ignorant) {
        vm.$http.getPositionAllList().then(res => {
          if (res.code === 200) {
            commit('setPositionAllList', res.result)
          }
        })
      }
    },
    // 更新职位全部启用列表
    updatePositionEnableAllList({
      commit,
      state
    }, ignorant) {
      if (!state.positionEnableAllListVisited || ignorant) {
        vm.$http.getPositionAllList({status:1}).then(res => {
          if (res.code === 200) {
            commit('setPositionEnableAllList', res.result)
          }
        })
      }
    },
    // 更新作业大类全部列表
    updatePersonWorkBigClassAllList({
      commit,
      state
    }, ignorant) {
      if (!state.workBigClassListAllVisited || ignorant) {
        vm.$http.getWorkBigClassAllList({
          type: 'emp'
        }).then(res => {
          if (res.code === 200) {
            commit('setWorkBigClassAllList', res.result)
          }
        })
      }
    },
    // 新增/修改人员
    createOrEditPerson({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.personKeyMap)
      if (params.ids) {
        return new Promise((resolve, reject) => {
          vm.$http.editBatchPerson(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPerson(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取人员详情
    getPersonDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getPersonDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.personKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改环卫工种
    createOrEditPersonProfession({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.personProfessionKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editPersonProfession(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPersonProfession(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取工种详情
    getPersonProfessionDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getPersonProfessionDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.personProfessionKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改职位
    createOrEditPosition({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.positionKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editPosition(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPosition(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取职位详情
    getPositionDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getPositionDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.positionKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}