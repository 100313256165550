import vm from '../../main.js'

export default {
  state: {
    alarmKeyMap: {
      'id': 'id', // 报警id
      'personIds': 'ids', // 人员id
      'sysname': 'sysname', //系统名称
      'name': 'alarmname', //报警名称
      'thresholdtype': 'thresholdtype', //阈值类型  
      'faclitypeid': 'faclitypeid', //设施大类id
      'itemid': 'itemid', //数据id
      'threshold': 'threshold', //阈值
      'status': 'status', //0 禁用 1启用
      'remark': 'remark', //备注
      'timeinterval': 'timeinterval', // 漏油的检测时间间隔
      'basedays': 'basedays', // 漏油的基准天数
      'polymerizationtime': 'polymerizationtime', // 超速 烟雾报警 硫化氢报警 氨气报警的聚合时间
    }
  },
  getters: {},
  mutations: {},
  actions: {
    // 新增/修改 报警参数
    createOrEditAlarm({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.alarmKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editAlarm(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createAlarm(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 表格 禁用 报警参数
    editTableAlarmStatus({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.alarmKeyMap)
      }
      return new Promise((resolve, reject) => {
        vm.$http.editAlarm(params).then(res => {
          vm.$Message.info(res.message)
          if (res.code === 200) {
            resolve()
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取报警参数详情
    getAlarmDetail({
      commit,
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.alarmKeyMap)
      return new Promise((resolve, reject) => {
        vm.$http.getAlarmDetail(params).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.alarmKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}