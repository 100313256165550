<template>
  <div class='main'>
    <div class="header">
      <div class="box">
        <div class="header-left">
          <img :src="loginLogo || loginLogoDefault">
          <span>{{indexName || '智慧环卫演示版'}}</span>
        </div>
        <div class="header-right">联系电话:{{phone}}</div>
      </div>
    </div>
    <div class="bgImage"
         :style="{'background-image': loginBgImg ? `url(${loginBgImg})` : `url(${loginBgImgDefault})`}">
      <div class="content">
        <div class="content-left">
          <p>欢迎登录</p>
          <h3>{{systemName || '智慧环卫演示版'}}</h3>
          <img src="../../assets/login/app.png"
               @mouseenter="visible=true"
               @mouseleave="visible=false">
        </div>
        <div class="content-right">
          <div v-show="!visible">
            <ul>
              <li>
                <Input v-model="account"
                       placeholder="请输入账号"
                       maxlength="18" />
              </li>
              <li>
                <Input type="password"
                       v-model="pwd"
                       placeholder="请输入密码"
                       maxlength="18"
                       @on-enter="login" />
              </li>
            </ul>
            <Button type="primary"
                    class="w-full m-t-25"
                    :loading="loading"
                    @click="login">登录</Button>
          </div>
          <div class="flex a-center j-center"
               v-show="visible">
            <div class="app">
              <img :src="androidCode ? androidCode : androidCodeDefaule">
              <span>安卓APP</span>
            </div>
            <div class="app">
              <img :src="iosCode ? iosCode : iosCodeDefaule">
              <span>苹果APP</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>
        <span>版权所有：{{copyright || '智慧环卫演示版gs'}}</span>
        <span class="m-l-30">技术支持：{{support || '智慧环卫演示版gs'}}</span>
      </p>
      <img src="../../assets/login/login_logo.png">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  data () {
    return {
      account: '',
      pwd: '',
      visible: false,
      loading: false,
      loginLogoDefault: require('../../assets/login/logo.png'),
      loginBgImgDefault: require('../../assets/login/bg.png'),
      androidCodeDefaule: require('../../assets/login/android.png'),
      iosCodeDefaule: require('../../assets/login/ios.png')
    }
  },
  computed: {
    ...mapGetters({
      'systemName': 'getSystemName',
      'indexName': 'getIndexName',
      'copyright': 'getCopyright',
      'support': 'getSupport',
      'loginLogo': 'getLoginLogo',
      'loginBgImg': 'getLoginBgImg',
      'phone': 'getPhone',
      'androidCode': 'getAndroidCode',
      'iosCode': 'getIosCode',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    login () {
      if (!this.account) return this.$Message.info('请输入账号')
      if (!this.pwd) return this.$Message.info('请输入密码')
      let params = {
        account: this.account,
        password: this.pwd
      }
      this.loading = true
      this.$store.dispatch('login', params).then(() => {
        console.log("_________________________________________1")
        console.log(params);
        this.loading = false
        this.$router.replace('/main')
      }).catch(err => {
        console.log("_________________________________________2")
        console.log(params);
        this.loading = false
        this.$Message.info(err)
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  .header {
    background-color: #fff;
    .box {
      width: 1200px;
      margin: 0 auto 20px;
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      .header-left {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
        }
        span {
          font-size: 30px;
          margin-left: 8px;
        }
      }
      .header-right {
        font-size: 14px;
        padding-top: 15px;
      }
    }
  }
  .bgImage {
    height: 600px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 100px;
    .content {
      width: 682px;
      height: 321px;
      margin: 0 auto;
      display: flex;
      .content-left {
        flex: 1;
        padding-left: 15px;
        color: #fff;
        background-color: rgba(107, 145, 197, 0.4);
        p {
          font-size: 28px;
          padding-top: 45px;
        }
        h3 {
          font-size: 36px;
          font-weight: 500;
          margin-top: 10px;
        }
        img {
          width: 73px;
          height: 73px;
          cursor: pointer;
          margin-top: 80px;
        }
      }
      .content-right {
        width: 272px;
        background-color: #fff;
        padding: 45px 15px 0;
        li {
          margin-bottom: 25px;
        }
        .app {
          padding-top: 60px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 110px;
            height: 110px;
          }
        }
      }
    }
  }
  .footer {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-top: 40px;
    }
  }
}
</style>
