import vm from '../../main.js'
import systemConfig from '@/api/modules/systemConfig';

export default {
  state: {
    systemName: '',
    indexName: '',
    mapCenterLnglat: '',
    mainRefreshTime: '',
    loginTimeout: '',
    loginTimeoutEnable: '',
    copyright: '',
    support: '',
    loginLogo: '',
    loginBgImg: '',
    phone: '',
    androidCode: '',
    iosCode: '',
    systemConfigKeyMap: {
      'systemName': 'sysName',
      'indexName': 'homeName',
      'lnglat': 'mapFocusLonlat',
      'address': 'mapFocusName',
      'videoSrc': 'videoUrl',
      'videoCode': 'videoCode',
      'mainRefreshTime': 'oneimgRefreshTime',
      'loginTimeout': 'loginTimeout',
      'loginTimeoutEnable': 'isEnableLt',
      'companyName': 'comName',
      'copyright': 'copyright',
      'support': 'support',
      'loginLogo': 'logo',
      'loginBgImg': 'loginBg',
      'phone': 'managerPhone',
      'tokenTimeout': 'timeoutDay',
      'androidCode': 'appAndroidCode',
      'iosCode': 'appIosCode',
      'loginName': 'appLoginName',
      'loginImg': 'appLoginLogo',
      'bannerArr': 'appImages',
    }
  },
  getters: {
    // 获取系统名称
    getSystemName: state => state.systemName,
    // 获取首页名称
    getIndexName: state => state.indexName,
    // 获取地图中心点
    getMapCenterLnglat: state => state.mapCenterLnglat,
    // 获取一张网重置时间
    getMainRefreshTime: state => state.mainRefreshTime,
    // 获取登录超时时间
    getLoginTimeout: state => state.loginTimeout,
    // 获取登录超时时间是否开启
    getLoginTimeoutEnable: state => state.loginTimeoutEnable,
    // 获取版权所有
    getCopyright: state => state.copyright,
    // 获取技术支持
    getSupport: state => state.support,
    // 获取登录logo
    getLoginLogo: state => state.loginLogo,
    // 获取背景图片
    getLoginBgImg: state => state.loginBgImg,
    // 获取平台联系电话
    getPhone: state => state.phone,
    // 获取安卓二维码
    getAndroidCode: state => state.androidCode,
    // 获取苹果二维码
    getIosCode: state => state.iosCode,
  },
  mutations: {
    // 设置系统名称
    setSystemName(state, data) {
      state.systemName = data
    },
    // 设置首页名称
    setIndexName(state, data) {
      state.indexName = data
    },
    // 设置地图中心点
    setMapCenterLnglat(state, data) {
      state.mapCenterLnglat = data
    },
    // 设置一张网重置时间
    setMainRefreshTime(state, data) {
      state.mainRefreshTime = data
    },
    // 设置登录超时时间
    setLoginTimeout(state, data) {
      state.loginTimeout = data
    },
    // 设置登录超时时间是否开启
    setLoginTimeoutEnable(state, data) {
      state.loginTimeoutEnable = data
    },
    // 设置版权所有
    setCopyright(state, data) {
      state.copyright = data
    },
    // 设置技术支持
    setSupport(state, data) {
      state.support = data
    },
    // 设置登录logo
    setLoginLogo(state, data) {
      state.loginLogo = data
    },
    // 设置背景图片
    setLoginBgImg(state, data) {
      state.loginBgImg = data
    },
    // 设置平台联系电话
    setPhone(state, data) {
      state.phone = data
    },
    // 设置安卓二维码
    setAndroidCode(state, data) {
      state.androidCode = data
    },
    // 设置苹果二维码
    setIosCode(state, data) {
      state.iosCode = data
    },
  },
  actions: {
    // 新增/修改 系统配置
    createOrEditSystemConfig({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.systemConfigKeyMap)
      return new Promise((resolve, reject) => {
        systemConfig.editSystemConfig(params).then(res => {
          vm.$Message.info(res.message)
          if (res.code === 200) {
            resolve()
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取系统配置详情
    getSystemConfigDetail({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        systemConfig.getSystemConfigDetail().then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.systemConfigKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            commit('setSystemName', newData.systemName)
            commit('setIndexName', newData.indexName)
            commit('setMapCenterLnglat', newData.lnglat)
            commit('setMainRefreshTime', newData.mainRefreshTime)
            commit('setLoginTimeout', newData.loginTimeout)
            commit('setLoginTimeoutEnable', newData.loginTimeoutEnable)
            commit('setCopyright', newData.copyright)
            commit('setSupport', newData.support)
            commit('setLoginLogo', newData.loginLogo)
            commit('setLoginBgImg', newData.loginBgImg)
            commit('setPhone', newData.phone)
            commit('setAndroidCode', newData.androidCode)
            commit('setIosCode', newData.iosCode)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}