<template>
  <div class='header'>
    <div class="header-left">
      <img :src="loginLogo || loginLogoDefault">
      <span>{{systemName}}</span>
    </div>
    <div class="header-right">
      <Menu ref="menu"
            mode="horizontal"
            :theme="theme"
            :active-name="activeName">
        <template v-for="(item,index) in data">
          <MenuItem :name="item.name"
                    :to="item.url"
                    replace
                    :target="item.target||'_self'"
                    :key="index">
          <Icon :custom="'i-icon '+item.icon" />
          {{item.title}}
          </MenuItem>
        </template>
      </Menu>
      <Dropdown trigger="click"
                @on-click="onClickUserName">
        <div class="header-user">
          {{account}}
          <Icon type="ios-arrow-down"></Icon>
        </div>
        <DropdownMenu slot="list">
          <DropdownItem name="loginout">
            <Icon custom="i-icon icon-tuichu1" />
            退出系统
          </DropdownItem>
          <DropdownItem name="changePwd">
            <Icon custom="i-icon icon-mima1" />
            修改密码
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <PwdEditModal v-model="pwdEditVisible"></PwdEditModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PwdEditModal from '@/components/product/admin/modal/home/PwdEditModal'
export default {
  components: {
    PwdEditModal
  },
  props: {
    data: Array,
    activeName: String
  },
  data () {
    return {
      loginLogoDefault: require('../../../assets/login/logo.png'),
      theme: 'dark',
      pwdEditVisible: false,
    }
  },
  watch: {
    activeName () {
      this.$nextTick(() => {
        this.$refs.menu.updateActiveName();
      })
    },
  },
  computed: {
    ...mapGetters({
      'systemName': 'getSystemName',
      'loginLogo': 'getLoginLogo',
      'account': 'getAccount',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onClickUserName (name) {
      switch (name) {
        case 'loginout':
          this.$store.dispatch('loginOut')
          this.$router.replace('/login')
          break;
        case 'changePwd':
          this.pwdEditVisible = true
          break;
        default:
          break;
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.header {
  width: 100%;
  height: 60px;
  background: rgba(29, 39, 62, 0.95);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
  .header-left {
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 60px;
      height: 60px;
    }
    span {
      font-size: 16px;
      color: #fff;
      margin-left: 5px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .header-user {
      padding: 0 16px;
      line-height: 60px;
      color: #fff;
      cursor: pointer;
    }
    ::v-deep {
      .ivu-menu-dark {
        background: transparent;
      }
    }
  }
}
::v-deep {
  .ivu-menu-item-active {
    background: #19a586;
  }
}
</style>