export default [{
    name: 'main',
    title: '环卫一张网',
    icon: 'icon-shishijiankong',
    url: '/main'
  },
  {
    name: 'patrolMaintain',
    title: '巡查维护',
    icon: 'icon-tiaodu',
    url: '/admin/patrolCase',
    children: [{
        name: 'patrolCase',
        title: '巡查案件',
        icon: 'icon-xuncha',
        url: '/admin/patrolCase',
      },
      {
        name: 'workViolations',
        title: '作业违规',
        icon: 'icon-weigui',
        url: '/admin/workViolations',
      },
      {
        name: 'runAlarm',
        title: '运行报警',
        icon: 'icon-baojing',
        url: '/admin/runAlarm',
      },
      {
        name: 'emergencyDispatch',
        title: '应急调度管理',
        icon: 'icon-eventType_wandao',
        url: '/admin/emergencyDispatch',
      },
      {
        name: 'thirdSystem',
        title: '第三方系统',
        icon: 'icon-eventType_wandao',
        url: '/admin/thirdSystem',
      },
    ]
  },
  {
    name: 'work',
    title: '作业管理',
    icon: 'icon-dingdan',
    url: '/admin/grid',
    children: [{
        name: 'grid',
        title: '网格管理',
        icon: 'icon-quyu1',
        url: '/admin/grid',
      },
      {
        name: 'roadClean',
        title: '道路保洁',
        icon: 'icon-daolubaojie',
        url: '/admin/roadClean-crewScheduling',
        children: [{
            name: 'roadClean-crewScheduling',
            title: '排班计划',
            url: '/admin/roadClean-crewScheduling',
          },
          {
            name: 'roadClean-attendance',
            title: '出勤记录',
            url: '/admin/roadClean-attendance',
          },
          // {
          //   name: 'roadClean-violation',
          //   title: '违规统计',
          //   url: '/admin/roadClean-violation',
          // },
          {
            name: 'roadClean-work',
            title: '作业统计',
            url: '/admin/roadClean-work',
          },
        ]
      },
      {
        name: 'mechanicalClean',
        title: '机械清扫',
        icon: 'icon-jixiezuoye',
        url: '/admin/mechanicalClean-crewScheduling',
        children: [{
            name: 'mechanicalClean-crewScheduling',
            title: '排班计划',
            url: '/admin/mechanicalClean-crewScheduling',
          },
          {
            name: 'mechanicalClean-exception',
            title: '异常日历',
            url: '/admin/mechanicalClean-exception',
          },
          {
            name: 'mechanicalClean-work',
            title: '作业统计',
            url: '/admin/mechanicalClean-work',
          },
        ]
      },
      {
        name: 'garbageClean',
        title: '垃圾清运',
        icon: 'icon-lajiqingyun',
        url: '/admin/garbageClean-crewScheduling',
        children: [{
            name: 'garbageClean-crewScheduling',
            title: '排班计划',
            url: '/admin/garbageClean-crewScheduling',
          },
          {
            name: 'garbageClean-exception',
            title: '异常日历',
            url: '/admin/garbageClean-exception',
          },
          {
            name: 'garbageClean-work',
            title: '作业统计',
            url: '/admin/garbageClean-work',
          },
          // {
          //   name: 'garbageClean-standard',
          //   title: '清运达标统计',
          //   url: '/admin/garbageClean-standard',
          // },
        ]
      },
      {
        name: 'garbageTransit',
        title: '垃圾转运',
        icon: 'icon-lajizhuanyun',
        url: '/admin/garbageTransit-crewScheduling',
        children: [{
            name: 'garbageTransit-crewScheduling',
            title: '排班计划',
            url: '/admin/garbageTransit-crewScheduling',
          },
          {
            name: 'garbageTransit-exception',
            title: '异常日历',
            url: '/admin/garbageTransit-exception',
          },
          {
            name: 'garbageTransit-work',
            title: '作业统计',
            url: '/admin/garbageTransit-work',
          },
        ]
      },
      //  {
      //   name: 'worlLog',
      //   title: '作业日志',
      //   icon: 'icon-rizhi1',
      //   url: '/admin/worlLog',
      // },
    ]
  },
  {
    name: 'cost',
    title: '成本管理',
    icon: 'icon-dingdan',
    url: '/admin/carCost',
    children: [{
        name: 'carCost',
        title: '车辆费用',
        icon: 'icon-che',
        url: '/admin/carCost',
      },
      {
        name: 'costSummary',
        title: '成本汇总',
        icon: 'icon-huizong1',
        url: '/admin/costSummary',
      },
      {
        name: 'costDictionaries',
        title: '成本字典',
        icon: 'icon-zidian',
        url: '/admin/costDictionaries',
      },
      {
        name: 'personCost',
        title: '人员成本',
        icon: 'icon-Agerenxuanzhong',
        url: '/admin/personCost',
      },
      {
        name: 'operatingCost',
        title: '运营成本',
        icon: 'icon-weixiu',
        url: '/admin/operatingCost',
      },
    ]
  },
  {
    name: 'videoSurveillance',
    title: '视频监控',
    icon: 'icon-jiankong',
    url: '/videoSurveillance',
    target: '_blank'
  },
  {
    name: 'archives',
    title: '档案管理',
    icon: 'icon-wuziqingkuang',
    url: '/admin/equipmentArchives',
    children: [{
        name: 'equipmentArchives',
        title: '设备档案',
        icon: 'icon-shebei',
        url: '/admin/equipmentArchives',
      },
      // {
      //   name: 'companyArchives',
      //   title: '公司档案',
      //   icon: 'icon-gongsi',
      //   url: '/admin/companyArchives',
      // },
      {
        name: 'facilityArchives',
        title: '设施档案',
        icon: 'icon-sheshi',
        url: '/admin/facilityArchives',
      },
      {
        name: 'carArchives',
        title: '车辆档案',
        icon: 'icon-che',
        url: '/admin/carArchives',
      },
      {
        name: 'personArchives',
        title: '人员档案',
        icon: 'icon-duoren',
        url: '/admin/personArchives',
      },
      {
        name: 'gardensArchives',
        title: '园林档案',
        icon: 'icon-sheshi',
        url: '/admin/gardensArchives',
      },
    ]
  },
  {
    name: 'statistics',
    title: '统计分析',
    icon: 'icon-tongji',
    url: '/admin/carFuelReport',
    children: [{
        name: 'carFuelReport',
        title: '车辆燃油报表',
        icon: 'icon-ranyou',
        url: '/admin/carFuelReport',
      },
      {
        name: 'drivingReport',
        title: '行驶报表',
        icon: 'icon-hangshi',
        url: '/admin/drivingReport',
      },
      {
        name: 'eventReport',
        title: '事件报表',
        icon: 'icon-t_shijianliebiao',
        url: '/admin/eventReport',
      },
      {
        name: 'policeReport',
        title: '报警报表',
        icon: 'icon-baojing',
        url: '/admin/policeReport',
      },
      {
        name: 'violationReport',
        title: '违规报表',
        icon: 'icon-baojing',
        url: '/admin/violationReport',
      },
      // {
      //   name: 'departmentResource',
      //   title: '机构资源分布',
      //   icon: 'icon-t_shijianliebiao',
      //   url: '/admin/departmentResource',
      // },
      // {
      //   name: 'departmentDeparture',
      //   title: '机构离职分析',
      //   icon: 'icon-t_shijianliebiao',
      //   url: '/admin/departmentDeparture',
      // },
      // {
      //   name: 'carMaintain',
      //   title: '车辆维修情况',
      //   icon: 'icon-t_shijianliebiao',
      //   url: '/admin/carMaintain',
      // },
      {
        name: 'faclReport',
        title: '设施报表',
        icon: 'icon-shebei',
        url: '/admin/faclReport',
      },
      {
        name: 'attendanceReport',
        title: '考勤报表',
        icon: 'icon-daolubaojie',
        url: '/admin/attendanceReport',
      },
    ]
  },
  {
    name: 'systemSetting',
    title: '系统设置',
    icon: 'icon-xitongshezhi',
    url: '/admin/userArchives',
    children: [{
        name: 'userArchives',
        title: '用户档案',
        icon: 'icon-jiaose',
        url: '/admin/userArchives',
      },
      {
        name: 'department',
        title: '机构管理',
        icon: 'icon-rili',
        url: '/admin/department',
      },
      // {
      //   name: 'notice',
      //   title: '通知公告',
      //   icon: 'icon-tongzhi',
      //   url: '/admin/notice',
      // },
      {
        name: 'log',
        title: '日志管理',
        icon: 'icon-rizhi1',
        url: '/admin/log',
      },
      {
        name: 'systemConfig',
        title: '系统配置',
        icon: 'icon-peizhi',
        url: '/admin/systemConfig',
      },
      // {
      //   name: 'administrativeFence',
      //   title: '行政围栏',
      //   icon: 'icon-hangzhengquyu',
      //   url: '/admin/administrativeFence',
      // },
    ]
  },
]