import axios from '../http.interceptor'

export default {
  // 获取录像文件
  getVideoFileList: (params) => axios.post('/hss/videoFile/list', params),

  // 获取录像计划
  getVideoPlanList: (params) => axios.post('/hss/videoPlan/list', params),

  // 新增录像计划
  createdVideoPlan: (params) => axios.post('/hss/videoPlan/save', params),

  // 修改录像计划
  editVideoPlan: (params) => axios.post('/hss/videoPlan/update', params),

  // 删除录像计划
  deleteVideoPlan: (params) => axios.post('/hss/videoPlan/delete', params),

  // 批量删除录像计划
  deleteBatchVideoPlan: (params) => axios.post('/hss/videoPlan/deleteBatch', params),

  // 获取设施视频设备信息
  getEquipWithChannel: (params) => axios.post('/hss/tlequip/getEquipWithChannel', params),

  // 设置视频存储等
  setVideoConfig: (params) => axios.post('/hss/videoConfig/save', params),

  // 获取视频存储等
  getVideoConfig: (params) => axios.post('/hss/videoConfig/getVideoConfig', params),
}