import vm from '../../main.js'

export default {
  state: {
    nationArr: [{
        id: '汉族',
        name: '汉族'
      },
      {
        id: '蒙古族',
        name: '蒙古族'
      },
      {
        id: '回族',
        name: '回族'
      },
      {
        id: '藏族',
        name: '藏族'
      },
      {
        id: '维吾尔族',
        name: '维吾尔族'
      },
      {
        id: '苗族',
        name: '苗族'
      },
      {
        id: '彝族',
        name: '彝族'
      },
      {
        id: '壮族',
        name: '壮族'
      },
      {
        id: '布依族',
        name: '布依族'
      },
      {
        id: '朝鲜族',
        name: '朝鲜族'
      },
      {
        id: '满族',
        name: '满族'
      },
      {
        id: '侗族',
        name: '侗族'
      },
      {
        id: '瑶族',
        name: '瑶族'
      },
      {
        id: '白族',
        name: '白族'
      },
      {
        id: '土家族',
        name: '土家族'
      },
      {
        id: '哈尼族',
        name: '哈尼族'
      },
      {
        id: '哈萨克族',
        name: '哈萨克族'
      },
      {
        id: '傣族',
        name: '傣族'
      },
      {
        id: '黎族',
        name: '黎族'
      },
      {
        id: '傈僳族',
        name: '傈僳族'
      },
      {
        id: '佤族',
        name: '佤族'
      },
      {
        id: '畲族',
        name: '畲族'
      },
      {
        id: '高山族',
        name: '高山族'
      },
      {
        id: '拉祜族',
        name: '拉祜族'
      },
      {
        id: '水族',
        name: '水族'
      },
      {
        id: '东乡族',
        name: '东乡族'
      },
      {
        id: '纳西族',
        name: '纳西族'
      },
      {
        id: '景颇族',
        name: '景颇族'
      },
      {
        id: '柯尔克孜族',
        name: '柯尔克孜族'
      },
      {
        id: '土族',
        name: '土族'
      },
      {
        id: '达翰尔族',
        name: '达翰尔族'
      },
      {
        id: '么佬族',
        name: '么佬族'
      },
      {
        id: '羌族',
        name: '羌族'
      },
      {
        id: '布朗族',
        name: '布朗族'
      },
      {
        id: '撒拉族',
        name: '撒拉族'
      },
      {
        id: '毛南族',
        name: '毛南族'
      },
      {
        id: '仡佬族',
        name: '仡佬族'
      },
      {
        id: '锡伯族',
        name: '锡伯族'
      },
      {
        id: '阿昌族',
        name: '阿昌族'
      },
      {
        id: '普米族',
        name: '普米族'
      },
      {
        id: '塔吉克族',
        name: '塔吉克族'
      },
      {
        id: '怒族',
        name: '怒族'
      },
      {
        id: '乌孜别克族',
        name: '乌孜别克族'
      },
      {
        id: '俄罗斯族',
        name: '俄罗斯族'
      },
      {
        id: '鄂温克族',
        name: '鄂温克族'
      },
      {
        id: '德昂族',
        name: '德昂族'
      },
      {
        id: '保安族',
        name: '保安族'
      },
      {
        id: '裕固族',
        name: '裕固族'
      },
      {
        id: '京族',
        name: '京族'
      },
      {
        id: '塔塔尔族',
        name: '塔塔尔族'
      },
      {
        id: '独龙族',
        name: '独龙族'
      },
      {
        id: '鄂伦春族',
        name: '鄂伦春族'
      },
      {
        id: '赫哲族',
        name: '赫哲族'
      },
      {
        id: '门巴族',
        name: '门巴族'
      },
      {
        id: '珞巴族',
        name: '珞巴族'
      },
      {
        id: '基诺族',
        name: '基诺族'
      },
    ],
    bankArr: [{
        id: '招商银行',
        name: '招商银行'
      },
      {
        id: '建设银行',
        name: '建设银行'
      },
      {
        id: '农业银行',
        name: '农业银行'
      },
      {
        id: '工商银行',
        name: '工商银行'
      },
      {
        id: '交通银行',
        name: '交通银行'
      },
      {
        id: '中国银行',
        name: '中国银行'
      },
      {
        id: '民生银行',
        name: '民生银行'
      },
      {
        id: '其他银行',
        name: '其他银行'
      },
    ],
    politicsArr: [{
        id: '党员',
        name: '党员'
      },
      {
        id: '团员',
        name: '团员'
      },
      {
        id: '其他',
        name: '其他'
      }
    ],
    educationArr: [{
        id: '初中及以下',
        name: '初中及以下'
      },
      {
        id: '高中',
        name: '高中'
      },
      {
        id: '大专',
        name: '大专'
      },
      {
        id: '本科',
        name: '本科'
      },
      {
        id: '硕士',
        name: '硕士'
      },
      {
        id: '博士',
        name: '博士'
      }
    ],
    clothingSizeArr: [{
        id: 'S',
        name: 'S'
      },
      {
        id: 'M',
        name: 'M'
      },
      {
        id: 'L',
        name: 'L'
      },
      {
        id: 'XL',
        name: 'XL'
      },
      {
        id: 'XXL',
        name: 'XXL'
      },
      {
        id: 'XXXL',
        name: 'XXXL'
      },
      {
        id: 'XXXXL',
        name: 'XXXXL'
      }
    ],
    agreementArr: [{
        id: 'elink',
        name: 'elink'
      },
      {
        id: 'sj',
        name: 'sj'
      },
      {
        id: 'gk309',
        name: 'gk309'
      },
      {
        id: '808',
        name: '808'
      },
      {
        id: '28181',
        name: '28181'
      },
      {
        id: 'pt88',
        name: 'pt88'
      },
      {
        id: 'lwm2m',
        name: 'lwm2m'
      },
      {
        id: 'pt89',
        name: 'pt89'
      },
      {
        id: 'ttx',
        name: 'ttx'
      },
      {
        id: 'modbus',
        name: 'modbus'
      },
      {
        id: 'http',
        name: 'http'
      },
      {
        id: 'interphone',
        name: 'interphone'
      },
    ],
    weekArr: [{
      id: 1,
      name: '星期一'
    }, {
      id: 2,
      name: '星期二'
    }, {
      id: 3,
      name: '星期三'
    }, {
      id: 4,
      name: '星期四'
    }, {
      id: 5,
      name: '星期五'
    }, {
      id: 6,
      name: '星期六'
    }, {
      id: 7,
      name: '星期日'
    }],
    monthArr: []
  },
  getters: {
    // 获取民族列表
    getNationArr(state) {
      return state.nationArr
    },
    // 获取银行列表
    getBankArr(state) {
      return state.bankArr
    },
    // 获取政治面貌列表
    getPoliticsArr(state) {
      return state.politicsArr
    },
    // 获取学历列表
    getEducationArr(state) {
      return state.educationArr
    },
    // 获取衣服尺寸列表
    getClothingSizeArr(state) {
      return state.clothingSizeArr
    },
    // 获取协议列表
    getAgreementArr(state) {
      return state.agreementArr
    },
    // 获取星期列表
    getWeekArr(state) {
      return state.weekArr
    },
    // 获取星期列表
    getWeekArr(state) {
      return state.weekArr
    },
    // 获取月列表
    getMonthArr(state) {
      for (let i = 1; i < 31; i++) {
        let obj = {}
        obj.id = i
        obj.name = i
        state.monthArr.push(obj)
      }
      return state.monthArr
    },
  },
  mutations: {},
  actions: {}
}