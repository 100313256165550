import vm from '../../main.js'

export default {
  state: {
    equipmentTypeAllList: [],
    equipmentTypeAllListVisited: false,
    equipmentManufacturerAllList: [],
    equipmentManufacturerAllListVisited: false,
    equipmentManufacturerAllEnableList: [],
    equipmentManufacturerAllEnableListVisited: false,
    // 设备
    equipmentKeyMap: {
      'ids': 'ids',
      'number': 'equipcode',
      'communication': 'communicationcode',
      'name': 'equipname',
      'type': 'equiptypeid',
      'status': 'status',
      'account': 'equipaccount',
      'password': 'viewpassword',
      'manufacturer': 'manufacturerid',
      'model': 'equipmodelid',
      'remark': 'remark'
    },
    // 设备型号
    equipmentModelKeyMap: {
      'ids': 'ids',
      'model': 'equipmodel',
      'type': 'equiptypeid',
      'manufacturer': 'manufacturerid',
      'agreement': 'agreement',
      'instruct': 'orders',
      'status': 'status',
    },
    // 设备类型
    equipmentTypeKeyMap: {
      'id': 'id',
      'type': 'equipname',
      'remark': 'remark',
      'status': 'status',
    },
    // 视频设备通道
    equipmentVideoChannelKeyMap: {
      'id': 'id',
      'parentId': 'equipid',
      'channelId': 'channelid',
      'name': 'channelname',
      'sort': 'sort',
      'remark': 'remark',
      'status': 'status',
    },
    // 厂商
    equipmentManufacturerKeyMap: {
      'id': 'id',
      'name': 'manufacturername',
      'remark': 'remark',
      'status': 'status'
    },
  },
  getters: {
    // 获取设备类型全部列表
    getEquipmentTypeAllList: state => state.equipmentTypeAllList,
    // 获取厂商全部列表
    getEquipmentManufacturerAllList: state => state.equipmentManufacturerAllList,
    // 获取厂商全部列表
    getEquipmentManufacturerAllEnableList: state => state.equipmentManufacturerAllEnableList,
  },
  mutations: {
    // 设置设备类型全部列表
    setEquipmentTypeAllList(state, arr) {
      state.equipmentTypeAllList = arr
      state.equipmentTypeAllListVisited = true
    },
    // 设置厂商全部列表
    setEquipmentManufacturerAllList(state, arr) {
      state.equipmentManufacturerAllList = arr
      state.equipmentManufacturerAllListVisited = true
    },
    // 设置厂商全部启用列表
    setEquipmentManufacturerAllEnableList(state, arr) {
      state.equipmentManufacturerAllEnableList = arr
      state.equipmentManufacturerAllEnableListVisited = true
    },
  },
  actions: {
    // 更新设备类型全部列表
    updateEquipmentTypeAllList({
      commit,
      state
    }, ignorant) {
      if (!state.equipmentTypeAllListVisited || ignorant) {
        vm.$http.getEquipmentTypeAllList().then(res => {
          if (res.code === 200) {
            commit('setEquipmentTypeAllList', res.result)
          }
        })
      }
    },
    // 更新厂商全部列表
    updateEquipmentManufacturerAllList({
      commit,
      state
    }, ignorant) {
      if (!state.equipmentManufacturerAllListVisited || ignorant) {
        vm.$http.getEquipmentManufacturerAllList().then(res => {
          if (res.code === 200) {
            commit('setEquipmentManufacturerAllList', res.result)
          }
        })
      }
    },
    // 更新厂商全部启用列表
    updateEquipmentManufacturerAllEnableList({
      commit,
      state
    }, ignorant) {
      if (!state.equipmentManufacturerAllEnableListVisited || ignorant) {
        vm.$http.getEquipmentManufacturerAllList({
          status: 1
        }).then(res => {
          if (res.code === 200) {
            commit('setEquipmentManufacturerAllEnableList', res.result)
          }
        })
      }
    },
    // 新增/修改设备
    createOrEditEquipment({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.equipmentKeyMap)
      if (params.ids) {
        return new Promise((resolve, reject) => {
          vm.$http.editBatchEquipment(params).then(res => {
            if (res.code === 200) {
            vm.$Message.info(res.message)
            resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createEquipment(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设备详情
    getEquipmentDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEquipmentDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.equipmentKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改设备型号
    createOrEditEquipmentModel({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.equipmentModelKeyMap)
      if (params.ids) {
        return new Promise((resolve, reject) => {
          vm.$http.editBatchEquipmentModel(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createEquipmentModel(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设备型号详情
    getEquipmentModelDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEquipmentModelDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.equipmentModelKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改设备类型
    createOrEditEquipmentType({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.equipmentTypeKeyMap)

      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEquipmentType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createEquipmentType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设备类型详情
    getEquipmentTypeDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEquipmentTypeDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.equipmentTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改设备类型
    createOrEditEquipmentVideoChannel({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.equipmentVideoChannelKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEquipmentVideoChannel(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createEquipmentVideoChannel(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设备类型详情
    getEquipmentVideoChannelDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEquipmentVideoChannelDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.equipmentVideoChannelKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改厂商
    createOrEditEquipmentManufacturer({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.equipmentManufacturerKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEquipmentManufacturer(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(res)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createEquipmentManufacturer(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(res)
          })
        })
      }
    },
    // 获取厂商详情
    getEquipmentManufacturerDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEquipmentManufacturerDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.equipmentManufacturerKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}