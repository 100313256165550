import vm from '../../main.js'
import user from '@/api/modules/user'
import {
  getToken,
  setToken
} from '@/utils/tokenutils'
import bus from '@/utils/bus'

export default {
  state: {
    userId: '',
    account: '',
    userName: '',
    userIsAdmin: 0,
    token: getToken(),
    userDetailVisited: false,
    btnPermissions: [],
  },
  getters: {
    getUserId: state => state.userId,
    getAccount: state => state.account,
    getUserName: state => state.userName,
    getUserIsAdmin: state => state.userIsAdmin,
    getToken: state => state.token,
    getUserDetailVisited: state => state.userDetailVisited,
    getBtnPermissions: state => state.btnPermissions,
  },
  mutations: {
    setUserId(state, id) {
      state.userId = id
    },
    setAccount(state, account) {
      state.account = account
    },
    setUserName(state, name) {
      state.userName = name
    },
    setUserIsAdmin(state, isAdmin) {
      state.userIsAdmin = isAdmin
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setUserDetailVisited(state, status) {
      state.userDetailVisited = status
    },
    setBtnPermissions(state, permissions) {
      state.btnPermissions = permissions
    },
  },
  actions: {
    // 登录
    login({
      commit,
      dispatch
    }, params) {
      return new Promise((resolve, reject) => {
        vm.$http.login(params).then(res => {
          if (res.code === 200) {
            const data = res.result
            commit('setToken', data.ticket)
            resolve(data)
            // dispatch('getUserDetail').then(ress => {
            //   resolve(ress)
            // }).catch(err => {
            //   reject(err.message)
            // })
          } else {
            reject(res.message)
          }
        })
      })
    },
    // 获取用户详情
    getUserDetail({
      state,
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        user.getUserDetail().then(res => {
          if (res.code === 200) {
            const data = res.result
            commit('setUserId', data.uid)
            commit('setAccount', data.account)
            commit('setUserName', data.name)
            commit('setUserIsAdmin', data.isAdmin)
            commit('setUserDetailVisited', true)
            commit('setBtnPermissions', data.permissions)
            resolve(data)
            // 根据权限获取菜单显隐和router拦截
            dispatch('updateMenuArr', data.menus)
            dispatch('updateRoutePermission', data.menus)
            // 开启websocket
            bus.$emit('openWebSocket', state.token)
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出登录
    loginOut({
      commit
    }) {
      commit('setToken', '')
      commit('setUserDetailVisited', false)
    },
  }
}