export default [{
  path: 'equipmentArchives',
  name: 'equipmentArchives',
  component: () => import('../../views/admin/archives/equipmentArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'companyArchives',
  name: 'companyArchives',
  component: () => import('../../views/admin/archives/companyArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'facilityArchives',
  name: 'facilityArchives',
  component: () => import('../../views/admin/archives/facilityArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'carArchives',
  name: 'carArchives',
  component: () => import('../../views/admin/archives/carArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'personArchives',
  name: 'personArchives',
  component: () => import('../../views/admin/archives/personArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'gardensArchives',
  name: 'gardensArchives',
  component: () => import('../../views/admin/archives/gardensArchives'),
  meta: {
    permission: []
  }
}, ]