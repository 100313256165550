import constantconfig from '../config/constantconfig'

/**
 * 键映射，只返回映射的json数据
 * @param {Object} json 原json对象
 * @param {Object} map 映射规则对象
 * @return {Object} 映射后的json数据
 */
export const keyMapNew = (json, map) => {
  if (Object.keys(map).length == 0) {
    return json;
  }
  let result = {};
  for (let key in json) {
    let newKey = map[key];
    if (newKey) {
      result[newKey] = json[key];
    }
  }
  return result;
}

/**
 * 键值对交换
 * @param {Object} json 原json对象
 * @return {Object} 交换后的json数据
 */
export const swapKeyValue = (json) => {
  return Object.keys(json).reduce((acc, key) => {
    acc[json[key]] = key;
    return acc;
  }, {})
}

// 处理时间 用时
export const timeDifference = (tiem1, tiem2) => {
  let moment = require('moment');
  const date1 = moment(tiem1);
  const date2 = moment(tiem2);
  const date3 = date2.diff(date1, 'minute'); //计算相差的分钟数
  let time = []
  time.push(Math.floor(date3 / 60)); //相差的小时数
  time.push(date3 % 60) //计算相差小时后余下的分钟数
  return time
}

// 秒转换分钟小时
export const timeSecondsTransition = (value) => {
  var theTime = parseInt(value); // 需要转换的时间秒 
  var theTime1 = 0; // 分 
  var theTime2 = 0; // 小时 
  var theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        //大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var result = '';
  if (theTime > 0) {
    result = "" + parseInt(theTime) + "秒";
  }
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  if (theTime3 > 0) {
    result = "" + parseInt(theTime3) + "天" + result;
  }
  return result;
}

// 时间戳转YYYY-MM-DD格式
export const times = (date) => {
  var date = new Date(date);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD + " " + hh + mm + ss;
}

// 减法清除浮点数
export const subtraction = (a, b) => {
  var r1, r2, m, n;
  try {
    r1 = a.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = b.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = (r1 >= r2) ? r1 : r2;
  return ((a * m - b * m) / m).toFixed(n);
}

// 加法清除浮点数
export const addition = (a, b) => {
  var r1, r2, m, c;
  try {
    r1 = a.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = b.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      a = Number(a.toString().replace('.', ''));
      b = Number(b.toString().replace('.', '')) * cm;
    } else {
      a = Number(a.toString().replace('.', '')) * cm;
      b = Number(b.toString().replace('.', ''));
    }
  } else {
    a = Number(a.toString().replace('.', ''));
    b = Number(b.toString().replace('.', ''));
  }
  return (a + b) / m;
}

// 通用下载方法
export const download = (fileName) => {
  window.location.href = constantconfig.downLoadUrl + "/hss/common/download?fileName=" + fileName + "&delete=" + false
  // window.open(constantconfig.downLoadUrl + "/hss/common/download?fileName=" + fileName + "&delete=" + false);
}