import axios from '../http.interceptor'

export default {

  // 获取道路属性全部列表
  getRoadAttributeAllList: () => axios.post('/hss/gridlevel/list'),

  // 获取道路属性详情
  getRoadAttributeDetail: (params) => axios.post('/hss/gridlevel/detail', params),

  // 新增道路属性
  createRoadAttribute: (params) => axios.post('/hss/gridlevel/add', params),

  // 修改道路属性
  editRoadAttribute: (params) => axios.post('/hss/gridlevel/update', params),

  // 删除道路属性
  deleteRoadAttribute: (params) => axios.post('/hss/gridlevel/del', params),

  // 获取网格树列表
  getGridTreeList: (params) => axios.post('/hss/grid/tree', params),

  // 获取网格列表
  getGridList: (params) => axios.post('/hss/grid/list', params),

  // 获取网格详情
  getGridDetail: (params) => axios.post('/hss/grid/detail', params),

  // 新增网格
  createGrid: (params) => axios.post('/hss/grid/add', params),

  // 修改网格
  editGrid: (params) => axios.post('/hss/grid/update', params),

  // 删除网格
  deleteGrid: (params) => axios.post('/hss/grid/del', params),

  // 获取电子围栏列表
  getEnclosureList: (params) => axios.post('/hss/grid/getList', params),

  // 解绑
  unboundEnclosurePersonOrCar: (params) => axios.post('/hss/gridBind/delete', params),

  // 检查是否有重复时间
  checkEnclosurePersonOrCar: (params) => axios.post('/hss/grid/checkGridList', params),

  // 人车绑定电子围栏
  boundEnclosurePersonOrCar: (params) => axios.post('/hss/grid/updateAndInsert', params),

  // 电子围栏绑定人车
  boundEnclosurePersonOrCar2: (params) => axios.post('/hss/grid/updateAndInsert2', params),

}