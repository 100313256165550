import axios from '../http.interceptor'

export default {
  // 登录
  login: (params) => axios.post('/hss/user/loginByPwd', params),

  // 修改密码
  editPwd: (params) => axios.post('/hss/user/modifyPwd', params),

  // 获取用户树列表
  getUserTreeList: () => axios.post('/hss/user/admin/tree'),

  // 获取用户列表
  getUserList: (params) => axios.post('/hss/user/admin/list', params),

  // 新增用户
  createUser: (params) => axios.post('/hss/user/admin/addUser', params),

  // 删除用户
  deleteUser: (params) => axios.post('/hss/user/admin/del', params),

  // 修改用户
  editUser: (params) => axios.post('/hss/user/admin/update', params),

  // 获取角色全部列表
  getRoleAllList: (params) => axios.post('/hss/role/selectRoleList', params),

  // 获取角色列表
  getRoleList: (params) => axios.post('/hss/role/getrolelist', params),

  // 新增角色
  createRole: (params) => axios.post('/hss/role/insertrole', params),

  // 删除角色
  deleteRole: (params) => axios.post('/hss/role/deleteroleByid', params),

  // 修改角色
  editRole: (params) => axios.post('/hss/role/updaterole', params),

  // 修改角色状态
  editRoleStatus: (params) => axios.post('/hss/role/changeStatus', params),

  // 获取角色详情
  getRoleDetail: (params) => axios.post('/hss/role/getrolebyid', params),

  // 获取菜单树列表
  getMenuTreeList: () => axios.post('/hss/sysmenu/getTreeList'),

  // 获取用户详情
  getUserDetail: () => axios.get('/hss/user/detail'),

}