import vm from '../../main.js'

export default {
  state: {
    videoConfig: {},
    videoConfigVisited: false,
  },
  getters: {
    // 获取用户树列表
    getVideoConfig: state => state.videoConfig,
  },
  mutations: {
    // 设置用户树列表
    setVideoConfig(state, arr) {
      state.videoConfig = arr
      state.videoConfigVisited = true
    },
  },
  actions: {
    // 更新用户树列表
    updateVideoConfig({
      commit,
      state
    }, ignorant) {
      if (!state.videoConfigVisited || ignorant) {
        vm.$http.getVideoConfig().then(res => {
          if (res.code === 200) {
            commit('setVideoConfig', res.result)
          }
        })
      }
    },
  }
}