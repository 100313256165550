import vm from '../../main.js'

export default {
  state: {
    elementList: [],
    elementListVisited: false,
  },
  getters: {
    // 获取综合监管-要素概况
    getElementList: state => state.elementList,
  },
  mutations: {
    // 设置综合监管-要素概况
    setElementList(state, arr) {
      state.elementList = arr
      state.elementListVisited = true
    },
  },
  actions: {
    // 更新综合监管-要素概况
    updateElementList({
      commit,
      state
    }, ignorant) {
      if (!state.elementListVisited || ignorant) {
        vm.$http.getMapTypeTotalNumber().then(res => {
          if (res.code === 200) {
            commit('setElementList', res.result)
          }
        })
      }
    },
  }
}