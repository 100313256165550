export default [{
  path: 'carCost',
  name: 'carCost',
  component: () => import('../../views/admin/cost/carCost'),
  meta: {
    permission: []
  }
},{
  path: 'costSummary',
  name: 'costSummary',
  component: () => import('../../views/admin/cost/costSummary'),
  meta: {
    permission: []
  }
},{
  path: 'costDictionaries',
  name: 'costDictionaries',
  component: () => import('../../views/admin/cost/costDictionaries'),
  meta: {
    permission: []
  }
},{
  path: 'personCost',
  name: 'personCost',
  component: () => import('../../views/admin/cost/personCost'),
  meta: {
    permission: []
  }
},{
  path: 'operatingCost',
  name: 'operatingCost',
  component: () => import('../../views/admin/cost/operatingCost'),
  meta: {
    permission: []
  }
}, ]