import axios from '../http.interceptor'

export default {
  // 获取巡查案件数量统计
  getPatrolCaseAmount: () => axios.post('/hss/cases/amount'),

  // 获取巡查案件列表
  getPatrolCaseList: (params) => axios.post('/hss/cases/list', params),

  // 获取巡查案件详情
  getPatrolCaseDetail: (params) => axios.post('/hss/cases/detail', params),

  // 新建巡查案件
  createPatrolCase: (params) => axios.post('/hss/cases/add', params),

  // 修改巡查案件
  editPatrolCase: (params) => axios.post('/hss/cases/update', params),

  // 删除巡查案件
  deletePatrolCase: (params) => axios.post('/hss/cases/del', params),

  // 归档巡查案件
  archivePatrolCase: (params) => axios.post('/hss/cases/archive', params),

  // 处理巡查案件
  managePatrolCase: (params) => axios.post('/hss/cases/chuli', params),

  // 结案巡查案件
  closePatrolCase: (params) => axios.post('/hss/cases/jiean', params),

  // 催办巡查案件
  urgePatrolCase: (params) => axios.post('/hss/cases/cuiban', params),

  // 废弃巡查案件
  discardPatrolCase: (params) => axios.post('/hss/cases/feiqi', params),

  // 恢复巡查案件
  regainPatrolCase: (params) => axios.post('/hss/cases/huifu', params),

  // 指派巡查案件
  assignPatrolCase: (params) => axios.post('/hss/cases/assigned', params),

  // 改派巡查案件
  reassignmentPatrolCase: (params) => axios.post('/hss/cases/gaipai', params),

  // 自动指派巡查案件
  automaticAssignPatrolCase: (params) => axios.post('/hss/cases/autoAssign', params),

  // 获取工单等级全部列表
  getWorkOrderLevelAllList: () => axios.post('/hss/casesetlevel/list'),

  // 获取工单问题类型树列表
  getWorkOrderQuestTypeTreeList: () => axios.post('/hss/casesetissue/tree'),


  // 设置工单 start
  // 获取上报设置、逾期提醒、指派半径
  getWorkOrderReported: () => axios.post('/hss/casesetsys/detail'),

  // 获取问题来源列表
  getWorkOrderQuestSourceList: () => axios.post('/hss/casesetissuesource/list'),

  // 删除问题来源
  deleteWorkOrderQuestSource: (params) => axios.post('/hss/casesetissuesource/del', params),

  // 新增问题类型
  createdWorkOrderQuestType: (params) => axios.post('/hss/casesetissue/add', params),

  // 删除问题类型
  deleteWorkOrderQuestType: (params) => axios.post('/hss/casesetissue/del', params),

  // 修改问题类型
  editWorkOrderQuestType: (params) => axios.post('/hss/casesetissue/update', params),

  // 工单统一修改
  editWorkOrder: (params) => axios.post('/hss/caseset/updateBatch', params),

  editWorkOrderLevel: (params) => axios.post('/hss/caseset/saveOrUpdateBatchCasesetLevel', params), // 等级与限时

  editWorkOrderIssue: (params) => axios.post('/hss/caseset/saveOrUpdateBatchCasesetIssue', params), // 问题类型

  editWorkOrderIssuesource: (params) => axios.post('/hss/caseset/saveOrUpdateBatchCasesetIssueSource', params), // 问题来源

  editWorkOrderSys: (params) => axios.post('/hss/caseset/casesetSysUpdateById', params), // 上报设置，逾期提醒，指派半径
  // 设置工单 end


  // 获取应急计划列表
  getTlemergentplanGetList: (params) => axios.post('/hss/tlemergentplan/getList', params),

  // 获取应急预案列表
  getEmergencyPlanGetList: (params) => axios.post('/hss/tlresplan/getList', params),

  // 获取应急等级下拉
  getEmergencyLevelSelectList: () => axios.post('/hss/tldictlevel/getSelectList'),

  // 获取应急等级列表
  getEmergencyLevelList: (params) => axios.post('/hss/tldictlevel/getList', params),

  // 新增应急等级
  createdEmergencyLevel: (params) => axios.post('/hss/tldictlevel/insert', params),

  // 修改应急等级
  editEmergencyLevel: (params) => axios.post('/hss/tldictlevel/update', params),

  // 获取应急等级详情
  getEmergencyLevelDetail: (params) => axios.post('/hss/tldictlevel/getDetail', params),

  // 删除应急等级
  deleteEmergencyLevel: (params) => axios.post('/hss/tldictlevel/deletebyid', params),

  // 获取应急计划名称列表
  getEmergencyPlanList: (params) => axios.post('/hss/tlemergentplan/getSelectList', params),

  // 获取应急类型下拉
  getEmergencyTypeSelectList: (params) => axios.post('/hss/tldicttype/getSelectList', params),

  // 获取应急类型列表
  getEmergencyTypeList: (params) => axios.post('/hss/tldicttype/getList', params),

  // 新增应急类型
  createdEmergencyType: (params) => axios.post('/hss/tldicttype/insert', params),

  // 修改应急类型
  editEmergencyType: (params) => axios.post('/hss/tldicttype/update', params),

  // 获取应急类型详情
  getEmergencyTypeDetail: (params) => axios.post('/hss/tldicttype/getDetail', params),

  // 新增应急预案
  createdEmergencyPlanGetList: (params) => axios.post('/hss/tlresplan/insert', params),

  // 修改应急预案
  editEmergencyPlan: (params) => axios.post('/hss/tlresplan/update', params),

  // 批量禁用 启用应急预案
  disableBatchEmergencyPlan: (params) => axios.post('/hss/tlresplan/changeStatus', params),

  // 获取应急预案详情
  getEmergencyPlanDetail: (params) => axios.post('/hss/tlresplan/getDetail', params),

  // 删除应急预案
  deleteEmergencyPlan: (params) => axios.post('/hss/tlresplan/deletebatchbyid', params),

  // 获取应急预案下拉
  getEmergencyPlanSelectList: (params) => axios.post('/hss/tlresplan/getSelectList', params),

  // 新增应急计划
  createdEmergentPlanning: (params) => axios.post('/hss/tlemergentplan/insert', params),

  // 删除应急计划
  deleteEmergentPlanning: (params) => axios.post('/hss/tlemergentplan/deletebatchbyid', params),

  // 修改应急计划
  editEmergentPlanning: (params) => axios.post('/hss/tlemergentplan/update', params),

  // 获取应急计划详情
  getEmergentPlanningDetail: (params) => axios.post('/hss/tlemergentplan/getDetail', params),

  // 应急计划处理签到
  emergentPlanningDispose: (params) => axios.post('/hss/tlemergentplan/audit', params),

  // 获取应急计划车的列表
  getPlanCarList: (params) => axios.post('/hss/carPlan/getCarShiftStatus', params),

  // 获取应急计划人的列表
  getPlanPersonList: (params) => axios.post('/hss/empPlan/getEmployeeShiftStatus', params),

  // 获取车辆违规列表
  getCarViolationList: (params) => axios.post('/hss/carviolation/list', params),
// 巡查案件导出
exportPatrolCase:(params) => axios.post('hss/cases/casesListExcel', params),
  // 车辆违规处理
  dealCarViolations: (params) => axios.post('/hss/carviolation/deal', params),

  // 导出车辆违规
  exportCarViolations: (params) => axios.post('/hss/carviolation/excelExport', params),

  // 获取人员违规列表
  getPersonViolationList: (params) => axios.post('/hss/dlbjviolation/list', params),

  // 人员违规处理
  dealPersonViolations: (params) => axios.post('/hss/dlbjviolation/deal', params),

  // 导出人员违规
  exportPersonViolations: (params) => axios.post('/hss/dlbjviolation/excelExport', params),
  // 作业违规 end


  // 运行报警 start
  // 获取车辆报警列表
  getCarRunAlarmList: (params) => axios.post('/hss/alarmcar/list', params),

  // 车辆报警处理
  dealCarAlarm: (params) => axios.post('/hss/alarmcar/deal', params),

  // 导出车辆报警
  exportCarAlarm: (params) => axios.post('/hss/alarmcar/excelExport', params),

  // 获取人员报警列表
  getPersonAlarmList: (params) => axios.post('/hss/alarmperson/list', params),

  // 人员报警处理
  dealPersonAlarm: (params) => axios.post('/hss/alarmperson/deal', params),

  // 导出人员报警
  exportPersonAlarm: (params) => axios.post('/hss/alarmperson/excelExport', params),

  // 获取垃圾桶报警列表
  getAshbinAlarmList: (params) => axios.post('/hss/alarmashbin/list', params),

  // 垃圾桶报警处理
  dealAshbinAlarm: (params) => axios.post('/hss/alarmashbin/deal', params),

  // 获取设施报警列表
  getFacilityAlarmList: (params) => axios.post('/hss/alarmsheshi/list', params),

  // 设施报警处理
  dealFacilityAlarm: (params) => axios.post('/hss/alarmsheshi/deal', params),

  // 导出设施报警
  exportFacilityAlarm: (params) => axios.post('/hss/alarmsheshi/excelExport', params),
  // 运行报警 end

  // 处理报警记录列表
  getProcessingAlarmRecord: (params) => axios.post('/hss/alarmHandleLog/selectAlarmHandleLogListByAlarmId', params),

  // 处理违规记录列表
  getProcessingViolationRecord: (params) => axios.post('/hss/violationHandleLog/selectViolationHandleLogListByViolationId', params),
}