<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="page-style">
    <HeaderComponent :data="headerMenuArr"
                     :activeName="headerActiveName"
                     class="h-60"></HeaderComponent>
    <div class="bodyView">
      <SliderMenu :data="sliderMenuArr"
                  :activeName="sliderActiveName"
                  :openName="sliderOpenName"
                  class="leftView"></SliderMenu>
      <div class="rightView">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderComponent from './HeaderComponent'
import SliderMenu from './SliderMenu'
export default {
  name: 'home',
  components: { HeaderComponent, SliderMenu },
  data () {
    return {
      headerActiveName: '',
      sliderActiveName: '',
      sliderOpenName: [],
      sliderMenuArr: [],
    }
  },
  watch: {
    '$route.name' (newVal) {
      this.menuInit(newVal)
    },
    headerMenuArr () {
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapGetters({
      'headerMenuArr': 'getMenuArr',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      setTimeout(() => {
        this.menuInit(this.$route.name)
      }, 1000);
    },
    menuInit (name) {
      let headerActiveName    // 顶部的菜单
      let sliderOpenName      // 左侧的菜单
      // 菜单 点击上方拿到children放到左边显示出来
      for (const item of this.headerMenuArr) {
        if (item.children) {
          // 二级菜单
          for (const items of item.children) {
            // 如果二级菜单的name == 传入的name
            if (items.name === name) {
              headerActiveName = item.name
              this.sliderMenuArr = item.children
              break
            } else {
              if (items.children) {
                for (const itemss of items.children) {
                  if (itemss.name === name) {
                    this.sliderMenuArr = item.children
                    headerActiveName = item.name
                    sliderOpenName = items.name
                    break
                  }
                }
              }
            }
          }
        }
      }
      this.sliderActiveName = name
      sliderOpenName && (this.sliderOpenName = [sliderOpenName])
      this.headerActiveName = headerActiveName
    }
  }
}

</script>
<style lang='scss' scoped>
.page-style {
  width: 100%;
  height: 100%;
}

.bodyView {
  padding-top: 60px;
  height: calc(100vh - 60px);
}

.leftView {
  height: calc(100vh - 60px);
}

.rightView {
  height: calc(100vh - 60px);
  padding-left: 176px;
}
</style>