// 统计汇总报表
import axios from '../http.interceptor'

export default {
  // 获取加油汇总 echars 数据
  getRefuelSummaryEchars: (params) => axios.post('/hss/addOil/selectForBTable', params),

  // 获取加油汇总各机构加油列表
  getRefuelSummaryDept: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceTableByDepId', params),

  // 获取各作业分类加油报表
  getJobClassificationRefuel: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceTableByOperType', params),

  // 获取各车型加油报表
  getModelsRefuel: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceTableByCarType', params),

  // 获取耗油 echars 数据
  getFuelConsumptionEchars: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceForBTable', params),

  // 获取耗油列表数据
  getFuelConsumptionList: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceForTTable', params),

  // 获取漏油 echars 数据
  getOilSpillEchars: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceOfOutForBTable', params),

  // 获取加油 echars 数据
  getRefuelEchars: (params) => axios.post('/hss/deviceOil/selectStateOilDeviceOfAddForBTable', params),

  // 获取加油汇总饼图 数据
  getRefuelSummaryPieEchars: (params) => axios.post('/hss/deviceOil/getRefuelingSummaryECharsVO', params),

  // 导出加油
  exportRefuelSummary: (params) => axios.post('/hss/deviceOil/selectStateOilExcelExport', params),

  // 导出加油汇总
  exportRefuelSummaryEx: (params) => axios.post('/hss/deviceOil/AddOilSummaryExcel', params),

  //行驶报表 行驶里程报表 车辆行驶报表 echars 数据
  getDrivingMileageEchars: (params) => axios.post('/hss/deviceOil/selectMileageReportChars', params),

  // 行驶报表 行驶里程报表 行驶汇总列表
  getDrivingMileageList: (params) => axios.post('/hss/deviceOil/selectMileageReportTable', params),

  // 行驶报表 停留报表 echars 数据
  getStayEachars: (params) => axios.post('/hss/deviceOil/stayReportEChars', params),

  // 行驶报表 停留报表列表数据
  getStayList: (params) => axios.post('/hss/deviceOil/stayReportTable', params),

  // 行驶报表 停留报表详情列表数据
  getStayDetailList: (params) => axios.post('/hss/deviceOil/stayReportTableDetail', params),

  // 导出行驶里程
  exportDrivingMileage: (params) => axios.post('/hss/deviceOil/selectMileageReportExcel', params),

  // 导出停留里程
  exportStay: (params) => axios.post('/hss/deviceOil/stayReportExcel', params),

  // 获取事件报表 按责任机构 事件分布 问题类型 echars 数据
  getDeptProblemEchars: (params) => axios.post('/hss/cases/getProblemTypeHistogramEChars', params),

  // 获取事件报表 按责任机构 事件分布 时间走势 echars 数据
  getDeptTimeEchars: (params) => axios.post('/hss/cases/getDistributionTimeTrendEChars', params),

  // 获取事件报表 按责任机构 事件分布 来源统计 列表
  getDeptSourceList: (params) => axios.post('/hss/cases/getProblemSourceTable', params),

  // 获取事件报表 按责任机构 事件分布 问题类型 列表
  getDeptProblemList: (params) => axios.post('/hss/cases/getProblemTypeTableVOTable', params),

  // 获取事件报表 按责任机构 处理情况 echars 数据
  getDeptSituationTotalEventsEchars: (params) => axios.post('/hss/cases/getTreatmentEChars', params),

  // 获取事件报表 按责任机构 处理情况 列表数据
  getDeptSituationList: (params) => axios.post('/hss/cases/getTreatmentTable', params),

  // 获取事件报表 按责任机构 处理分析 列表数据
  getDeptSituationAnalysisList: (params) => axios.post('/hss/cases/getTimelyAnalysisTable', params),

  // 获取事件报表 按责任机构 总事件处理情况 echars 数据
  getDeptSituationAnalysisEchars: (params) => axios.post('/hss/cases/getTimelyAnalysisEChars', params),

  // 导出事件报表 按责任机构 处理情况 列表数据
  exportDeptSituation: (params) => axios.post('/hss/cases/getTreatmentTableExcel', params),

  // 导出事件报表 按责任机构 处理分析 列表数据
  exportDeptSituationAnalysis: (params) => axios.post('/hss/cases/getTimelyAnalysisExcel', params),



  // 获取事件报表 按责任人 事件分布 问题类型 echars 数据
  getPersonProblemEchars: (params) => axios.post('/hss/cases/eventPersonByType', params),

  // 获取事件报表 按责任人 事件分布 时间走势 echars 数据
  getPersonTimeEchars: (params) => axios.post('/hss/cases/eventPersonByTime', params),

  // 获取事件报表 按责任人 事件分布 来源统计 列表
  getPersonSourceList: (params) => axios.post('/hss/cases/eventPersonByCreater', params),

  // 获取事件报表 按责任人 事件分布 问题类型 列表
  getPersonProblemList: (params) => axios.post('/hss/cases/eventPersonByProblem', params),

  // 获取事件报表 按责任人 处理情况 总事件处理情况 echars 数据
  getPersonSituationTotalEventsEchars: (params) => axios.post('/hss/cases/eventPersonTreatment', params),

  // 获取事件报表 按责任人 处理情况 列表数据
  getPersonSituationList: (params) => axios.post('/hss/cases/eventPersonTreatmentTable', params),

  // 获取事件报表 按责任人 处理分析 列表数据
  getPersonSituationAnalysisEchars: (params) => axios.post('/hss/cases/eventPersonAnalysis', params),

  // 获取事件报表 按责任人 处理分析 列表数据
  getPersonSituationAnalysisList: (params) => axios.post('/hss/cases/eventPersonAnalysisTable', params),

  // 导出事件报表 按责任人 处理情况 列表数据
  exportPersonSituation: (params) => axios.post('/hss/cases/eventPersonTreatmentExcel', params),

  // 导出事件报表 按责任人 处理分析 列表数据
  exportPersonSituationAnalysis: (params) => axios.post('/hss/cases/eventPersonAnalysisTableExcel', params),

  // 导出事件报表 按事件分布 来源 列表数据
  exportCaseSituation: (params) => axios.post('/hss/cases/eventPersonByCreaterExcel', params),

  // 导出事件报表 按事件分布 问题类型 列表数据
  exportCaseSituationAnalysis: (params) => axios.post('/hss/cases/eventPersonByProblemExcel', params),


  // 报警报表 车辆报警 报警分析 echars 数据
  getCarAlarmEchars: (params) => axios.post('/hss/alarmcar/selectAlarmCarForCharts', params),

  // 报警报表 车辆报警 报警汇总 列表数据
  getCarAlarmList: (params) => axios.post('/hss/alarmcar/selectAlarmCarForTable', params),

  // 报警报表 车辆报警 报警明细 列表数据
  getCarAlarmDetailList: (params) => axios.post('/hss/alarmcar/selectAlarmCarForTableDetail', params),

  // 导出报警报表 车辆报警 报警分析 echars 数据  汇总
  exportCarAlarmReportForm: (params) => axios.post('/hss/alarmcar/selectAlarmCarForExcel', params),
  // 导出报警报表 车辆报警 报警分析 echars 数据  明细
  exportCarAlarmDetailReportForm: (params) => axios.post('/hss/alarmcar/selectAlarmCarForExcelDetail', params),
  // 导出报警报表 设施报警 报警分析 echars 数据  汇总
  exportFaclAlarmReportForm: (params) => axios.post('/hss/alarmsheshi/reportForExcelExport', params),
  // 导出报警报表 设施报警 报警分析 echars 数据  明细
  exportFaclAlarmDetailReportForm: (params) => axios.post('/hss/alarmsheshi/reportForExcelExportDetail', params),
  // 导出报警报表 人员报警 报警分析 echars 数据  汇总
  exportPersonAlarmReportForm: (params) => axios.post('/hss/alarmperson/alarmPersonChartsTableExcelExport', params),
  // 导出报警报表 人员报警 报警分析 echars 数据  明细
  exportPersonAlarmDetailReportForm: (params) => axios.post('/hss/alarmperson/alarmPersoneDetailExport', params),

  // 报警报表 人员报警 报警分析 echars 数据
  getPersonAlarmEchars: (params) => axios.post('/hss/alarmperson/selectAlarmPersonForCharts', params),

  // 报警报表 人员报警 报警汇总 列表数据
  getPersonAlarmSummaryList: (params) => axios.post('/hss/alarmperson/selectAlarmPersonForChartsTable', params),

  // 报警报表 人员报警 报警明细 列表数据
  getPersonAlarmDetailList: (params) => axios.post('/hss/alarmperson/selectAlarmPersonForTableDetail', params),

  // 报警报表 设施报警 报警分析 echars 数据
  getFacilitiesAlarmEchars: (params) => axios.post('/hss/alarmsheshi/selectAlarmSheshiForCharts', params),

  // 报警报表 设施报警 报警汇总 列表数据
  getFacilitiesAlarmList: (params) => axios.post('/hss/alarmsheshi/selectAlarmSheshiForChartsTable', params),

  // 报警报表 设施报警 报警明细 列表数据
  getFacilitiesAlarmDetailList: (params) => axios.post('/hss/alarmsheshi/selectAlarmSheshiForTableDetail', params),

  // 设施报警下拉列表
  getFacilitiesAlarmSelect: () => axios.post('/hss/tlfacilitiestype/getTreeForSheshiAlarm'),

  // 报警报表 机构报警 报警分析 echars 数据
  getDeptAlarmEchars: (params) => axios.post('/hss/sysdepart/selectDepartAlarmChars', params),

  // 报警报表 机构报警 报警汇总 列表数据
  getDeptAlarmList: (params) => axios.post('/hss/sysdepart/selectDepartAlarmCharsTale', params),


  // 设施报表 公厕人流统计 echars
  getPublicToiletEcharts: (params) => axios.post('/hss/tlfacilities/getToiletPersonZX', params),

  // 设施报表 公厕人流统计 统计列表
  getPublicToiletStatistics: (params) => axios.post('/hss/tlfacilities/getToiletPerson', params),

  // 设施报表 公厕人流统计 明细列表
  getPublicToiletDetail: (params) => axios.post('/hss/tlfacilities/getToiletPersonMX', params),

  // 导出 设施报表 公厕人流统计 明细列表
  exportPublicToiletReportForm: (params) => axios.post('/hss/tlfacilities/getToiletPersonExcel', params),
  exportPublicToiletDetailReportForm: (params) => axios.post('/hss/tlfacilities/getToiletPersonMXExcel', params),
  // 违规报表  车辆违规报表上图
  getCarViolationsEchars: (params) => axios.post('/hss/carviolation/selectCarViolationForCharts', params),
  // 违规报表  车辆违规报表下表
  getCarViolationsList: (params) => axios.post('/hss/carviolation/selectCarViolationForTable', params),
  // 违规报表  车辆违规报表明细下表
  getCarViolationsDetail:(params) => axios.post('/hss/carviolation/selectCarViolationForTableDetail', params),
  // 违规报表  人员违规报表上图
  getPersonViolationsEachars: (params) => axios.post('/hss/dlbjviolation/selectDlbjViolationForCharts', params),
  // 违规报表  人员违规报表下表
  getPersonViolationsList: (params) => axios.post('/hss/dlbjviolation/selectDlbjViolationForTable', params),
  // 违规报表  人员违规报表明细下表
  getPersonViolationsDetail: (params) => axios.post('/hss/dlbjviolation/selectDlbjViolationForTableDetail', params),
  // 人员考勤汇总
  getPersonAttendance: (params) => axios.post('/hss/empPlan/attendanceCount', params),

  // 人员考勤明细
  getPersonAttendanceDetail: (params) => axios.post('/hss/empPlan/attendanceDetail', params),
}