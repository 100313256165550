import vm from '../../main.js'

export default {
  state: {
    departmentTreeList: [],
    departmentTreeListVisited: false,
    departmentKeyMap: {
      'id': 'id',
      'departName': 'departName',
      'type': 'orgType',
      'parentId': 'parentId',
      'parentName': 'parentDepartName',
      'personId': 'principalUid',
      'person': 'principalName',
      'phone': 'mobile',
      'remark': 'description',
      'userPhone': 'userPhone'
    }
  },
  getters: {
    // 获取部门树列表
    getDepartmentTreeList: state => state.departmentTreeList,
  },
  mutations: {
    // 设置部门树列表
    setDepartmentTreeList(state, arr) {
      state.departmentTreeList = arr
      state.departmentTreeListVisited = true
    },
  },
  actions: {
    // 更新部门树列表
    updateDepartmentTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.departmentTreeListVisited || ignorant) {
        vm.$http.getDepartmentTreeList().then(res => {
          if (res.code === 200) {
            commit('setDepartmentTreeList', res.result)
          }
        })
      }
    },
    // 新增/修改部门
    createOrEditDepart({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.departmentKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editDepartment(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createDepartment(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取部门详情
    getDepartDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getDepartmentDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.departmentKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}