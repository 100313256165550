import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// iview
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import './scss/my-theme/index.less'
Vue.use(ViewUI);

// 预览视频
import previewVideo from './directives/previewVideo'
Vue.directive('previewVideo', previewVideo)

// 输入限制
import inputLimit from './directives/inputLimit'
Object.keys(inputLimit).forEach(k => Vue.directive(k, inputLimit[k]))


import hasPermi from './directives/hasPermi'
Vue.directive('hasPermi', hasPermi)

// api
import http from './api/modules'
Vue.prototype.$http = http

// import TRTCCalling from 'trtc-calling-js';
// Vue.prototype.TrtcCalling = TRTCCalling;

import {
  Calendar,
  Image
} from 'element-ui';
Vue.use(Calendar)
Vue.use(Image)

import {
  keyMapNew,
  swapKeyValue,
  timeDifference,
  timeSecondsTransition,
  times,
  subtraction,
  addition
} from './utils/utils'
Vue.prototype.$keyMapNew = keyMapNew
Vue.prototype.$swapKeyValue = swapKeyValue
Vue.prototype.$timeDifference = timeDifference
Vue.prototype.$timeSecondsTransition = timeSecondsTransition
Vue.prototype.$times = times
Vue.prototype.$subtraction = subtraction
Vue.prototype.$addition = addition

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')