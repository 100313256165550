export const isOnLine = process.env.NODE_ENV === 'production' // process.env.NODE_ENV === 'production'

// 接口域名
export const urlConfig = {
  dev: 'http://127.0.0.1:8080',
  pro: '/'
}

// 下载地址
export const downLoadUrlConfig = {
  dev: 'http://127.0.0.1:8080',
  pro: ''
}

// socket域名
export const socketUrlConfig = {
  dev: 'http://127.0.0.1:8080',
  pro: ''
}

// 项目名称
export const projectName = '智慧环卫演示版'

// 是否启用权限
export const isEnablePermission = false

// 车载视频配置
export const socketVideoConfig = {
  dev: {
    clusterHost: '47.105.65.226',
    clusterPort: '17000'
  },
  pro: {
    clusterHost: '',
    clusterPort: '17000'
  }
}

// 监控视频配置
export const httpVideoConfig = {
  dev: 'http://47.105.65.226:8089',
  pro: ''
}

export default {
  isOnLine,
  projectName,
  isEnablePermission,
  baseUrl: isOnLine ? urlConfig.pro : urlConfig.dev,
  downLoadUrl: isOnLine ? downLoadUrlConfig.pro : downLoadUrlConfig.dev,
  socketUrl: isOnLine ? socketUrlConfig.pro : socketUrlConfig.dev,
  socketVideoConfig: isOnLine ? socketVideoConfig.pro : socketVideoConfig.dev,
  httpVideoConfig: isOnLine ? httpVideoConfig.pro : httpVideoConfig.dev,
}
