import menuArrs from '../../config/menuconfig';
import {
  isEnablePermission
} from '@/config/constantconfig';
import router from '@/router'

export default {
  state: {
    adminRedirect: '',
    menuArr: []
  },
  getters: {
    // 获取admin重定向
    getAdminRedirect: state => state.adminRedirect,
    // 获取菜单列表
    getMenuArr: state => state.menuArr,
  },
  mutations: {
    // 设置admin重定向
    setAdminRedirect(state, arr) {
      state.adminRedirect = arr
    },
    // 设置菜单列表
    setMenuArr(state, arr) {
      state.menuArr = arr
    },
  },
  actions: {
    // 更新菜单列表
    updateMenuArr({
      commit
    }, permission) {
      let newMenuArr = []
      if (isEnablePermission) {
        // 1.拿到我能访问的菜单 2.重定向
        let redirectBoo = false
        menuArrs.forEach(item => {
          let boo = permission.some(p => p.applabel === item.name)
          if (boo) {
            if (item.children) {
              let children = []
              let redirectBoo = false
              item.children.forEach(items => {
                // applable对应菜单的name对应路由的name
                let boo = permission.some(p => p.applabel === items.name)
                if (boo) {
                  if (items.children) {
                    let children = []
                    let redirectBoo = false
                    items.children.forEach(itemss => {
                      let boo = permission.some(p => p.applabel === itemss
                        .name)
                      if (boo) {
                        // 重置重定向
                        if (!redirectBoo) {
                          items.url = itemss.url
                          redirectBoo = true
                        }
                        children.push(itemss)
                      }
                    })
                    items.children = children
                  }
                  // 重置重定向(数组中第一个)
                  if (!redirectBoo) {
                    item.url = items.url
                    redirectBoo = true
                  }
                  // 有权限
                  children.push(items)
                }
              })
              item.children = children
            }
            // 获取第一个具有权限的route对象，并拿到其url赋值给adminRedirect
            if (!redirectBoo && item.name !== 'main' && item.name !== 'videoSurveillance') {
              // 设置后台的重定向
              commit('setAdminRedirect', item.url)
              redirectBoo = true
            }
            newMenuArr.push(item)
          }
        })
      } else {
        newMenuArr = menuArrs
      }
      commit('setMenuArr', newMenuArr)
    },
    // 更新路由权限
    updateRoutePermission({
      state
    }, permission) {
      if (isEnablePermission) {
        // 所有的路由
        let routes = router.options.routes
        routes.forEach(item => {
          let boo = permission.some(p => p.applabel === item.name)
          if (boo) {
            item.meta.permission = ['visible']
          }
          // 给admin的redirect赋值，如果没有权限，则重定向到login
          if (item.name === 'admin') {
            if (state.adminRedirect) {
              item.redirect = state.adminRedirect
            } else {
              item.redirect = '/login'
            }
          }
          if (item.children) {
            item.children.forEach(items => {
              let boo = permission.some(p => p.applabel === items.name)
              if (boo) {
                items.meta.permission = ['visible']
              }
            })
          }
        })
      }
    }
  }
}