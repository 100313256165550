import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from '@/store'
import iView from 'view-design'
import {
  projectName,
  isEnablePermission
} from '@/config/constantconfig';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  iView.LoadingBar.start()
  // 修改html title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = projectName
  }
  // 需要拦截登录时开启
  if (to.matched.some(res => res.meta.norequirelogin)) {
    next()
  } else {
    if (store.getters.getUserDetailVisited) {
      // 启用权限
      if (isEnablePermission) {
        let permission = to.meta.permission
        // 有权限的放行 next()
        if (permission && permission.some(item => item === 'visible')) {
          next()
        } else {
          // 去的是一张网 且 离开的不是admin
          if (to.name === 'main' && from.path.indexOf('/admin') === -1) {
            next({
              name: 'admin'
            })
            return
          }
          iView.Message.info('没有权限')
          store.dispatch('loginOut')
          next({
            name: 'login'
          })
        }
      } else {
        next()
      }
    } else {
      // 获取用户详情(获取用户详情的拦截是因为涉及到多页面，视频监控页是新标签页)
      // 登录成功后 / 刷新页面 都会重新获取用户详情，
      store.dispatch('getUserDetail').then(() => {
        // 启用权限
        if (isEnablePermission) {
          let permission = to.meta.permission
          // 有权限的放行 next()
          if (permission && permission.some(item => item === 'visible')) {
            next()
          } else {
            // 去的是一张网 且 离开的不是admin
            if (to.name === 'main' && from.path.indexOf('/admin') === -1) {
              next({
                name: 'admin'
              })
              return
            }
            iView.Message.info('没有权限')
            store.dispatch('loginOut')
            next({
              name: 'login'
            })
          }
        } else {
          next()
        }
      }).catch(() => {
        store.dispatch('loginOut')
        next({
          name: 'login'
        })
      })
      // 获取用户提醒设置
      store.dispatch('getMapRemindDetail').then(res => {
        next()
      })
    }
  }
})
router.afterEach(() => {
  iView.LoadingBar.finish()
})
export default router