import axios from '../http.interceptor'

export default {
  // 获取设施大类全部列表
  getFacilityBigTypeAllList: (params) => axios.post('/hss/tlfacilitiestype/getSelectList', params),

  // 获取设施小类全部列表
  getFacilitySmallTypeAllList: (params) => axios.post('/hss/tlfacilitiestypeitems/getSelectList', params),

  // 获取树种全部列表
  getTreeVarietiesAllList: (params) => axios.post('/hss/tltreespec/getSelectList', params),

  // 获取养护资料全部列表
  getConserveAllList: (params) => axios.post('/hss/tlcuringknowledge/getSelectList', params),

  // 传大类id获取小类 设施树
  getFacilityAllList: (params) => axios.post('/hss/elementtotal/getTrashTree', params),

  // 获取设施小类列表
  getFacilitySmallTypeList: (params) => axios.post('/hss/tlfacilitiestypeitems/getList', params),

  // 获取设施小类详情
  getFacilitySmallTypeDetail: (params) => axios.post('/hss/tlfacilitiestypeitems/getDetail', params),

  // 新增设施小类
  createFacilitySmallType: (params) => axios.post('/hss/tlfacilitiestypeitems/insert', params),

  // 修改设施小类
  editFacilitySmallType: (params) => axios.post('/hss/tlfacilitiestypeitems/update', params),

  // 删除设施小类
  deleteFacilitySmallType: (params) => axios.post('/hss/tlfacilitiestypeitems/deletebyid', params),

  // 批量删除设施小类
  deleteBatchFacilitySmallType: (params) => axios.post('/hss/tlfacilitiestypeitems/deletebatchbyid', params),

  // 获取设施列表
  getFacilityList: (params) => axios.post('/hss/tlfacilities/getList', params),

  // 获取设施详情
  getFacilityDetail: (params) => axios.post('/hss/tlfacilities/getDetail', params),

  // 获取园林树木详情
  getParkTreeDetail: (params) => axios.post('/hss/tlfacilities/getTreeSpecList', params),

  // 新增设施
  createFacility: (params) => axios.post('/hss/tlfacilities/insert', params),

  // 修改设施
  editFacility: (params) => axios.post('/hss/tlfacilities/update', params),

  // 批量修改设施
  editBatchFacility: (params) => axios.post('/hss/tlfacilities/updatebyids', params),

  // 删除设施
  deleteFacility: (params) => axios.post('/hss/tlfacilities/deletebyid', params),

  // 批量删除设施
  deleteBatchFacility: (params) => axios.post('/hss/tlfacilities/deletebatchbyid', params),

  // 导出设备列表
  exportFacl: (params) => axios.post('hss/tlfacilities/excelExport', params),

  // 导出设备模板
  exportFaclTem: (params) => axios.post('hss/tlfacilities/excelExportTemplate', params),

  // 获取树种列表
  getTreeVarietiesList: (params) => axios.post('/hss/tltreespec/getList', params),

  // 新增树种
  createTreeVarieties: (params) => axios.post('/hss/tltreespec/insert', params),

  // 修改树种
  editTreeVarieties: (params) => axios.post('/hss/tltreespec/update', params),

  // 删除树种
  deleteTreeVarieties: (params) => axios.post('/hss/tltreespec/deletebyid', params),

  // 获取树种详情
  getTreeVarietiesDetail: (params) => axios.post('/hss/tltreespec/getDetail', params),

  // 获取养护知识列表
  getConserveList: (params) => axios.post('/hss/tlcuringknowledge/getList', params),

  // 新增养护知识
  createConserve: (params) => axios.post('/hss/tlcuringknowledge/insert', params),

  // 修改养护知识
  editConserve: (params) => axios.post('/hss/tlcuringknowledge/update', params),

  // 删除养护知识
  deleteConserve: (params) => axios.post('/hss/tlcuringknowledge/deletebyid', params),

  // 获取养护知识详情
  getConserveDetail: (params) => axios.post('/hss/tlcuringknowledge/getDetail', params),

  // 获取科属 树种级联
  getFamilyVarietiesList: (params) => axios.post('/hss/tltreespec/treespecTree', params),

  // 古树详情 获取树种相关信息
  getTreeDetailVarieties: (params) => axios.post('/hss/tlfacilities/getTreeSpec', params),
}