 /**
  * v-hasPermi 操作权限处理
  */

 import store from '@/store'

 export default {
   inserted(el, binding, vnode) {
     const {
       value
     } = binding
     const permissions = store.getters && store.getters.getBtnPermissions
     let isAdmin = permissions == '***' ? true : false
     if (value && value instanceof Array && value.length > 0) {
       const permissionFlag = value

       if (isAdmin) return

       const hasPermissions = permissions.some(permission => {
         return permissionFlag.includes(permission)
       })
       if (!hasPermissions) {
         el.parentNode && el.parentNode.removeChild(el)
       }
     } else {
       throw new Error(`请设置操作权限标签值`)
     }
   }
 }