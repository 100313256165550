export default [{
  path: 'carFuelReport',
  name: 'carFuelReport',
  component: () => import('../../views/admin/statistics/carFuelReport'),
  meta: {
    permission: []
  }
}, {
  path: 'drivingReport',
  name: 'drivingReport',
  component: () => import('../../views/admin/statistics/drivingReport'),
  meta: {
    permission: []
  }
}, {
  path: 'eventReport',
  name: 'eventReport',
  component: () => import('../../views/admin/statistics/eventReport'),
  meta: {
    permission: []
  }
}, {
  path: 'policeReport',
  name: 'policeReport',
  component: () => import('../../views/admin/statistics/policeReport'),
  meta: {
    permission: []
  }
},
{
  path: 'violationReport',
  name: 'violationReport',
  component: () => import('../../views/admin/statistics/violationReport'),
  meta: {
    permission: []
  }
}, {
  path: 'departmentResource',
  name: 'departmentResource',
  component: () => import('../../views/admin/statistics/departmentResource'),
  meta: {
    permission: []
  }
}, {
  path: 'departmentDeparture',
  name: 'departmentDeparture',
  component: () => import('../../views/admin/statistics/departmentDeparture'),
  meta: {
    permission: []
  }
}, {
  path: 'carMaintain',
  name: 'carMaintain',
  component: () => import('../../views/admin/statistics/carMaintain'),
  meta: {
    permission: []
  }
}, {
  path: 'faclReport',
  name: 'faclReport',
  component: () => import('../../views/admin/statistics/faclReport'),
  meta: {
    permission: []
  }
}, {
  path: 'attendanceReport',
  name: 'attendanceReport',
  component: () => import('../../views/admin/statistics/attendanceReport'),
  meta: {
    permission: []
  }
}, ]