import main from '@/api/modules/main'

export default {
  state: {
    eventRemindTime: 30,
    eventRemindNumber: 1,
    eventRemindData: null
  },
  getters: {
    // 获取提醒时长
    getEventRemindTime: state => state.eventRemindTime,
    // 获取显示条数
    getEventRemindNumber: state => state.eventRemindNumber,
    // 获取提醒选择
    getEventRemindData: state => state.eventRemindData,
  },
  mutations: {
    setEventRemindTime(state, data) {
      state.eventRemindTime = data
    },
    setEventRemindNumber(state, data) {
      state.eventRemindNumber = data
    },
    setEventRemindData(state, data) {
      state.eventRemindData = data
    },
  },
  actions: {
    // 获取报警参数详情
    getMapRemindDetail({
      commit
    }) {
      return new Promise((resolve, reject) => {
        main.getMapRemindDetail().then(res => {
          if (res.code === 200) {
            let data = res.result
            commit('setEventRemindTime', data.timeLength)
            commit('setEventRemindNumber', data.showNumber)
            commit('setEventRemindData', data)
            resolve(data)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}