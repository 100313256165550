<template>
  <!-- 网络视频 该组件比较快 但是功能少 -->
  <div class="facl-video"
       ref="httpVideoPlayer"
       :id="dataId+'-'+channelId"></div>
  <!-- <div ref="player"></div> -->
</template>

<script>
import config from '../../config/constantconfig'
import md5 from 'js-md5'
import axios from 'axios'
import { formatDate, getBeforeHourTimeNoStrideDay, isInDateInterval, formatTime, hourMillisecond, dayMillisecond } from '@/utils/dateRangUtil';
// import 'xgplayer';
// import FlvJsPlayer from 'xgplayer-flv.js';
export default {
  components: {},
  props: {
    dataId: String,
    channelId: String,
    startTime: String,
    endTime: String,
    autoplay: {
      type: Boolean,
      default: true
    },
    videoId: Number,
    playbackCurrentTime: Number,
  },
  data () {
    return {
      player: null,
      doubleSpeedPlayer: null,
      config: {
        Height: true
      },
      appId: config.faclVideoAppid,
      videoMsg: {},
      streamId: '',
      isReplay: false,
      reconnectionCount: 0,
      reconnectionStatus: 0,
      speed: 1
    };
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.speed = 1
    // 重连状态重置
    this.reconnectionStatus = 0
    this.close()
  },
  methods: {
    init () {
      if (this.dataId && this.channelId) {
        let appId = this.appId
        let deviceId = this.dataId
        let channel = this.channelId
        let timestamp = new Date().getTime()
        let params = {
          appId,
          deviceId,
          channel,
          timestamp
        }
        if (this.startTime && this.endTime) {
          // 视频回放
          let startTime = this.startTime
          let endTime = this.endTime
          // 自己创建的
          let arr = [{
            type: 'http',
            startTime,
            endTime
          }]
          this.$emit('onSearchPlaybackVideoList', arr)
        } else {
          // 视频点播  md5加密
          let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&timestamp=${timestamp}&secret=123456`)
          params.sign = sign
          axios.post(`${config.httpVideoConfig}/api/playNVR/open`, params).then(res => {
            if (res.code === 200) {
              this.player = new WasmPlayer(null, this.dataId + '-' + this.channelId, this.callbackfun, this.config)
              // res.result.flv 播放地址(流)
              // this.player.play(res.result.flv, this.autoplay ? 1 : 0)
              // http最多只能六个 改为ws
              this.player.play(res.result.ws_flv, this.autoplay ? 1 : 0)
              // this.player = new FlvJsPlayer({
              //   el: this.$refs.player,
              //   url: res.result.flv,
              //   width: '100%',
              //   height: '100%',
              //   autoplay: true,
              //   isLive: true,
              //   playsinline: true,
              //   screenShot: true,
              //   lang: 'zh-cn',
              //   closeVideoClick: true,
              //   closeVideoStopPropagation: true
              // })
              this.$emit('onStart')
            } else {
              this.$Message.info(res.msg)
            }
          })
        }
      }
    },
    callbackfun (e) {
      if (e === 'stop') {
        this.isReplay = false
        this.$emit('onStop')
      }
      if (e == 'pause') {
        this.isReplay = true
        this.onClickPauseVideo()
        if (this.reconnectionStatus == 1) {
          this.$emit('onPause')
        }
      }
      if (e == 'play') {
        if (this.isReplay) {
          this.onClickReplayVideo()
          this.$emit('onPlay')
        }
      }
    },
    // 视频回放
    playbackVideo (item) {
      this.close()
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let params = {
        appId,
        deviceId,
        channel,
        timestamp
      }
      let startTime = item.startTime
      let endTime = item.endTime
      params.startTime = startTime
      params.endTime = endTime
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&endTime=${endTime}&startTime=${startTime}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackOpen`, params).then(res => {
        if (res.code === 200) {
          this.player = new WasmPlayer(null, this.dataId + '-' + this.channelId, this.callbackfun, this.config)
          this.player.play(res.result.flv, this.autoplay ? 1 : 0)
          this.isReplay = false
          this.streamId = res.result.streamId
          if (!item.isNoDown) {
            this.$emit('getPlayVideoMsg', params)
          }
          // 重连成功
          this.reconnectionStatus = 1
          // 重连后 倍速 ！= 1 需要在调一次倍速接口
          if (this.speed != 1) {
            this.onClickFastForward(this.speed)
          }
        } else {
          this.onReconnectionVideo()
          // this.$Message.info(res.msg)
        }
      })
    },
    // 视频回放 拖动进度条控制
    playbackControlVideo (item) {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let seektime = item.seektime
      let params = {
        appId,
        channel,
        deviceId,
        seektime,
        streamId,
        timestamp,
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&seektime=${seektime}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackSeek`, params).then(res => {
        if (res.code === 200) {
          // 重连成功
          this.reconnectionStatus = 1
        } else {
          // this.$Message.info(res.msg)
          this.onReconnectionVideo()
        }
      })
    },
    // 视频回放下载
    downLoadBackVideo (item) {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let params = {
        appId,
        deviceId,
        channel,
        timestamp
      }
      let startTime = item.startTime
      let endTime = item.endTime
      params.startTime = startTime
      params.endTime = endTime
      params.downloadSpeed = 8
      let sign = md5(`appId=${params.appId}&channel=${params.channel}&deviceId=${params.deviceId}&downloadSpeed=${params.downloadSpeed}&endTime=${params.endTime}&startTime=${params.startTime}&timestamp=${params.timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackDownload`, params).then(res => {
        if (res.code === 200) {
          this.doubleSpeedPlayer = new WasmPlayer(null, this.dataId + '-' + this.channelId, this.callbackfun, this.config)
          setTimeout(() => {
            this.doubleSpeedPlayer.play(res.result.flv, this.autoplay ? 1 : 0)
          }, 200);
          this.videoMsg = res.result
          this.$emit("getVideoMsg", res.result, params)
        } else {
          this.$Message.info(res.msg)
        }
      })
    },
    close () {
      if (this.player) {
        this.player.destroy()
        this.player = null
      }
      if (this.doubleSpeedPlayer) {
        this.doubleSpeedPlayer.destroy()
        this.doubleSpeedPlayer = null
      }
    },
    // 下载设施视频
    downFaclVideo () {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/getDownLoadMp4`, params).then(res => {
        if (res.code === 200) {
          // window.location.href(res.result)
        } else {
          this.$emit('downFaclVideoErr', res)
          this.$Message.info(res.msg)
        }
      })
    },
    // 播放设施视频  弃用
    playFaclVideo () {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.videoMsg.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/getDownLoadMp4`, params).then(res => {
        if (res.code === 200) {
          this.videoUrl = res.result
          this.live = false
          this.$emit('onPlayFaclVideo')
        } else {
          this.$emit('downFaclVideoErr', res)
          this.$Message.info(res.msg)
        }
      })
    },
    // 倍速
    onClickFastForward (speed) {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      this.speed = speed
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId,
      }
      params.speed = this.speed
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&speed=${params.speed}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackSpeed`, params).then(res => {
        if (res.code === 200) {
          this.$emit('onFinishFastForward', this.speed)
          this.$Message.info(res.msg)
          // 重连成功
          this.reconnectionStatus = 1
        } else {
          // this.$Message.info(res.msg)
          this.onReconnectionVideo()
        }
      })
    },
    // 回放暂停
    onClickPauseVideo () {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId,
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackPause`, params).then(res => {
        if (res.code === 200) {
          this.$Message.info(res.msg)
        } else {
          this.$Message.info(res.msg)
        }
      })
    },
    // 回放恢复
    onClickReplayVideo () {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId,
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackReplay`, params).then(res => {
        if (res.code === 200) {
          this.$Message.info(res.msg)
          // 重连成功
          this.reconnectionStatus = 1
        } else {
          // this.$Message.info(res.msg)
          this.onReconnectionVideo()
        }
      })
    },
    // 关闭设施回放视频
    onCloseFlacBackVideo (item) {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId,
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/playbackClose`, params).then(res => {
        if (res.code === 200) {
          // this.$Message.info(res.msg)
        } else {
          // this.$Message.info(res.msg)
        }
      })
    },
    // 重连
    onReconnectionVideo () {
      if (this.reconnectionCount < 5) {
        this.reconnectionCount++
        // this.$Message.info('正在重连')
      } else {
        this.$Message.info('操作失败')
        return
      }
      let end = new Date(this.endTime).getTime()
      let start = new Date(end - this.playbackCurrentTime)
      let newStart = formatDate(start) + ' ' + formatTime(start)
      this.playbackVideo({ startTime: newStart, endTime: this.endTime }, 1)
    }
  },
}
</script>

<style lang='scss' scoped>
::v-deep .iconqingxiLOGO {
  pointer-events: none;
  &::before {
    content: " ";
  }
}
// ::v-deep video {
//   object-fit: fill;
// }
</style>