/**
 * 自定义指令 - input输入框正则匹配替换
 */
export default {
  // 正整数
  intLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/^(0+)|[^\d]+/g, '')
        console.log(input.value);
      })
    }
  },
  // 带小数点数字
  floatLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\d.]/g, "").replace(/^\./g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
      })
    }
  },
  // 限制特殊字符
  stringLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\u4E00-\u9FA5A-Za-z0-9_\-\@\$\']/g, '')
      })
    }
  },
  // 数字
  numberLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\d]/g, "")
      })
    }
  },
  // 英文
  charLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\a-\z\A-\Z]/g, "")
      })
    }
  },
  // 英文和数字
  charAndNumberLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\a-\z\A-\Z0-9]/g, "")
      })
    }
  },
  // 账号
  accountLimit: {
    bind(el) {
      const input = el.getElementsByTagName('input')[0]
      input.addEventListener("keyup", () => {
        let val = input.value
        input.value = val.replace(/[^\a-\z\A-\Z0-9._-]/g, "")
      })
    }
  },
}