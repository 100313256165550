import axios from '../http.interceptor'

export default {
  // 获取作业规则列表
  getWorkRuleList: (params) => axios.post('/hss/workrule/list', params),

  // 新增作业规则
  createWorkRule: (params) => axios.post('/hss/workrule/add', params),

  // 删除作业规则
  deleteWorkRuleCar: (params) => axios.post('/hss/carPlan/deleteWorkRule', params),

  // 删除作业规则
  deleteWorkRuleDlbj: (params) => axios.post('/hss/empPlan/deleteWorkRule', params),

  // 修改作业规则
  editWorkRule: (params) => axios.post('/hss/workrule/update', params),

  // 获取作业规则详情
  getWorkRuleDetail: (params) => axios.post('/hss/workrule/detail', params),

  // 获取作业规则中默认规则项
  getWorkRuleItemList: (params) => axios.post('/hss/workruleitem/init', params),

  // 修改规则项
  editWorkRuleItem: (params) => axios.post('/hss/workruleitem/update', params),

  // 获取作业班组全部列表
  getWorkGroupItemAllList: (params) => axios.post('/hss/workgroup/treeSimple', params),

  // 获取作业班组列表
  getWorkGroupList: (params) => axios.post('/hss/workgroup/list', params),

  // 新增作业班组
  createWorkGroup: (params) => axios.post('/hss/workgroup/add', params),

  // 删除作业班组
  deleteWorkGroupCar: (params) => axios.post('/hss/carPlan/deleteWorkGroup', params),

  // 删除作业班组
  deleteWorkGroupDlbj: (params) => axios.post('/hss/empPlan/deleteWorkGroup', params),

  // 修改作业班组
  editWorkGroup: (params) => axios.post('/hss/workgroup/update', params),

  // 获取作业班组详情
  getWorkGroupDetail: (params) => axios.post('/hss/workgroup/detail', params),

  // 冬夏时间 完成度详情
  getTimeCompleteDetail: (params) => axios.post('/hss/jobset/detail', params),

  // 修改 冬夏时间 完成度
  editTimeComplete: (params) => axios.post('/hss/jobset/update', params),


  // 新 新增道路保洁排班计划
  createRoadPlanNew: (params) => axios.post('/hss/empPlan/create', params),

  // 新 修改道路保洁排班计划
  editRoadPlanNew: (params) => axios.post('/hss/empPlan/update', params),

  // 新 获取道路保洁排班计划详情
  getRoadPlanDetailNew: (params) => axios.post('/hss/empPlan/read', params),

  // 新 获取道路保洁排班计划列表
  getRoadPlanListNew: (params) => axios.post('/hss/empPlan/masterList', params),

  // 新 获取道路保洁排班计划详情列表
  getRoadPlanDetailListNew: (params) => axios.post('/hss/empPlan/detailList', params),

  // 新 删除道路保洁排班计划
  deleteRoadPlanNew: (params) => axios.post('/hss/empPlan/deleteMaster', params),

  // 新 删除道路保洁 详情列表 排班
  deleteRoadPlanDayNew: (params) => axios.post('/hss/empPlan/deleteDetail', params),

  // 新 按天新增道路保洁排班
  createRoadPlanDayNew: (params) => axios.post('/hss/empPlan/updateByDay', params),

  // 新增前查重
  repeatPersonPlanName: (params) => axios.post('/hss/empPlan/repeatPlanName', params),


  // 获取道路保洁排班计划列表
  getRoadPlanList: (params) => axios.post('/hss/workplan/workPlanMaster', params),

  // 获取道路保洁排班计划详情
  getRoadPlanDetail: (params) => axios.post('/hss/workplan/read', params),

  // 新增道路保洁排班计划
  createRoadPlan: (params) => axios.post('/hss/workplan/create', params),


  // 修改道路保洁排班计划
  editRoadPlan: (params) => axios.post('/hss/workplan/updateMaster', params),

  // 删除道路保洁排班计划
  deleteRoadPlan: (params) => axios.post('/hss/workplan/delete', params),

  // 获取道路保洁 详情列表
  getRoadPlanDetailList: (params) => axios.post('/hss/workplan/workPlanList', params),

  // 导出道路保洁排班计划列表
  exportRoadPlan: (params) => axios.post('/hss/empPlan/selectEmpPlanForExcel', params),

  // 导出道路保洁模板
  exportRoadPlanTem: (params) => axios.post('/hss/empPlan/selectEmpPlanForExcelTemplate', params),


  // 按天新增道路保洁排班
  createRoadPlanDay: (params) => axios.post('/hss/workplanday/create', params),

  // 按天修改道路保洁排班
  editRoadPlanDay: (params) => axios.post('/hss/workplanday/update', params),

  // 删除道路保洁 详情列表 排班
  deleteRoadPlanDay: (params) => axios.post('/hss/workplanday/delete', params),

  // 获取道路保洁出勤汇总列表
  getRoadPlanAttendanceSummaryList: (params) => axios.post('/hss/empPlan/attendanceCount', params),

  // 获取道路保洁出勤明细列表
  getRoadPlanAttendanceDetailList: (params) => axios.post('/hss/empPlan/attendanceDetail', params),

  // 道路保洁纠正记录弹框
  correctionRecord: (params) => axios.post('/hss/employeeCorrectRecord/insertEmployeeCorrectRecord', params),

  // 获取道路保洁纠正记录列表
  getRoadPlanAttendanceCorrectList: (params) => axios.post('/hss/employeeCorrectRecord/attendanceCorrectRecord', params),

  // 恢复道路保洁纠正记录
  recoveryCorrectionRecord: (params) => axios.post('/hss/employeeCorrectRecord/updateEmployeeCorrectRecord', params),

  // 获取道路保洁违规汇总列表
  getRoadPlanViolateSummaryList: (params) => axios.post('/hss/dlbjviolation/collectList', params),

  // 获取道路保洁违规图表
  getRoadPlanViolateEcharts: (params) => axios.post('/hss/dlbjviolation/histogram', params),

  // 获取道路保洁违规图表
  getRoadPlanStatisticsEcharts: (params) => axios.post('/hss/empPlan/attendanceCountChars', params),

  // 获取机械清扫排班计划列表
  getMechanicalPlanList: (params) => axios.post('/hss/carPlan/mechanicalList', params),

  // 新增机械清扫排班计划
  createMechanicalPlan: (params) => axios.post('/hss/carPlan/create', params),

  // 删除机械清扫排班计划
  deleteCarPlan: (params) => axios.post('/hss/carPlan/delete', params),

  // 修改启用状态
  updateCarPlanIsEnable: (params) => axios.post('/hss/carPlan/updateIsEnable', params),

  // 新增前查重
  repeatCarPlanName: (params) => axios.post('/hss/carPlan/repeatPlanName', params),

  // 修改机械清扫排班计划
  editMechanicalPlan: (params) => axios.post('/hss/carPlan/update', params),

  // 获取机械清扫排班计划详情
  getMechanicalPlanDetail: (params) => axios.post('/hss/carPlan/read', params),

  // 导出机械清扫排班计划列表
  exportMechanicalPlan: (params) => axios.post('/hss/jxqsplan/jxqsPlanExcelExport', params),
  exportCarPlan: (params) => axios.post('/hss/carPlan/carPlanExcelExport', params),

  // 导出机械清扫模板
  exportMechanicalPlanTem: (params) => axios.post('/hss/jxqsplan/jxqsPlanExcelExportTemplate', params),
  exportCarPlanTem: (params) => axios.post('/hss/carPlan/carPlanExcelExportTemplate', params),


  // 获取垃圾清运排班计划列表
  getGarbageCleanPlanList: (params) => axios.post('/hss/carPlan/trashPickupList', params),

  // 解绑垃圾清运作业规则
  unBindGarbageCleanRule: (params) => axios.post('/hss/ljqyworkplan/unbindRule', params),

  // 获取垃圾清运排班计划详情
  getGarbageCleanDetail: (params) => axios.post('/hss/ljqyworkplan/detail', params),

  // 导出垃圾清运排班计划列表
  exportGarbageClean: (params) => axios.post('/hss/ljqyworkplan/ljqyPlanExcelExport', params),

  // 导出垃圾清运模板
  exportGarbageCleanTem: (params) => axios.post('hss/ljqyworkplan/ljqyPlanExcelExportTemplate', params),


  // 获取垃圾转运排班计划列表
  getGarbageTransitPlanList: (params) => axios.post('/hss/carPlan/garbageTransitList', params),
  // 垃圾转运 明细 点击车牌号
  getGarbageTransitCarList: (params) => axios.post('/hss/carPlan/garbageTransitWorkPlanDetailByCar', params),

  // 获取垃圾转运七天里程
  getGarbageTransitSevenMileage: (params) => axios.post('/hss/carPlan/getExpectMileage', params),

  // 解绑垃圾转运作业规则
  unBindGarbageTransitRule: (params) => axios.post('/hss/ljzyworkplan/unbindRule', params),

  // 获取垃圾转运排班计划详情
  getGarbageTransitDetail: (params) => axios.post('/hss/ljzyworkplan/detail', params),

  // 导出垃圾清运排班计划列表
  exportGarbageTransit: (params) => axios.post('/hss/ljzyworkplan/ljzyPlanExcelExport', params),

  // 导出垃圾清运模板
  exportGarbageTransitTem: (params) => axios.post('/hss/ljzyworkplan/ljzyPlanExcelExportTemplate', params),

  // 垃圾转运 车辆作业统计 纠正记录列表
  getGarbageTransitCorrectList: (params) => axios.post('/hss/ljzyrectifyrecord/list', params),

  // 车辆 异常日历 违规日历
  getCarViolateCalendar: (params) => axios.post('/hss/carviolation/countByDay', params),

  // 车辆 异常日历 整体违规情况与违规分布图表
  getCarViolateEcharts: (params) => axios.post('/hss/carviolation/summaryByWglxJson', params),

  // 车辆作业统计汇总列表
  getCarWorkSummaryList: (params) => axios.post('/hss/jxqsworkcardetail/collectList', params),
  getCarWorkJxzySummaryList: (params) => axios.post('/hss/carPlan/mechanicalWorkPlanCount ', params),
  getCarWorkLjqySummaryList: (params) => axios.post('/hss/carPlan/trashPickupWorkPlanCount', params),
  getCarWorkLjzySummaryList: (params) => axios.post('/hss/carPlan/garbageTransitWorkPlanCount', params),

  // 车辆作业统计详情列表
  getCarWorkDetailList: (params) => axios.post('/hss/jxqsworkcardetail/list', params),
  getCarWorkJxzyDetailList: (params) => axios.post('/hss/carPlan/mechanicalWorkPlanDetail', params),
  getCarWorkLjqyDetailList: (params) => axios.post('/hss/carPlan/trashPickupWorkPlanDetail', params),
  getCarWorkLjzyDetailList: (params) => axios.post('/hss/carPlan/garbageTransitWorkPlanDetail', params),

  // 获取机械清扫作业统计 作业趟数详情列表
  getMechanicalPlanDetailTangciList: (params) => axios.post('/hss/carPlan/mechanicalWorkPlanDetailByCarVo', params),
  // 获取垃圾清运作业统计 作业趟数详情列表
  getGarbageCleanDetailTangciList: (params) => axios.post('/hss/carPlan/trashPickupWorkPlanDetailByCar', params),
  // 获取垃圾转运作业统计 中转站
  getCollectStandNumberList: (params) => axios.post('/hss/carPlan/workPlanByCollectStand', params),
  // 获取垃圾转运作业统计 处理站
  getProcessStandList: (params) => axios.post('/hss/carPlan/workPlanByProcessStand', params),

  // 导出车辆作业统计汇总
  exportCarWorkJxzySummaryList: (params) => axios.post('/hss/carPlan/mechanicalWorkPlanCountExport', params),
  exportCarWorkLjqySummaryList: (params) => axios.post('/hss/carPlan/trashPickupWorkPlanCountExport', params),
  exportCarWorkLjzySummaryList: (params) => axios.post('/hss/ljzyworkplan/ljzyPlanCountExcelExport', params),


  // 导出车辆作业详情汇总
  exportCarWorkJxzyDetailList: (params) => axios.post('/hss/carPlan/mechanicalWorkPlanDetailExport', params),
  exportCarWorkLjqyDetailList: (params) => axios.post('/hss/carPlan/trashPickupWorkPlanDetailExport', params),
  exportCarWorkLjzyDetailList: (params) => axios.post('/hss/ljzyworkplan/ljzyPlanDetailExcelExport', params),

  // 网格与车辆相反
  // 网格作业情况汇总列表
  getGridWorkSummaryList: (params) => axios.post('/hss/carPlanCar/carPlanWorkCount', params),
  // 机械作业 车辆作业统计 点击车牌号
  getMechanicalCleanCarList: (params) => axios.post('/hss/carPlanCar/carPlanWorkDetailByCar', params),

  // 网格作业情况详情列表
  getGridWorkDetailList: (params) => axios.post('/hss/carPlanCar/carPlanWorkDetail', params),

  // 违规统计汇总列表
  getViolationSummaryList: (params) => axios.post('/hss/carviolation/collectList', params),

  // 违规统计图表
  getViolationEcharts: (params) => axios.post('/hss/carviolation/histogram', params),
  // 车辆作业列表
  getCarWorkLogList: (params) => axios.post('/hss/worklog/selectCarWorkLogList', params),
  // 人员作业列表
  getStaffWorkLogList: (params) => axios.post('/hss/worklog/selectPersonWorkLogList', params),
  // 园林作业列表
  getGardenWorkLogList: (params) => axios.post('/hss/worklog/selectGarWorkLogList', params),
  // 古树作业列表
  getTreeWorkLogList: (params) => axios.post('/hss/worklog/selectTreeWorkLogList', params),
  // 设施作业列表
  getFacilitiesWorkLogList: (params) => axios.post('/hss/worklog/selectFacWorkLogList', params),
  // 作业管理-道路保洁作业统计
  getGridJobSummaryList: (params) => axios.post('/hss/empPlan/selectSummaryTable', params),
  // 作业管理-道路保洁作业明细
  getGridJobDetailList: (params) => axios.post('/hss/empPlan/selectSummaryDetailTable', params),
  // 车辆作业日志导出
  exportCarWorkLogList: (params) => axios.post('/hss/worklog/carWorkLogExcelExport', params),
  // 人员作业日志导出
  exportPersonWorkLogList: (params) => axios.post('/hss/worklog/empWorkLogExcelExport', params),
  // 园林作业日志导出
  exportGardenWorkLogList: (params) => axios.post('/hss/worklog/garWorkLogExcelExport', params),
  // 古树作业日志导出
  exportTreeWorkLogList: (params) => axios.post('/hss/worklog/treeWorkLogExcelExport', params),
  // 设施作业日志导出
  exportFacilitiesWorkLogList: (params) => axios.post('/hss/worklog/facWorkLogExcelExport', params),
}