import axios from '../http.interceptor'

export default {
  // 获取车辆树列表
  getCarTreeList: (params) => axios.post('/hss/tlcar/tree', params),

  // 获取车辆详细机构树列表
  getCarDetailTreeList: (params) => axios.post('/hss/tlcar/cartree', params),

  // 获取作业大类全部列表
  getWorkBigClassAllList: (params) => axios.post('/hss/tloperation/getSelectList', params),

  // 获取车辆类型全部列表
  getCarTypeAllList: (params) => axios.post('/hss/tlcartype/getSelectList', params),

  // 获取车辆类型启用全部列表
  getCarTypeEnableAllList: (params) => axios.post('/hss/tlcartype/getSelectList', params),

  // 获取车辆型号全部列表
  getCarModelAllList: (params) => axios.post('/hss/tlcarmodel/getSelectList', params),

  // 获取厂商全部列表
  getCarManufacturerAllList: (params) => axios.post('/hss/tlcarmanufacturer/getSelectList', params),

  // 获取车辆列表
  getCarList: (params) => axios.post('/hss/tlcar/getList', params),

  // 新增车辆
  createCar: (params) => axios.post('/hss/tlcar/insert', params),

  // 修改车辆
  editCar: (params) => axios.post('/hss/tlcar/update', params),

  // 批量修改车辆
  editBatchCar: (params) => axios.post('/hss/tlcar/updatebyids', params),

  // 获取车辆详情
  getCarDetail: (params) => axios.post('/hss/tlcar/getDetail', params),

  // 删除车辆暂时弃用
  deleteCar: (params) => axios.post('/hss/tlcar/deletebyid', params),

  // 批量删除
  deleteBatchCar: (params) => axios.post('/hss/tlcar/deletebatchbyid', params),

  // 导出车辆列表
  exportCar: (params) => axios.post('hss/tlcar/excelExport', params),

  // 导出车辆模板
  exportCarTem: (params) => axios.post('hss/tlcar/excelExportTemplate', params),

  // 获取车辆型号列表
  getCarModelNumList: (params) => axios.post('/hss/tlcarmodel/getList', params),

  // 获取车辆型号详情
  getCarModelNumDetail: (params) => axios.post('/hss/tlcarmodel/getDetail', params),

  // 新增车辆型号
  createCarModelNum: (params) => axios.post('/hss/tlcarmodel/insert', params),

  // 删除车辆型号
  deleteCarModelNum: (params) => axios.post('/hss/tlcarmodel/deletebyid', params),

  // 修改车辆型号
  editCarModelNum: (params) => axios.post('/hss/tlcarmodel/update', params),

  // 获取车辆类型列表
  getCarTypeList: (params) => axios.post('/hss/tlcartype/getList', params),

  // 新增车辆类型
  createCarType: (params) => axios.post('/hss/tlcartype/insert', params),

  // 获取车辆类型详情
  getCarTypeDetail: (params) => axios.post('/hss/tlcartype/getDetail', params),

  // 删除车辆类型
  deleteCarType: (params) => axios.post('/hss/tlcartype/deletebyid', params),

  // 修改车辆型号
  editCarType: (params) => axios.post('/hss/tlcartype/update', params),

  // 获取厂商列表
  getCarManufacturerList: (params) => axios.post('/hss/tlcarmanufacturer/getList', params),

  // 新增厂商
  createCarManufacturer: (params) => axios.post('/hss/tlcarmanufacturer/insert', params),

  // 删除厂商
  deleteCarManufacturer: (params) => axios.post('/hss/tlcarmanufacturer/deletebyid', params),

  // 修改厂商
  editCarManufacturer: (params) => axios.post('/hss/tlcarmanufacturer/update', params),

  // 获取厂商列表详情
  getCarManufacturerDetail: (params) => axios.post('/hss/tlcarmanufacturer/getDetail', params),

  // 获取作业大类分页列表
  getWorkBigTypeList: (params) => axios.post('/hss/tloperation/getList', params),

  // 新增作业大类
  createWorkBigType: (params) => axios.post('/hss/tloperation/insert', params),

  // 删除作业大类
  deleteWorkBigType: (params) => axios.post('/hss/tloperation/deletebyid', params),

  // 修改作业大类
  editWorkBigType: (params) => axios.post('/hss/tloperation/update', params),

  // 获取作业大类详情
  getWorkBigTypeDetail: (params) => axios.post('/hss/tloperation/getDetail', params),
}