<template>
  <Modal v-model="visible"
         @on-visible-change="onChangeVisible"
         :closable="false"
         footer-hide
         :z-index="900"
         :width="width">
    <div :class="{'video-box2':data.details.length==2,'video-box4':data.details.length==1||data.details.length>2&&data.details.length<5,'video-box12':data.details.length>8,'video-box6':data.details.length>4&&data.details.length<7,'video-box8':data.details.length>6&&data.details.length<9}"
         v-if="data">
      <div class="video-top">
        <div class="video-top-left">
          视频监控
        </div>
        <img src="../../assets/new/new_close.png"
             class="cursor-pointer"
             @click.stop="onClickClose">
      </div>
      <div class="video-cont">
        <div class="video-cont-item"
             v-for="item in data.details"
             :key="item.channelid"
             :class="{'video-last':2 * (Math.ceil(data.details.length / 2))}"
             :style="{width:data.details.length==1 ? '768px' : '320px',height:data.details.length==1 ? '400px' : '200px'}">
          <SocketVideoPlayer :dataId="data.equipcode"
                             :channelId="item.channelid"
                             style="width:100%;height:100%"
                             ref="socketVideo"
                             v-if="data.eqptype==='车载监控'">
          </SocketVideoPlayer>
          <HttpVideoPlayer :dataId="data.equipcode"
                           :channelId="item.channelid"
                           style="width:100%;height:100%"
                           v-if="data.eqptype==='视频设备'"></HttpVideoPlayer>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import bus from '@/utils/bus'
import SocketVideoPlayer from '@/components/common/SocketVideoPlayer';
import HttpVideoPlayer from '@/components/common/HttpVideoPlayer';
import { closeFlacDemandVideo } from '@/utils/videoFacl'
export default {
  components: {
    SocketVideoPlayer,
    HttpVideoPlayer
  },
  data () {
    return {
      visible: false,
      data: null,
      width: null
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.removeEventListener('resize', this.resetVideoSize)
    bus.$off('showPlayVideoModal')
  },
  methods: {
    init () {
      bus.$on('showPlayVideoModal', data => {
        if (this.$store.state.statusType) {
			data.details = data.details.filter(item=>{
				return item.status==1
			})
			// console.log(data)
          this.data = data
          if (this.data.details.length == 1) {
            this.width = 2 * 380 + 10 + 16 * 2
          } else if (this.data.details.length == 2) {
            this.width = 857
          } else if (this.data.details.length > 8 || this.data.details.length > 6 && this.data.details.length < 9) {
            this.width = 1543
          } else if (this.data.details.length > 4 && this.data.details.length < 7) {
            this.width = 1203
          } else {
            // 视频宽度 + 右边距 + padding left right
            // Math.ceil向上取整
            if (Math.ceil(this.data.details.length / 2) > 4) {
              this.width = 4 * 380 + 3 * 10 + 16 * 2
            } else {
              this.width = Math.ceil(this.data.details.length / 2) * 380 + (Math.ceil(this.data
                .details.length / 2) - 1) * 10 + 16 * 2
            }
          }
          this.visible = true
        }
      })
    },
    // 清除全屏
    resetVideoSize () {
      window.addEventListener('resize', () => {
        // 判断全屏
        let isFullscreen = document.fullScreen || document.mozFullScreen || document
          .webkitIsFullScreen;
        if (!isFullscreen) {
          if (this.$refs.socketVideo) {
            // 2021.9.7目前还不好用 加100延时测试(可能因为异步)
            setTimeout(() => {
              this.$refs.socketVideo.forEach(item => {
                // 跟样式一样
                if (data.details.length == 1) {
                  item.resize(768, 400)
                } else {
                  item.resize(380, 200)
                }
              })
            }, 500);
          }
        }
      })
    },
    onChangeVisible (boo) {
      console.log(boo);
      if(!boo) {
        this.data.details.forEach(item => {
          if (this.data.eqptype == '视频设备') {
            item.equipcode = this.data.equipcode
            closeFlacDemandVideo(item)
          }
        })
        this.data = null
      }
    },
    onClickClose () {
      this.visible = false
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/modal";

.video-box2 {
  width: 867px;
  height: 443px;
  background: url(../../assets/new/new_video_2.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 55px 90px 0;

  .video-top {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-top-left {
      height: 72px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
  .video-cont {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
  }
  .video-last {
    margin-right: 0;
    /* background-color: #FFFFFF; */
  }
}

.video-box4 {
  width: 857px;
  height: 663px;
  background: url(../../assets/new/new_video_4.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 55px 90px 0;

  .video-top {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-top-left {
      height: 72px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
  .video-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-around; */
  }
  .video-last {
    margin: 28px 9px 0;
    /* background-color: #FFFFFF; */
  }
}

.video-box6 {
  width: 1203px;
  height: 663px;
  background: url(../../assets/new/new_video_6.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 55px 90px 0;

  .video-top {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-top-left {
      height: 72px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
  .video-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-around; */
  }
  .video-last {
    margin: 28px 9px 0;
    /* background-color: #FFFFFF; */
  }
}

.video-box8 {
  width: 1543px;
  height: 663px;
  background: url(../../assets/new/new_video_8.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 55px 90px 0;

  .video-top {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-top-left {
      height: 72px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
  .video-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-around; */
  }
  .video-last {
    margin: 28px 9px 0;
    /* background-color: #FFFFFF; */
  }
}

.video-box12 {
  width: 1543px;
  height: 884px;
  background: url(../../assets/new/new_video_12.png) 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 55px 90px 0;

  .video-top {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-top-left {
      height: 72px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
  }
  .video-cont {
    height: 690px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-around; */
    overflow: auto;
  }
  .video-last {
    margin: 28px 9px 0;
    /* background-color: #FFFFFF; */
  }
  .video-last:nth-child(4n) {
    margin-right: 0;
  }
}

.video-cont-item {
  overflow: hidden;
}
::v-deep {
  .ivu-modal-content {
    box-shadow: 0 0 5px 0 rgba(37, 187, 150, 0);
    background: rgba(26, 26, 26, 0);
  }

  .ivu-modal-body {
    line-height: 1;
    padding: 0;
  }
  .video-select {
    border: 2px solid #23cefd !important;
  }
  .video-kbps-text {
    color: #23cefd !important;
  }
}
</style>
