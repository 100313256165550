import axios from '../http.interceptor'

export default {
  // 获取设备类型全部列表
  getEquipmentTypeAllList: () => axios.post('/hss/tlequiptype/getSelectList'),

  // 获取设备类型详情
  getEquipmentTypeDetail: (params) => axios.post('/hss/tlequiptype/getDetail', params),

  // 新增设备类型
  createEquipmentType: (params) => axios.post('/hss/tlequiptype/insert', params),

  // 修改设备类型
  editEquipmentType: (params) => axios.post('/hss/tlequiptype/update', params),

  // 删除设备类型
  deleteEquipmentType: (params) => axios.post('/hss/tlequiptype/deletebyid', params),

  // 获取设备所有列表
  getEquipmentAllList: (params) => axios.post('/hss/tlequip/getSelectList', params),

  // 获取设备列表
  getEquipmentList: (params) => axios.post('/hss/tlequip/getList', params),

  // 获取设备详情
  getEquipmentDetail: (params) => axios.post('/hss/tlequip/getDetail', params),

  // 新增设备
  createEquipment: (params) => axios.post('/hss/tlequip/insert', params),

  // 修改设备
  editEquipment: (params) => axios.post('/hss/tlequip/update', params),

  // 批量修改设备
  editBatchEquipment: (params) => axios.post('/hss/tlequip/updatebyids', params),

  // 删除设备
  deleteEquipment: (params) => axios.post('/hss/tlequip/deletebyid', params),

  // 批量删除设备
  deleteBatchEquipment: (params) => axios.post('/hss/tlequip/deletebatchbyid', params),

  // 获取设备型号所有列表
  getEquipmentModelAllList: (params) => axios.post('/hss/tlequipmodel/getSelectList', params),

  // 获取设备型号列表
  getEquipmentModelList: (params) => axios.post('/hss/tlequipmodel/getList', params),

  // 获取设备型号详情
  getEquipmentModelDetail: (params) => axios.post('/hss/tlequipmodel/getDetail', params),

  // 新增设备型号
  createEquipmentModel: (params) => axios.post('/hss/tlequipmodel/insert', params),

  // 修改设备型号
  editEquipmentModel: (params) => axios.post('/hss/tlequipmodel/update', params),

  // 批量修改设备型号
  editBatchEquipmentModel: (params) => axios.post('/hss/tlequipmodel/updatebyids', params),

  // 删除设备型号
  deleteEquipmentModel: (params) => axios.post('/hss/tlequipmodel/deletebyid', params),

  // 批量删除设备型号
  deleteBatchEquipmentModel: (params) => axios.post('/hss/tlequipmodel/deletebatchbyid', params),

  // 导出设备列表
  exportEquipment: (params) => axios.post('hss/tlequip/excelExport', params),

  // 导出设备模板
  exportEquipmentTem: (params) => axios.post('hss/tlequip/excelExportTemplate', params),

  // 获取厂商全部列表
  getEquipmentManufacturerAllList: (params) => axios.post('/hss/tlmanufacturer/getSelectList',params),

  // 获取视频设备所有列表
  getVideoEquipmentAllList: (params) => axios.post('/hss/elementtotal/getVideoMap', params),

  // 获取视频设备列表
  getVideoEquipmentList: (params) => axios.post('/hss/elementtotal/getVideoMapList', params),

  // 获取视频设备通道列表
  getVideoEquipmentChannelList: (params) => axios.post('/hss/tlchannel/getList', params),

  // 获取视频设备通道详情
  getEquipmentVideoChannelDetail: (params) => axios.post('/hss/tlchannel/getDetail', params),

  // 新增视频设备通道
  createEquipmentVideoChannel: (params) => axios.post('/hss/tlchannel/insert', params),

  // 修改视频设备通道
  editEquipmentVideoChannel: (params) => axios.post('/hss/tlchannel/update', params),

  // 删除视频设备通道
  deleteEquipmentVideoChannel: (params) => axios.post('/hss/tlchannel/deletebyid', params),

  // 获取绑定对象树列表
  getBindObjectTreeList: (params) => axios.post('/hss/tlfacilities/getParamTree', params),

  // 获取绑定设备列表
  getBindEquipmentList: (params) => axios.post('/hss/tlbind/getSelectList', params),

  // 设备绑定
  equipmentBind: (params) => axios.post('/hss/tlequipbind/bind', params),

  // 获取厂商列表
  getEquipmentManufacturerList: (params) => axios.post('/hss/tlmanufacturer/getList', params),

  // 新增厂商
  createEquipmentManufacturer: (params) => axios.post('/hss/tlmanufacturer/insert', params),

  // 删除厂商
  deleteEquipmentManufacturer: (params) => axios.post('/hss/tlmanufacturer/deletebyid', params),

  // 修改厂商
  editEquipmentManufacturer: (params) => axios.post('/hss/tlmanufacturer/update', params),

  // 获取厂商列表详情
  getEquipmentManufacturerDetail: (params) => axios.post('/hss/tlmanufacturer/getDetail', params),

}