import vm from '../../main.js'

export default {
  state: {
    userTreeList: [],
    userTreeListVisited: false,
    menuTreeList: [],
    menuTreeListVisited: false,
    userKeyMaps: {
      'uid': 'uid',
      'account': 'account',
      'password': 'password',
      'name': 'name',
      'depid': 'depid',
      'platform': 'platform',
      'phone': 'phone',
      'isDepAdmin': 'isDepAdmin',
      'patrolUid': 'patrolUid',
      'roleids': 'roleids',
      'userphoto': 'userphoto',
      'remark': 'remark'
    },
    roleKeyMaps: {
      'id': 'id',
      'roleName': 'roleName',
      'roleCode': 'roleCode',
      'roleType': 'roleType',
      'platform': 'platform',
      'description': 'description',
      'deptvalues': 'deptvalues',
      'permissionRoleId': 'permissionRoleId', // 数据权限
      'sysRoleMenu': 'sysRoleMenu', // 操作权限
      'createBy': 'createBy',
      'createTime': 'createTime',
      'updatBy': 'updatBy',
      'updateTime': 'updateTime',
      'status': 'status',
      'dataScope': 'dataScope'
    }
  },
  getters: {
    // 获取用户树列表
    getUserTreeList: state => state.userTreeList,
    // 获取菜单树列表
    getMenuTreeList: state => state.menuTreeList
  },
  mutations: {
    // 设置用户树列表
    setUserTreeList(state, arr) {
      state.userTreeList = arr
      state.userTreeListVisited = true
    },
    // 设置菜单树列表
    setMenuTreeList(state, arr) {
      state.menuTreeList = arr
      state.menuTreeListVisited = true
    },
  },
  actions: {
    // 更新用户树列表
    updateUserTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.userTreeListVisited || ignorant) {
        vm.$http.getUserTreeList().then(res => {
          if (res.code === 200) {
            commit('setUserTreeList', res.result)
          }
        })
      }
    },
    // 更新菜单树列表
    updateMenuTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.menuTreeListVisited || ignorant) {
        vm.$http.getMenuTreeList({
          size: 99999
        }).then(res => {
          if (res.code === 200) {
            commit('setMenuTreeList', res.result)
          }
        })
      }
    },
    // 新增/修改用户
    createOrEditUser({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.userKeyMaps)
      if (params.uid) {
        return new Promise((resolve, reject) => {
          vm.$http.editUser(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createUser(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 新增/修改角色
    createOrEditRole({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.roleKeyMaps)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editRole(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createRole(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 修改角色状态
    editRoleStatus({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.roleKeyMaps)
      return new Promise((resolve, reject) => {
        vm.$http.editRoleStatus(params).then(res => {
          vm.$Message.info(res.message)
          if (res.code === 200) {
            resolve()
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取角色详情
    getRoleDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getRoleDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.roleKeyMaps)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}