import axios from '../http.interceptor'

export default {
  // 获取报警列表
  getAlarmList: (params) => axios.post('/hss/tlthreshold/getList', params),

  // 新增报警参数
  createAlarm: (params) => axios.post('/hss/tlthreshold/insert', params),

  // 修改报警参数
  editAlarm: (params) => axios.post('/hss/tlthreshold/update', params),

  // 获取报警参数详情
  getAlarmDetail: (params) => axios.post('/hss/tlthreshold/getDetail', params),
}