<template>
  <div id="app"
       @click="onClick">
    <router-view class="page-content-style" />
    <socket></socket>
    <VideoPlayerModal></VideoPlayerModal>
    <NewVideoPlayerModal></NewVideoPlayerModal>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import bus from '@/utils/bus'
import socket from '@/views/socket';
import VideoPlayerModal from '@/components/common/VideoPlayerModal';
import NewVideoPlayerModal from '@/components/common/NewVideoPlayerModal';

export default {
  components: {
    socket,
    VideoPlayerModal,
    NewVideoPlayerModal
  },
  data () {
    return {
      timer: null,
      lTime: new Date().getTime(), // 最后一次点击的时间
      cTime: new Date().getTime(), //当前时间
    }
  },
  computed: {
    ...mapGetters({
      'loginTimeout': 'getLoginTimeout',
      'loginTimeoutEnable': 'getLoginTimeoutEnable',
    }),
  },
  mounted () {
    this.init()
    window.addEventListener('mousedown', this.onMouseDown)
    window.addEventListener('keyup', this.onMouseDown)
    window.addEventListener('mousewheel', this.onMouseDown)
  },
  methods: {
    init () {
      this.$store.dispatch('getSystemConfigDetail').then(() => {
        if (this.loginTimeout && this.loginTimeoutEnable === 0) {
          this.timer = setInterval(() => {
            this.tTime()
          }, 1000)
        }
      })
    },
    onMouseDown () {
      this.lTime = new Date().getTime()
      bus.$emit('onMouseDownPage', this.lTime)
    },
    onClick () {
      this.lTime = new Date().getTime()
    },
    tTime () {
      this.cTime = new Date().getTime();
      if ((this.cTime - this.lTime) > (this.loginTimeout * 60 * 1000)) {
        //未登录状态
        if (!this.$store.getters.getToken) {
          this.lTime = new Date().getTime()
        } else {
          console.log('自动退出登录')
          this.$store.dispatch('loginOut')
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import "./scss/index.scss";

#app {
  font-family: PingFangSC-Regular, PingFang SC，Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  // background-color: #f0f3fa;
}

body,
html {
  height: 100%;
  overflow: hidden;
}

.page-content-style {
  height: 100%;
  overflow: auto;
}

img {
  -webkit-user-drag: none;
}
</style>
