import axios from '../http.interceptor'

export default {
  // 上传图片
  uploadImg: (params) => axios.post('/hss/file/upload', params),

  // 上传文件
  uploadFile: (params) => axios.post('/hss/file/uploadFile', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    },
  }),

  // 下载文件
  downloadFile: (params) => axios.post('/hss/common/download', params),
}