/** axios封装
 *  请求拦截、相应拦截、错误统一处理
 */
import vm from '../main.js'
// 引入axios
import axios from 'axios'
// get参数拼接
import store from '../store'
import constantconfig from '../config/constantconfig'
import Qs from 'qs' //引入qs
// 环境的切换
axios.defaults.baseURL = constantconfig.baseUrl
// 请求超时时间
// if (constantconfig.isOnLine) {
//   axios.defaults.timeout = 20000
// } else {
//   axios.defaults.timeout = 100000
// }
axios.defaults.timeout = constantconfig.isOnLine ? 20000 : 100000
// post请求头
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.getters.getToken
    token && (config.headers.ticket = token)
    // config.data = Qs.stringify(config.data)   // 更改Content-Type
    return config
  },
  error => {
    return Promise.error(error)
  })
// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      let data = response.data
      let code = data.code
      if (code === 300) { // token失效
        vm.$store.dispatch('loginOut')
        vm.$router.replace('/login')
      }
      return Promise.resolve(data)
    } else {
      return Promise.reject(data)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 300:
          vm.$store.dispatch('loginOut')
          vm.$router.replace('/login')
          break;
          // 404请求不存在
        case 404:
          vm.$Message.info('网络请求不存在')
          break;
          // 其他错误，直接抛出错误提示
        default:
          vm.$Message.info(error.response.data.message)
          break;
      }
      return Promise.reject(error.response)
    } else {
      vm.$Message.info('请求失败')
    }
  }
)

export default axios