import vm from '../../main.js'

export default {
  state: {
    dictBigTypeList: [],
    dictBigTypeVisited: false,
    costDictionariesKeyMap: {
      'id': 'id',
      'menuId': 'menuid',
      'costType': 'costType',
    },
    costDictionariesTypeKeyMap: {
      'id': 'id',
      'dictId': 'dictid',
      'name': 'costname',
      'number': 'code',
      'unit': 'unit', // 单位 暂时无用
      'status': 'status',
      'remark': 'remark'
    },
    // 油费
    carCostOilKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      // 'driverId': 'driverNameid',
      // 'departmentId': 'deptidforcar',
      'oilCard': 'oilcardnum',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'oilNum': 'oilnum',
      'price': 'oilunitprice',
      'mileage': 'clockmileage',
      'servicer': 'servicevendorname',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'oliType': 'repairreason',
      'remark': 'remark'
    },
    // 维修费
    carCostRepairKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'part': 'repairreason',
      'orderNo': 'orderno',
      'mileage': 'clockmileage',
      'servicer': 'servicevendorname',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'remark': 'remark',
      'details': 'dateils',
    },
    // 保养费
    carCostMaintainKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardnum',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'mileageInterval': 'maintainmileageintervalname',
      'timeInterval': 'maintaintimeintervalname',
      'orderNO': 'orderno',
      'mileage': 'clockmileage',
      'servicer': 'serviceVendorName',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'remark': 'remark',
      'part': 'repairreason',
      'details': 'dateils'
    },
    // 年检费
    carCostExamineKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'mileage': 'clockmileage',
      'remark': 'remark',
      'part': 'repairreason',
      'orderNo': 'orderNo',
      // 'details': 'dateils'
    },
    // 保险费
    carCostInsuranceKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      // 'driverId': 'driverId',
      // 'departmentId': 'deptIdForCar',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'startTime': 'insurancestarttime',
      'endTime': 'insuranceendtime',
      'servicer': 'serviceVendorName',
      'orderNo': 'orderno',
      'mileage': 'clockmileage',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'remark': 'remark',
      'part': 'repairreason',
      'details': 'dateils'
    },
    // 违章费
    carCostViolationKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      // 'driverId': 'driverId',
      // 'departmentId': 'deptIdForCar',
      // 'part': 'wzTypeName',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      'orderNo': 'orderno',
      'mileage': 'clockmileage',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'part': 'repairreason',
      'remark': 'remark'
    },
    // 车杂费
    carCostOtherKeyMap: {
      'id': 'id',
      'carNameId': 'carnumid',
      'carName': 'carCardNum',
      // 'driverId': 'driverId',
      // 'departmentId': 'deptIdForCar',
      'type': 'costType',
      'totalPrice': 'totalcost',
      'time': 'costtime',
      // 'orderNo': 'orderNo',
      'mileage': 'clockmileage',
      'address': 'place',
      'payPersonName': 'payusername',
      'picture': 'atta',
      'part': 'repairreason',
      'remark': 'remark'
    },
    personCostWageKeyMap: {
      'id': 'id',
      'nameId': 'empid', //人员id
      'name': 'empName', //人员
      'departmentId': 'empdeptid', //部门id
      'departmentName': 'empdeptName', //部门
      'month': 'month', //月份
      'realityWage': 'totalsalary', //实发工资
      'issueDate': 'paydate', //发放日期
      'checkDays': 'attendays', //考勤天数
      'onDays': 'onguarddays', //在岗天数
      'deductionsDays': 'subMoneyDays', // 扣款天数
      'picture': 'fileurl', //附件url
      'remark': 'remark', //备注
      'basicWage': 'basicSalary', //基础工资
      'postWage': 'postSalary', //岗位工资
      'performance': 'meritSalary', //绩效工资
      'subsidy': 'subsidy', //补贴
      'socialSecurity': 'socialSecurity', //社保
      'reservedFunds': 'providentFund', //公积金
      'personalIncomeTax': 'incomeTax', //个税
      'withhold': 'deduction', //扣税
      'other': 'otherpay', //其他支出
    },
    personCostOtherKeyMap: {
      'id': 'id',
      'nameId': 'empid', //人员id
      'name': 'empName', //人员
      'departmentId': 'empdeptid', //部门id
      'departmentName': 'empdeptName', //部门
      'time': 'paydate', //缴费时间
      'money': 'paycost', //费用
      'type': 'costtype', //费用类型
      'picture': 'fileurl', //附件url
      // 'costpersion': 'costpersion', //缴费人
      'address': 'addr', //地点
      'remark': 'remark' //备注
    },
    operatingCostKeyMap: {
      // costtype有三种，水费、电费、杂费（当为杂费时无需填写使用量和单价）
      'id': 'id',
      'departmentId': 'waterdept', //机构id
      'departmentName': 'waterdeptName',
      'type': 'costtype', //费用类型
      'costtypeitem': 'costtypeitem', //费用类型小类 只有类型为杂费时填写  
      'quantity': 'waterusage', //使用量
      'price': 'unit', //单价
      'totalPrice': 'totalcost', //总费用
      'time': 'paytime', //缴费时间
      'payPersonId': 'payperson', //缴费人
      'payPersonName': 'paypersonName', //缴费人
      'servicer': 'servicevendor', //服务商
      'lngLat': 'addr', //地址坐标
      'address': 'addrdetail', //地址详细
      'picture': 'fileurl', //附件地址
      'remark': 'remark' //备注
    }
  },
  getters: {
    getDictBigTypeList: state => state.dictBigTypeList,
  },
  mutations: {
    setDictBigTypeList(state, arr) {
      state.dictBigTypeList = arr
      state.dictBigTypeListVisited = true
    },
  },
  actions: {
    // 更新车辆树列表
    updateDictBigTypeList({
      commit,
      state
    }, ignorant) {
      if (!state.dictBigTypeListVisited || ignorant) {
        vm.$http.getCostDictionariesList({
          menuid: 0
        }).then(res => {
          if (res.code === 200) {
            commit('setDictBigTypeList', res.result)
          }
        })
      }
    },
    // 新增/修改 字典大类
    createOrEditCostDicType({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.costDictionariesKeyMap)
      }
      console.log(params);
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCostDictionaries(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCostDictionaries(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 新增/修改 字典 部位(列表项)
    createOrEditCostDictItem({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.costDictionariesTypeKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCostDictionariesItem(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCostDictionariesItem(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取 列表项详情
    geCostDictItem({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCostDictionariesItemDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.costDictionariesTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆油费
    createOrEditCarCostOil({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostOilKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostOil(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostOil(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆油费详情
    getCarCostOilDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostOilDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostOilKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆维修费
    createOrEditCarCostRepair({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostRepairKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostRepair(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostRepair(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆维修费详情
    getCarCostRepairDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostRepairDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostRepairKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆保养费
    createOrEditCarCostMaintain({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostMaintainKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostMaintain(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostMaintain(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆保养费详情
    getCarCostMaintainDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostMaintainDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostMaintainKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆年检费
    createOrEditCarCostExamine({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.carCostExamineKeyMap)
      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostExamine(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostExamine(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆年检费详情
    getCarCostExamineDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostExamineDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostExamineKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆保险费
    createOrEditCarCostInsurance({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostInsuranceKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostInsurance(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostInsurance(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆保险费详情
    getCarCostInsuranceDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostInsuranceDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostInsuranceKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆违章费
    createOrEditCarCostViolation({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostViolationKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostViolation(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostViolation(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆违章费详情
    getCarCostViolationDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostViolationDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostViolationKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 车辆杂费
    createOrEditCarCostOther({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.carCostOtherKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editCarCostOther(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createCarCostOther(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取车辆杂费详情
    getCarCostOtherDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getCarCostOtherDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCostOtherKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 人员工资组成
    createOrEditPersonCostWage({
      state
    }, form) {
      // let params = {
      //   data: vm.$keyMapNew(form, state.personCostWageKeyMap)
      // }
      let params = vm.$keyMapNew(form, state.personCostWageKeyMap)
      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editPersonCostWage(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPersonCostWage(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取人员工资组成详情
    getPersonCostWageDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getPersonCostWageDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.personCostWageKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 人员杂费
    createOrEditPersonCostOther({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.personCostOtherKeyMap)
      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editPersonCostOther(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPersonCostOther(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取人员杂费详情
    getPersonCostOtherDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getPersonCostOtherDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.personCostOtherKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 运营成本
    createOrEditOperatingCost({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.operatingCostKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editOperatingCost(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createOperatingCost(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取运营成本详情
    getOperatingCostDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getOperatingCostDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.operatingCostKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}