// 系统配置
import axios from '../http.interceptor'

export default {
  // 获取系统配置列表
  getSystemConfigList: (params) => axios.post('/hss/sysinfo/getList', params),

  // 获取系统配置详情
  getSystemConfigDetail: () => axios.post('/hss/sysinfo/detail'),

  // 修改系统配置
  editSystemConfig: (params) => axios.post('/hss/sysinfo/update', params),

  // 获取操作日志列表
  getLogList: (params) => axios.post('/hss/log/list', params),

  // 获取设备监控列表
  getEquipmentMonitorList: (params) => axios.post('/hss/tlequipmonitor/monitor', params),

  // 刷新设备监控列表
  refreshEquipmentMonitorList: (params) => axios.get('/hss/tlequipmonitor/refresh', params),
}