<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="510"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            原密码：
          </p>
          <Input v-model="oldPwd"
                 type="password"
                 maxlength="30"
                 placeholder="请输入原密码"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            新密码：
          </p>
          <Input v-model="newPwd"
                 type="password"
                 maxlength="30"
                 placeholder="请输入新密码"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            确认新密码：
          </p>
          <Input v-model="newPwdAgain"
                 type="password"
                 maxlength="30"
                 placeholder="请再次确认新密码"></Input>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '修改密码'
    },
    visible: Boolean
  },
  data () {
    return {
      oldPwd: '',
      newPwd: '',
      newPwdAgain: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onClickConfirm () {
      if (!this.oldPwd) return this.$Message.info('请输入原密码');
      if (!this.newPwd) return this.$Message.info('请输入新密码')
      if (!this.newPwdAgain) return this.$Message.info('请再次确认新密码')
      if (this.newPwd !== this.newPwdAgain) return this.$Message.info('两次密码输入不一致')
      let params = {
        pwdOld: this.oldPwd,
        pwdNew: this.newPwd
      }
      this.$http.editPwd(params).then(res => {
        this.$Message.info(res.message)
        if (res.code === 200) {
          this.$store.dispatch('loginOut')
          this.$router.replace('/login')
          this.$emit('onChange', false)
        }
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.oldPwd = ''
      this.newPwd = ''
      this.newPwdAgain = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>