import vm from '../../main.js'

export default {
  state: {
    facilityBigTypeAllList: [],
    facilityBigTypeAllListVisited: false,
    // 树种
    treeVarietiesAllList: [],
    treeVarietiesAllListVisited: false,
    // 科属 树种级联
    familyVarietiesList: [],
    familyVarietiesListVisited: false,
    // 养护资料
    conserveAllList: [],
    conserveAllListVisited: false,
    facilityKeyMap: {
      'ids': 'ids',
      'name': 'faclname',
      'bigType': 'facltypeid',
      'bigTypeName': 'facltypename',
      'smallType': 'facltypeitemid',
      'smallTypeName': 'facltypeitemname',
      'number': 'faclcode',
      'departId': 'facldeptid',
      'departName': 'facldeptname',
      'principalId': 'principal',
      'principal': 'principalname',
      'status': 'faclstatus',
      'maintainId': 'mtadeptid',
      'maintain': 'mtadeptname',
      'radius': 'radii',
      'date': 'startdate',
      'business': 'businessarea',
      'lnglat': 'facladdr',
      'site': 'facladdrdetail',
      'gridId': 'gridId',
      'gridName': 'gridname',
      'remark': 'remark',
      'picture': 'fileurl',
      'bindstatus': 'bindstatus',
      'others': 'details',
      'eqptype': 'eqptype',
      'equipcode': 'equipcode',
      'equipid': 'equipid',
      'channels': 'channels',
      'videoonlinestatus': 'videoonlinestatus',
      // 窨井盖
      'selfInspection': 'insType', // 自检周期
      'selfInspectionCount': 'ins', // 周期内自检次数
    },
    facilityDetailKeyMap: {
      // 设施下的园林数组对象
      'gardenRange': 'mapscope', //园林范围
      'totalArea': 'area', //面积数
      'plannedGreenArea': 'plangreenarea', //计划绿化面积数
      'greenArea': 'greenarea', //绿化面积数
      'totalCount': 'treeallnum', // 总数量
      'material': 'materequip', //物料设备数
      'maintainCar': 'curingcar', //养护车辆数
      'housekeeper': 'cleaner', //保洁人员数
      'maintenanceWorkers': 'curingpeople', //养护人员数
      'healthyTrees': 'healtree', //健康树木数量
      'strongGrowingTree': 'growntree', //生长强树木数量
      'maintenanceCycleGardens': 'curingcycle', //养护周期
      'technical': 'techinfo', //技术资料
      'introductionGardens': 'parkexplain', //介绍说明
      'details': 'details',
      // 设施下的名木古树数组对象
      'family': 'families', //科属
      'scientificName': 'scientificname', //学名
      'treeAge': 'treeage', //树龄
      'treeHeight': 'treeheight', //树高
      'dbh': 'breast', //胸径
      'groundDiameter': 'grounddiameter', //地径
      'leavesDensity': 'leavesdensity', //树叶密度
      'crown': 'treecrownstatus', //树冠状态
      'growthSituation': 'growthsituation', //生长形势
      'curingWay': 'curingway', //养护方式
      'maintenanceCycle': 'curingperiod', //养护周期
      'safeguardProcedures': 'safeguard', //保护措施
      'introduction': 'introduce', //介绍说明
      // 设施下的窨井盖数组对象
      'selfInspection': 'insType', // 自检周期 0周 1月 2年
      'selfInspectionCount': 'ins', // 周期内自检次数
      'remindRadio': 'remindType', // 提醒类型
      'remindday': 'remindDay', // 提醒日
      'remindtime': 'remindTime', // 提醒时间 mm-dd-hh-mm  提醒类型为年
      'remindtimeWeekOrMonth': 'remindTimeWeek', // 提醒时间 hh-mm 提醒类型为周 月
    },
    facilityDetailItemKeyMap: {
      // 设施下的园林数组对象下的树种数组
      'name': 'maintree', //主要树种
      'id': 'treeSeedId', // 树种id
      'count': 'treenum', //数量
      'age': 'treeage', //树龄
      'spec': 'specs', //规格
      // 设施下的窨井盖数组对象下的提醒周期数组
      'remindRadio': 'remindType', // 提醒类型
      'remindday': 'remindDay', // 提醒日
      'remindtime': 'remindTime', // 提醒时间 mm-dd-hh-mm  提醒类型为年
      'remindtimeWeekOrMonth': 'remindTimeWeek', // 提醒时间 hh-mm 提醒类型为周 月
    },
    facilitySmallTypeKeyMap: {
      'id': 'id',
      'name': 'itemtypename',
      'bigType': 'facilitietypeid',
      'icon': 'icon',
      'remark': 'remark',
    },
    // 树种
    treeVarietiesKeyMap: {
      'id': 'id',
      'name': 'treespecname', //树种名称
      'family': 'families', //科属
      'growthType': 'growntype', //生长类型
      'introduction': 'introduce', //介绍说明
      'maintenanceAdvice': 'maintenanceadvice', //养护建议
      'status': 'status', //状态 0 禁用 1启用
      'remark': 'remark', //备注
      'identifying': 'iconid' //标识
    },
    // 养护知识
    conserveKeyMap: {
      "id": "id", //id
      "name": "title", //标题
      "maintain": "knowledgetype", //知识类别
      "sphereApplication": "scope", //使用范围
      "abstract": "curabstract", //摘要
      "cont": "content"
    }
  },
  getters: {
    // 获取设施大类所有列表
    getFacilityBigTypeAllList: state => state.facilityBigTypeAllList,
    // 获取树种所有列表
    getTreeVarietiesAllList: state => state.treeVarietiesAllList,
    // 获取科属 树种级联
    getFamilyVarietiesList: state => state.familyVarietiesList,
    // 获取养护资料所有列表
    getConserveAllList: state => state.conserveAllList,
  },
  mutations: {
    // 设置设施大类所有列表
    setFacilityBigTypeAllList(state, arr) {
      state.facilityBigTypeAllList = arr
      state.facilityBigTypeAllListVisited = true
    },
    // 设置树种所有列表
    setTreeVarietiesAllList(state, arr) {
      state.treeVarietiesAllList = arr
      state.treeVarietiesAllListVisited = true
    },
    // 设置科属 树种级联
    setFamilyVarietiesList(state, arr) {
      state.familyVarietiesList = arr
      state.familyVarietiesListVisited = true
    },
    // 设置养护资料所有列表
    setConserveAllList(state, arr) {
      state.conserveAllList = arr
      state.conserveAllListVisited = true
    },
  },
  actions: {
    // 更新设施大类所有列表
    updateFacilityBigTypeAllList({
      commit,
      state
    }, ignorant) {
      if (!state.facilityBigTypeAllListVisited || ignorant) {
        vm.$http.getFacilityBigTypeAllList({
          type: ''
        }).then(res => {
          if (res.code === 200) {
            commit('setFacilityBigTypeAllList', res.result)
          }
        })
      }
    },
    // 更新树种所有列表
    updateTreeVarietiesAllList({
      commit,
      state
    }, ignorant) {
      if (!state.treeVarietiesAllListVisited || ignorant) {
        vm.$http.getTreeVarietiesAllList().then(res => {
          if (res.code === 200) {
            commit('setTreeVarietiesAllList', res.result)
          }
        })
      }
    },
    // 更新科属 树种级联
    updateFamilyVarietiesList({
      commit,
      state
    }, ignorant) {
      if (!state.familyVarietiesListVisited || ignorant) {
        vm.$http.getFamilyVarietiesList().then(res => {
          if (res.code === 200) {
            commit('setFamilyVarietiesList', res.result)
          }
        })
      }
    },
    // 更新养护资料所有列表
    updateConserveAllList({
      commit,
      state
    }, ignorant) {
      if (!state.conserveAllListVisited || ignorant) {
        vm.$http.getConserveAllList().then(res => {
          if (res.code === 200) {
            commit('setConserveAllList', res.result)
          }
        })
      }
    },
    // 新增/修改设施
    createOrEditFacility({
      state
    }, form) {
      if (form.others && form.others[0] && form.others[0].details) {
        form.others[0].details = form.others[0].details.map(item => vm.$keyMapNew(item, state.facilityDetailItemKeyMap))
      }
      if (form.others) {
        form.others = form.others.map(item => vm.$keyMapNew(item, state.facilityDetailKeyMap))
      }
      let params = vm.$keyMapNew(form, state.facilityKeyMap)
      if (params.ids) {
        return new Promise((resolve, reject) => {
          vm.$http.editBatchFacility(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createFacility(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设施详情
    getFacilityDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getFacilityDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.facilityKeyMap)
            let detailKeyMap = vm.$swapKeyValue(state.facilityDetailKeyMap)
            let detailItemKeyMap = vm.$swapKeyValue(state.facilityDetailItemKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            if (newData.others) {
              newData.others = newData.others.map(item => vm.$keyMapNew(item, detailKeyMap))
            }
            if (newData.others[0] && newData.others[0].details) {
              newData.others[0].details = newData.others[0].details.map(item => vm.$keyMapNew(item, detailItemKeyMap))
            }
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改设施小类
    createOrEditFacilitySmallType({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.facilitySmallTypeKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editFacilitySmallType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createFacilitySmallType(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取设施详情
    getFacilitySmallTypeDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getFacilitySmallTypeDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.facilitySmallTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改树种
    createOrEditTreeVarieties({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.treeVarietiesKeyMap)
      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editTreeVarieties(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createTreeVarieties(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取树种详情
    getTreeVarietiesDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getTreeVarietiesDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.treeVarietiesKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改养护知识
    createOrEditConserve({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.conserveKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editConserve(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createConserve(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取养护知识详情
    getConserveDetail({
      commit,
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getConserveDetail({
          id
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.conserveKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}