import vm from '../../main.js'

export default {
  state: {
    roadAttributeAllList: [],
    roadAttributeAllListVisited: false,
    gridTreeList: [],
    gridTreeListVisited: false,
    gridTreeEnableList: [],
    gridTreeListEnableVisited: false,
    enclosureTreeList: [],
    enclosureTreeListVisited: false,
    roadAttributeKeyMap: {
      'id': 'levelId',
      'name': 'name',
      'status': 'isEnable',
      'remark': 'remark',
    },
    gridKeyMap: {
      'id': 'gridId',
      'name': 'name',
      'departId': 'depid',
      'depart': 'depName',
      'type': 'type',
      'drawType': 'areaType',
      'lng': 'lng',
      'lat': 'lat',
      'radius': 'radius',
      'points': 'lnglat',
      'gradeId': 'levelid',
      'grade': 'levelName',
      'superiorId': 'parentGridIds',
      'superior': 'parentGridNames',
      'mileage': 'km',
      'area': 'area',
      'lineWidth': 'lineWidth',
      'sectionId': 'sectionIds',
      'section': 'sectionNames',
      'status': 'isEnable',
      'guideboard': 'isGuideboard',
      'bindObj': 'bindObj',

      'pointsNew': 'latLngEntityList',
      'rectangularEntity': 'rectangularEntity', // 矩形

      // 电子围栏
      'gridType': 'gridType', // 0网格 1电子围栏 3园林档案的区域
      'advance': 'punchCard',
      'late': 'laterIn',
      'leaveEarly': 'leaveEarly',
      'absenteeism': 'noWork',
      'startTime': 'startTime',
      'endTime': 'endTime',
      'itemId': 'itemId',
      'carAndEmpType': 'carAndEmpType',
      'gridCheckworkList': 'gridCheckworkList',
      'gridCheckworkVoList': 'gridCheckworkVoList',
    },
    attendanceSetMap: {
      // 'id': 'levelId',
      // 'name': 'name',
      // 'status': 'isEnable',
      // 'remark': 'remark',
      'checkWorkName': 'checkWorkName',
      'startTime': 'startTime',
      'endTime': 'endTime',
      'sumStartTime': 'sumStartTime',
      'sumEndTime': 'sumEndTime',
      'winStartTime': 'winStartTime',
      'winEndTime': 'winEndTime',
      'reqType': 'reqType'

    },
  },
  getters: {
    // 获取道路属性所有列表
    getRoadAttributeAllList: state => state.roadAttributeAllList,
    // 获取网格树列表
    getGridTreeList: state => state.gridTreeList,
    // 获取围栏树列表
    getEnclosureTreeList: state => state.enclosureTreeList,
    // 获取网格树启用列表
    getGridTreeEnableList: state => state.gridTreeEnableList,
  },
  mutations: {
    // 设置道路属性所有列表
    setRoadAttributeAllList(state, arr) {
      state.roadAttributeAllList = arr
      state.roadAttributeAllListVisited = true
    },
    // 设置网格树列表
    setGridTreeList(state, arr) {
      state.gridTreeList = arr
      state.gridTreeListVisited = true
    },
    // 设置网格树启用列表
    setGridTreeEnableList(state, arr) {
      state.gridTreeEnableList = arr
      state.gridTreeEnableListVisited = true
    },
    // 设置围栏树列表
    setEnclosureTreeList(state, arr) {
      state.enclosureTreeList = arr
      state.enclosureTreeListVisited = true
    },
  },
  actions: {
    // 更新道路属性所有列表
    updateRoadAttributeAllList({
      commit,
      state
    }, ignorant) {
      if (!state.roadAttributeAllListVisited || ignorant) {
        vm.$http.getRoadAttributeAllList().then(res => {
          if (res.code === 200) {
            commit('setRoadAttributeAllList', res.result)
          }
        })
      }
    },
    // 更新网格树列表
    updateGridTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.gridTreeListVisited || ignorant) {
        vm.$http.getGridTreeList({
          gridType: 0
        }).then(res => {
          if (res.code === 200) {
            commit('setGridTreeList', res.result)
          }
        })
      }
    },
    // 更新网格树列表
    updateGridTreeEnableList({
      commit,
      state
    }, ignorant) {
      if (!state.gridTreeEnableListVisited || ignorant) {
        vm.$http.getGridTreeList({
          gridType: 0,
          isEnable: 1
        }).then(res => {
          if (res.code === 200) {
            commit('setGridTreeEnableList', res.result)
          }
        })
      }
    },
    // 更新围栏树列表
    updateEnclosureTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.enclosureTreeListVisited || ignorant) {
        vm.$http.getGridTreeList({
          gridType: 1
        }).then(res => {
          if (res.code === 200) {
            commit('setEnclosureTreeList', res.result)
          }
        })
      }
    },
    // 新增/修改道路属性
    createOrEditRoadAttribute({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.roadAttributeKeyMap)
      if (params.levelId) {
        return new Promise((resolve, reject) => {
          vm.$http.editRoadAttribute(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createRoadAttribute(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取道路属性详情
    getRoadAttributeDetail({
      commit,
      state
    }, levelId) {
      return new Promise((resolve, reject) => {
        vm.$http.getRoadAttributeDetail({
          levelId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.roadAttributeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改网格
    createOrEditGrid({
      state
    }, form) {
      console.log(form);
      if (form.gridCheckworkList) {
        form.gridCheckworkList = form.gridCheckworkList.map(item => vm.$keyMapNew(item, state.attendanceSetMap))
      }
      if (form.gridCheckworkVoList) {
        form.gridCheckworkVoList = form.gridCheckworkVoList.map(item => vm.$keyMapNew(item, state.attendanceSetMap))
      }
      let params = vm.$keyMapNew(form, state.gridKeyMap)
      if (params.gridId) {
        return new Promise((resolve, reject) => {
          vm.$http.editGrid(params).then(res => {
            // vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve(res)
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createGrid(params).then(res => {
            if (res.code === 200) {
              resolve(res)
            } else {
              vm.$Message.info(res.message)
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取网格详情
    getGridDetail({
      commit,
      state
    }, data) {
      return new Promise((resolve, reject) => {
        vm.$http.getGridDetail({
          gridId: data.gridId,
          gridType: data.gridType
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.gridKeyMap)
            let detailKeyMap = vm.$swapKeyValue(state.attendanceSetMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            if (newData.gridCheckworkList) {
              newData.gridCheckworkList = newData.gridCheckworkList.map(item => vm.$keyMapNew(item, detailKeyMap))
            }
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}