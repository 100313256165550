export const hourMillisecond = 1000 * 60 * 60

export const dayMillisecond = 1000 * 60 * 60 * 24

/**
 * 日期格式化
 * @param {Date} date 日期
 * @return {String} 日期yyyy-MM-dd
 */
export const formatDate = (date) => {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  return (year + '-' + month + '-' + day)
}

/**
 * 时间格式化
 * @param {Date} date 日期
 * @return {String} 时间HH:mm:ss
 */
export const formatTime = (date) => {
  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()

  if (h < 10) {
    h = '0' + h
  }
  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }
  return (h + ':' + m + ':' + s)
}

/**
 * 秒转化为时间
 * @param {Number} second 秒数
 * @return {String} 时间H小时m分钟s秒
 */
export const formatSecond = (second) => {
  let result = parseInt(second)
  let h = Math.floor(result / 3600)
  let m = Math.floor((result / 60 % 60))
  let s = Math.floor((result % 60))

  let res = '';
  if (h) res += `${h}小时`;
  if (m) res += `${m}分钟`;
  res += `${s}秒`;
  return res;
}

/**
 * 获取本年日期到今天
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getCurrentYearToNow = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()
  let currentday = currentDate.getDate()
  let startDate = new Date(currentYear, 0, 1)
  let endDate = new Date(currentYear, currentMonth, currentday)
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取本年日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getCurrentYear = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let startDate = new Date(currentYear, 0, 1)
  let endDate = new Date(currentYear, 11, 31)
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取去年日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getLastYear = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  currentYear--
  let startDate = new Date(currentYear, 0, 1)
  let endDate = new Date(currentYear, 11, 31)
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取本月日期 最后一日到今天
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getCurrentMonth = (includingToday = true) => {
  let dateArr = []
  let millisecond = 1000 * 60 * 60 * 24
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()
  let currentday = currentDate.getDate()
  let startDate = new Date(currentYear, currentMonth, 1)
  let endDate
  if (includingToday) {
    endDate = new Date(currentYear, currentMonth, currentday)
  } else {
    endDate = new Date(currentDate.getTime() - (millisecond * 1))
    if (startDate.getTime() > endDate.getTime()) {
      endDate = new Date(currentYear, currentMonth, currentday)
    }
  }
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取本月日期 最后一日到月底
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getCurrentMonthAll = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()
  let startDate = new Date(currentYear, currentMonth, 1)
  let endDate = new Date(currentYear, currentMonth, getMonthDays(currentYear, currentMonth))
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取上月日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getLastMonth = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()
  if (currentMonth == 0) {
    currentMonth = 11
    currentYear--
  } else {
    currentMonth--
  }
  let startDate = new Date(currentYear, currentMonth, 1)
  let endDate = new Date(currentYear, currentMonth, getMonthDays(currentYear, currentMonth))
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取下月日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getNextMonth = () => {
  let dateArr = []
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear()
  let currentMonth = currentDate.getMonth()
  if (currentMonth == 11) {
    currentMonth = 0
    currentYear++
  } else {
    currentMonth++
  }
  let startDate = new Date(currentYear, currentMonth, 1)
  let endDate = new Date(currentYear, currentMonth, getMonthDays(currentYear, currentMonth))
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

// 获取近几个月的日期
// isStartFromScratch false 本月 上月 上上月
// isStartFromScratch true  往上推90天
export const getPreMonthDay = (monthNum, isStartFromScratch = false) => {
  let dateArr = []
  let monthNum2
  if (isStartFromScratch) {
    monthNum2 = monthNum - 1
  } else {
    monthNum2 = monthNum - 2
  }
  let currentDate = new Date()
  let currentYear = currentDate.getFullYear(); //获取当前日期的年份
  let currentMonth = currentDate.getMonth(); //获取当前日期的月份
  let currentday = currentDate.getDate(); //获取当前日期的日
  let endDate = new Date(currentYear, currentMonth, currentday)
  let days = new Date(currentYear, currentMonth, 0).getDate() //获取当前日期中月的天数
  let year2 = currentYear;
  let month2 = parseInt(currentMonth) - monthNum2
  if (month2 <= 0) {
    year2 = parseInt(year2) - parseInt(month2 / 12 == 0 ? 1 : parseInt(month2) / 12);
    month2 = 12 - (Math.abs(month2) % 12);
  }
  let days2 = new Date(year2, month2, 0).getDate();
  if (currentday > days2) {
    currentday = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  let startDate
  if (isStartFromScratch) {
    startDate = year2 + '-' + month2 + '-' + currentday;
  } else {
    startDate = year2 + '-' + month2 + '-01'
  }
  dateArr.push(startDate)
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取本周日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getCurrentWeek = () => {
  let dateArr = []
  let currentDate = new Date()
  let week = currentDate.getDay()
  let millisecond = 1000 * 60 * 60 * 24
  let minusDay = week != 0 ? week - 1 : 0
  let startDate = new Date(currentDate.getTime() - (minusDay * millisecond))
  let endDate = currentDate
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取上周日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getLastWeek = () => {
  let dateArr = []
  let currentDate = new Date()
  let week = currentDate.getDay()
  let millisecond = 1000 * 60 * 60 * 24
  let minusDay = week != 0 ? week - 1 : 0
  let currentWeekDayOne = new Date(currentDate.getTime() - (millisecond * minusDay))
  let startDate = new Date(currentWeekDayOne.getTime() - (millisecond * 7))
  let endDate = new Date(currentWeekDayOne.getTime() - millisecond)
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取今天日期
 * @return {String} 日期yyyy-MM-dd
 */
export const getToday = () => {
  let currentDate = new Date()
  return formatDate(currentDate)
}

/**
 * 获取昨天日期
 * @return {String} 日期yyyy-MM-dd
 */
export const getYesterday = () => {
  let currentDate = new Date()
  let millisecond = 1000 * 60 * 60 * 24
  let date = new Date(currentDate.getTime() - millisecond)
  return formatDate(date)
}

/**
 * 获取明天日期
 * @return {String} 日期yyyy-MM-dd
 */
export const getTomorrow = () => {
  let currentDate = new Date()
  let millisecond = 1000 * 60 * 60 * 24
  let date = new Date(currentDate.getTime() + millisecond)
  return formatDate(date)
}

/**
 * 获取近三天日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getNearlyThreeDay = (includingToday = true) => {
  let dateArr = []
  let currentDate = new Date()
  let millisecond = 1000 * 60 * 60 * 24
  let startDate
  let endDate
  if (includingToday) {
    startDate = new Date(currentDate.getTime() - (millisecond * 2))
    endDate = currentDate
  } else {
    startDate = new Date(currentDate.getTime() - (millisecond * 3))
    endDate = new Date(currentDate.getTime() - (millisecond * 1))
  }
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取近七天日期
 * @return {Array<String>} [开始日期,结束日期]yyyy-MM-dd
 */
export const getNearlySevenDay = (includingToday = true) => {
  let dateArr = []
  let currentDate = new Date()
  let millisecond = 1000 * 60 * 60 * 24
  let startDate
  let endDate
  if (includingToday) {
    startDate = new Date(currentDate.getTime() - (millisecond * 6))
    endDate = currentDate
  } else {
    startDate = new Date(currentDate.getTime() - (millisecond * 7))
    endDate = new Date(currentDate.getTime() - (millisecond * 1))
  }
  dateArr.push(formatDate(startDate))
  dateArr.push(formatDate(endDate))
  return dateArr
}

/**
 * 获取传入日期的明天
 * @return {String} 日期yyyy-MM-dd
 */
export const getDateTomorrow = (oldDate) => {
  let currentDate = new Date(oldDate)
  let millisecond = 1000 * 60 * 60 * 24
  let date = new Date(currentDate.getTime() + millisecond)
  return formatDate(date)
}

// 根据传入的年月获取当前月第一天至最后一天
export const getYearOrMonthForMonth = (year, month) => {
  let dateArr = []
  let startDate = year + "-" + month + "-01"
  let endDate = year + '-' + month + '-' + getMonthDays(year, month - 1)
  dateArr.push(startDate)
  dateArr.push(endDate)
  return dateArr
}

/**
 * 获得该月的天数
 * @param {Number} year 年份
 * @param {Number} month 当前月-1
 * @return {Number} 返回天数
 * */
// 方式一
export const getMonthDays = function (year, month) {
  //本月第一天 1-31 
  let relativeDate = new Date(year, month, 1)
  //获得当前月份0-11 
  let relativeMonth = relativeDate.getMonth()
  //获得当前年份4位年 
  let relativeYear = relativeDate.getFullYear()

  //当为12月的时候年份需要加1 
  //月份需要更新为0 也就是下一年的第一个月 
  if (relativeMonth == 11) {
    relativeYear++
    relativeMonth = 0
  } else {
    //否则只是月份增加,以便求的下一月的第一天 
    relativeMonth++
  }
  //一天的毫秒数 
  let millisecond = 1000 * 60 * 60 * 24
  //下月的第一天 
  let nextMonthDayOne = new Date(relativeYear, relativeMonth, 1)
  //返回得到上月的最后一天,也就是本月总天数 
  return new Date(nextMonthDayOne.getTime() - millisecond).getDate()
}
// 方式二
// month 当前月份
// export const getMonthDays = function (year, month) {
//   let day = new Date(year, month, 0)
//   return day.getDate()
// }


/**
 * 获取两个日期之间所有日期
 * @param {String} start 开始日期
 * @param {String} end 结束日期
 * @returns {Array<String>} 返回每一天yyyy-MM-dd
 */
export const getBetweenDateStr = (start, end) => {
  var result = []
  var beginDay = start.split("-")
  var endDay = end.split("-")
  var diffDay = new Date()
  var dateList = new Array
  var i = 0
  diffDay.setDate(beginDay[2])
  diffDay.setMonth(beginDay[1] - 1)
  diffDay.setFullYear(beginDay[0])
  result.push(start)
  while (i == 0) {
    var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000
    diffDay.setTime(countDay)
    dateList[2] = diffDay.getDate()
    dateList[1] = diffDay.getMonth() + 1
    dateList[0] = diffDay.getFullYear()
    if (String(dateList[1]).length == 1) {
      dateList[1] = "0" + dateList[1]
    }
    if (String(dateList[2]).length == 1) {
      dateList[2] = "0" + dateList[2]
    }
    result.push(dateList[0] + "-" + dateList[1] + "-" + dateList[2])
    if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
      i = 1
    }
  };
  return result
}

/**
 * 获取前几小时时间（不跨天）
 * @param {Number} hour 小时数
 * @return {Array<String>} [开始时间,结束时间]yyyy-MM-dd HH:mm:ss
 */
export const getBeforeHourTimeNoStrideDay = (hour) => {
  let dateArr = []
  let currentDate = new Date()
  let millisecond = 1000 * 60 * 60 * hour
  let startDate = new Date(currentDate.getTime() - millisecond)
  let endDate = new Date(currentDate.getTime())
  if (startDate.getHours() > endDate.getHours()) {
    dateArr.push('00:00:00')
    dateArr.push(formatTime(endDate))
  } else {
    dateArr.push(formatTime(startDate))
    dateArr.push(formatTime(endDate))
  }
  return dateArr
}

/**
 * 判断时间是否在某个时间段内
 * @param {String} dateStr 当前时间yyyy-MM-dd HH:mm:ss
 * @param {String} startDateStr 开始时间yyyy-MM-dd HH:mm:ss
 * @param {String} endDateStr 结束时间yyyy-MM-dd HH:mm:ss
 * @return {Boolean} 返回布尔值
 */
export const isInDateInterval = (dateStr, startDateStr, endDateStr) => {
  let date = new Date(dateStr)
  let startDate = new Date(startDateStr)
  let endDate = new Date(endDateStr)
  if (date >= startDate && date <= endDate) {
    return true
  }
  return false
}

/**
 * 获取相差多少分钟
 * @param {String} startTime 开始时间 HH:mm
 * @param {String} endTime 结束时间 HH:mm
 * @return {String}
 */
export const timeDifference = (startTime, endTime) => {
  var start1 = startTime.split(":");
  var startAll = parseInt(start1[0] * 60) + parseInt(start1[1]);

  var end1 = endTime.split(":");
  var endAll = parseInt(end1[0] * 60) + parseInt(end1[1]);
  // console.log("时间差===" + (endAll - startAll));
  return endAll - startAll
}