import vm from '../../main.js'

export default {
  state: {
    // 作业班组
    groupKeyMap: {
      'workGroupId': 'workGroupId',
      'name': 'name',
      'departmentId': 'depid',
      'department': 'depName',
      'classes': 'kaoqinType',
      'workEarly': 'yxsbtq',
      'late': 'cd',
      'severityLate': 'yzcd',
      'leaveEarly': 'zt',
      'offWorkLackCard': 'xbqc',
      'onWorkLackCard': 'sbqk',
      'absenteeism': 'kg',
      'remark': 'remark',
      'module': 'module',
      'timeList': 'kaoqins'
    },
    // 作业班组下的数组
    groupItemKeyMap: {
      'name': 'name',
      'goWorkTime': 'sbsj',
      'offWorkTime': 'xbsj',
      'goWorkTimeSummer': 'xsbsj',
      'offWorkTimeSummer': 'xxbsj',
      'goWorkTimeWinter': 'dsbsj',
      'offWorkTimeWinter': 'dxbsj'
    },
    // 作业规则
    ruleKeyMap: {
      'id': 'workRuleId',
      'name': 'ruleName',
      'status': 'isEnable',
      'ruleItems': 'ruleItems',
      'remark': 'remark',
      'module': 'module'
    },
    // 作业规则下的数组
    ruleItemKeyMap: {
      'customName': 'customName',
      'sysName': 'sysName',
      'isEnable': 'isEnable',
      'remark': 'remark',
      'content': 'content',
      'workRuleItemId': 'workRuleItemId',
      'polymerizationtime': 'polymerizationtime',
    },
    // 冬夏时间 完成度
    timeCompleteKeyMap: {
      'jobsetId': 'jobsetId',
      'kmOkThreshold': 'kmOkThreshold',
      'routeOkThreshold': 'routeOkThreshold',
      'pointOkThreshold': 'pointOkThreshold',
      'motionOkThreshold': 'motionOkThreshold',
      'summerDate': 'summerDate',
      'winterDate': 'winterDate',
      'bsShow': 'bsShow'
    },
    // 道路保洁
    roadCleanPlanKeyMap: {
      'workPlanId': 'workPlanId',
      'uuId': 'uuId',
      'workPlan': 'name',
      'gridId': 'gridId',
      'gridName': 'gridName',
      'personIds': 'person_ids',
      'workRule': 'ruleId',
      'schedulingType': 'type',
      'manualList': 'conf',

      'person': 'employeeNameList',
      'personId': 'employeeIdList',
      'workPlanDays': 'workPlanDays',
      // 详情新字段
      'newWorkPlan': 'workPlanName',
      'newWorkPlanDays': 'workPlanGroupReadList',
      'newGrid': 'grid',
    },
    // 道路保洁item
    roadCleanPlanItemKeyMap: {
      "date": "date",
      "groupId": "workGroupId",
      "workPlanDayId": "workPlanDayId"
    },
    // 道路保洁按天修改
    roadCleanPlanDayKeyMap: {
      "id": "palnId",
      "dayId": "workPlanDayId",
      "groupId": "workGroupId",
      "personId": "personId",
      "date": "date",
      'gridId': 'gridId',
    },
    // 机械作业 垃圾清运 垃圾转运
    carCleanPlanKeyMap: {
      'carPlanIdList': 'carPlanIdList', // id
      'plan': 'planName', // 计划名称
      'gridNameIdList': 'gridIdList', // 批量新增网格id
      'gridNameId': 'gridId', // 网格id
      'gridName': 'gridName', // 网格名称
      'departmentId': 'departmentId',
      'departmentName': 'departmentName',
      'operationMode': 'workType', // 作业方式    1 机动作业 2 固定作业
      'workTypeId': 'workTypeId', // 工作大类id
      'workTypeName': 'workTypeName', // 工作大类名称
      // 'workSubTypeId': 'workSubTypeId', // 工作子类id
      // 'workSubTypeName': 'workSubTypeName', // 工作子类名称
      'status': 'isEnable', // 是否启用
      'rule': 'workRuleName ', // 作业规则
      'ruleId': 'workRuleId', // 作业规则id
      'oilLoss': 'expectOilConsumption', // 单次预计油耗
      'waterLoss': 'expectWaterConsumption', // 单次预计水耗
      'workTime': 'expectTimeConsumption', // 单次作业时长
      'remark': 'remark', // 备注
      'carId': 'carIdList', //
      'car': 'carNumberList',
      // 'driverId': 'driverIds',
      // 'driver': 'driverNames',
      'groupId': 'workGroupId',
      'group': 'workGroupName',
      'period': 'workCycle', // 作业周期
      'count': 'workTrip', // 趟次
      'startTime': 'workStartDate', // 有效时段
      'endTime': 'workEndDate',
      'collectorPointId': 'carPlanCollectPointIdList', // 收集点id
      'collectorPoint': 'carPlanCollectPointNameList', // 收集点name
      'gatherId': 'carPlanCollectStandIdList', // 收集站 中转站id
      'gatherName': 'carPlanCollectStandNameList', // 收集站 中转站name
      'disposeId': 'carPlanProcessStandIdList', // 处理站id
      'disposeName': 'carPlanProcessStandNameList', // 处理站name
      'type': 'type', // 类型 1 机械清扫 2 垃圾清运 3垃圾转运
      'module': 'module', // 1 批量 2 不批量
      'flag': 'flag', // 0新增 1删除
      'expectMileage': 'expectMileage', // 预计行驶里程
    },
  },
  getters: {},
  mutations: {},
  actions: {
    // 新增/修改班组
    createOrEditWorkGroup({
      state
    }, form) {
      if (form.timeList) {
        form.timeList = form.timeList.map(item => vm.$keyMapNew(item, state.groupItemKeyMap))
      }
      let newData = vm.$keyMapNew(form, state.groupKeyMap)
      let params = newData
      if (params.workGroupId) {
        return new Promise((resolve, reject) => {
          vm.$http.editWorkGroup(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createWorkGroup(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取班组详情
    getWorkGroupDetail({
      commit,
      state
    }, workGroupId) {
      return new Promise((resolve, reject) => {
        vm.$http.getWorkGroupDetail({
          workGroupId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.groupKeyMap)
            let itemKeyMap = vm.$swapKeyValue(state.groupItemKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            newData.timeList = newData.timeList.map(item => vm.$keyMapNew(item, itemKeyMap))
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改规则
    createOrEditWorkRule({
      state
    }, form) {
      if (form.ruleItems) {
        form.ruleItems = form.ruleItems.map(item => vm.$keyMapNew(item, state.ruleItemKeyMap))
      }
      let newData = vm.$keyMapNew(form, state.ruleKeyMap)
      let params = newData
      if (params.workRuleId) {
        return new Promise((resolve, reject) => {
          vm.$http.editWorkRule(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createWorkRule(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取规则详情
    getWorkRuleDetail({
      commit,
      state
    }, workRuleId) {
      return new Promise((resolve, reject) => {
        vm.$http.getWorkRuleDetail({
          workRuleId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.ruleKeyMap)
            let itemKeyMap = vm.$swapKeyValue(state.ruleItemKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            newData.ruleItems = newData.ruleItems.map(item => vm.$keyMapNew(item, itemKeyMap))
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 修改规则项
    editWorkRuleItem({
      state
    }, params) {
      return new Promise((resolve, reject) => {
        vm.$http.editWorkRuleItem(params).then(res => {
          vm.$Message.info(res.message)
          if (res.code === 200) {
            resolve()
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 修改冬夏时间 完成度
    createOrEditTimeComplete({
      state
    }, form) {
      let newData = vm.$keyMapNew(form, state.timeCompleteKeyMap)
      let params = newData
      if (params.jobsetId) {
        return new Promise((resolve, reject) => {
          vm.$http.editTimeComplete(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取冬夏时间 完成度详情
    getTimeCompleteDetail({
      commit,
      state
    }, jobsetId) {
      return new Promise((resolve, reject) => {
        vm.$http.getTimeCompleteDetail({
          jobsetId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.timeCompleteKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 道路保洁排班计划
    createOrEditRoadCleanPlan({
      state
    }, form) {
      if (form.manualList) {
        form.manualList = form.manualList.map(item => vm.$keyMapNew(item, state.roadCleanPlanItemKeyMap))
      }
      let params = vm.$keyMapNew(form, state.roadCleanPlanKeyMap)
      if (params.uuId) {
        return new Promise((resolve, reject) => {
          vm.$http.editRoadPlan(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createRoadPlan(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取道路保洁排班计划详情
    getRoadCleanPlanDetail({
      commit,
      state
    }, uuId) {
      return new Promise((resolve, reject) => {
        vm.$http.getRoadPlanDetail({
          uuId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            // console.log(data, '详情接口返回');
            let newKeyMap = vm.$swapKeyValue(state.roadCleanPlanKeyMap)
            // let itemKeyMap = vm.$swapKeyValue(state.roadCleanPlanItemKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            // newData.manualList = newData.manualList.map(item => vm.$keyMapNew(item, itemKeyMap))
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 按天新增/修改道路保洁排班计划
    createOrEditRoadCleanPlanDay({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.roadCleanPlanDayKeyMap)
      if (params.workPlanDayId) {
        return new Promise((resolve, reject) => {
          vm.$http.editRoadPlanDay(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createRoadPlanDay(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 新增/修改 机械清扫排班计划
    createOrEditMechanicalCleanPlan({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.carCleanPlanKeyMap)
      if (params.carPlanIdList) {
        return new Promise((resolve, reject) => {
          vm.$http.editMechanicalPlan(params).then(res => {
            if (res.code === 200) {
              console.log(res);
              if (res.result.message) {
                vm.$Message.info(res.result.message)
                reject(res)
              } else {
                vm.$Message.info(res.message)
                resolve()
              }
            } else {
              vm.$Message.info(res.message)
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createMechanicalPlan(params).then(res => {
            if (res.code === 200) {
              if (res.result.message) {
                vm.$Message.info(res.result.message)
                reject(res)
              } else {
                vm.$Message.info(res.message)
                resolve()
              }
            } else {
              vm.$Message.info(res.message)
              reject(res)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    // 获取机械清扫排班计划详情
    getMechanicalCleanPlanDetail({
      commit,
      state
    }, data) {
      return new Promise((resolve, reject) => {
        vm.$http.getMechanicalPlanDetail(data).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.carCleanPlanKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  }
}