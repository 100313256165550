import axios from '../http.interceptor'

export default {
  // 获取热力地图数据
  getScreenMapData: (params) => axios.post('/hss/databigscreen/heatMap', params),

  // 获取人车行驶里程
  getScreenMileageData: (params) => axios.post('/hss/databigscreen/mileageMonitor', params),

  // 获取人员数据
  getScreenPersonData: (params) => axios.post('/hss/databigscreen/personMonitor', params),

  // 获取车辆数据
  getScreenCarData: (params) => axios.post('/hss/databigscreen/carMonitor', params),

  // 获取人员在线数据
  getScreenPersonOnline: (params) => axios.post('hss/databigscreen/personWorkMonitor', params),

  // 获取车辆作业
  getScreenCarWork: (params) => axios.post('/hss/databigscreen/carWorkMonitor', params),

  // 获取作业播报
  getScreenWorkBroadcast: (params) => axios.post('/hss/databigscreen/workBroadcast', params),

  // 获取设施数据
  getScreenFaclData: (params) => axios.post('/hss/databigscreen/facilitiesMonitor', params),

  // 获取异常数据
  getScreenAbnormalData: (params) => axios.post('/hss/databigscreen/abnormalMonitor', params),

  // 获取异常播报
  getScreenAbnormalBroadcast: (params) => axios.post('/hss/databigscreen/abnormalBroadcast', params),

  // 获取应急计划,应急预案数量
  getScreenPlanCount: (params) => axios.post('/hss/databigscreen/emergentMonitor', params),

  // 获取应急计划播报
  getScreenPlanBroadcast: (params) => axios.post('/hss/databigscreen/emergentBroadcast', params),

  // 获取应急计划图表
  getScreenPlanEcharts: (params) => axios.post('/hss/databigscreen/emergentPlanTimeTrend', params),

  // 获取巡查维护数量
  getScreenCaseCount: (params) => axios.post('/hss/databigscreen/patrolCasesMonitor', params),

  // 获取巡查维护播报
  getScreenCaseBroadcast: (params) => axios.post('/hss/databigscreen/caseBroadcast', params),

  // 获取地图计划列表
  getScreenMapPlanList: (params) => axios.post('/hss/bigscreen/getPlanList', params),
}