<template></template>

<script>
import bus from '@/utils/bus'
import config from '@/config/constantconfig';
export default {
  components: {},
  data () {
    return {
      socket: null,
      isDestroyed: false
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    this.isDestroyed = true
    this.socket.close();
    this.socket = null;
  },
  methods: {
    init () {
      bus.$on('openWebSocket', token => {
        this.openWebSocket(token)
      })
    },
    // 开启websocket
    openWebSocket (token) {
      if (this.socket) return
      if (typeof (WebSocket) == 'undefined') {
        console.log('您的浏览器不支持WebSocket')
        return
      }
      //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
      let socketUrl = config.socketUrl + token
      this.socket = new WebSocket(socketUrl)
      //打开事件
      this.socket.onopen = () => {
        console.log('websocket已打开')
      };
      //获得消息事件
      this.socket.onmessage = msg => {
        //发现消息进入    开始处理前端触发逻辑
        this.onmessage(msg.data)
      };
      //关闭事件
      this.socket.onclose = () => {
        console.log('websocket已关闭')
        this.socket = null
        if (!this.isDestroyed) {
          this.openWebSocket(token)
        }
      };
      //发生了错误事件
      this.socket.onerror = () => {
        console.log('websocket发生了错误')
      }
    },
    // 对socket推送的事件进行处理
    onmessage (data) {
      if (!data || data === '连接成功') return
      console.log(data);
      let json = JSON.parse(data)
      switch (json.model) {
        // 事件上报
        case 1:
        // 报警提醒
        case 2:
        // 违规提醒
        case 3:
        // 井盖巡检
        case 7:
          bus.$emit('eventRemind', json)
          break;
        default:
          break;
      }
    }
  },
}
</script>

<style lang='scss' scoped>
</style>