export default [{
  path: 'userArchives',
  name: 'userArchives',
  component: () => import('../../views/admin/systemSetting/userArchives'),
  meta: {
    permission: []
  }
}, {
  path: 'department',
  name: 'department',
  component: () => import('../../views/admin/systemSetting/department'),
  meta: {
    permission: []
  }
}, {
  path: 'notice',
  name: 'notice',
  component: () => import('../../views/admin/systemSetting/notice'),
  meta: {
    permission: []
  }
}, {
  path: 'log',
  name: 'log',
  component: () => import('../../views/admin/systemSetting/log'),
  meta: {
    permission: []
  }
}, {
  path: 'systemConfig',
  name: 'systemConfig',
  component: () => import('../../views/admin/systemSetting/systemConfig'),
  meta: {
    permission: []
  }
}, {
  path: 'administrativeFence',
  name: 'administrativeFence',
  component: () => import('../../views/admin/systemSetting/administrativeFence'),
  meta: {
    permission: []
  }
}, ]