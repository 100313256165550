车载视频
<template>
  <!-- 车载视频 -->
  <div ref="player"></div>
</template>

<script>
import config from '../../config/constantconfig';
export default {
  components: {},
  props: {
    count: {
      type: Number,
      default: 1
    },
    dataId: String,
    channelId: String,
    startTime: String,
    endTime: String,
  },
  data () {
    return {
      player: null,
      erd: null
    };
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.close()
  },
  methods: {
    init () {
      // 分屏改变大小 start
      //导入element-resize-detector
      let elementResizeDetectorMaker = require("element-resize-detector")
      // 创建实例
      this.erd = elementResizeDetectorMaker()
      //监听元素 大小变化
      this.erd.listenTo(this.$refs.player, element => {
        let width = element.offsetWidth
        let height = element.offsetHeight
        this.player.Resize(width, height)
      })
      // 分屏改变大小 end
      this.player = CvNetVideo.Init(this.$refs.player, this.count, {
        callback: () => {
          // 不加延迟，打开多个只会打开一个，解决不能打开多个视频的问题
          setTimeout(() => {
            this.start()
          }, 200);
        },
      })
      // this.start()
    },
    start () {
      if (!this.player) return
      if (this.startTime && this.endTime) {
        // 视频回放
        this.player.QueryVideoFileList(this.dataId, this.channelId, this.dateToUnixLong(this.startTime), this.dateToUnixLong(this.endTime), '0', 2, 1, 1,
          (data) => {
            // data.FileList设备返回的播放文件列表
            let arr = data.FileList.map(item => {
              item.startTime = this.time1078ToTime(item.StartTime)
              item.endTime = this.time1078ToTime(item.EndTime)
              item.type = 'socket'
              return item
            })
            this.$emit('onSearchPlaybackVideoList', arr)
          }, 0, {
          clusterHost: config.socketVideoConfig.clusterHost,
          clusterPort: config.socketVideoConfig.clusterPort,
          events: {
            onStop: this.onStop
          }
        }, 0)
      } else {
        // 视频点播
        this.player.StartRealTimeVideo(this.dataId, this.channelId, 1, true, 0, {
          clusterHost: config.socketVideoConfig.clusterHost,
          clusterPort: config.socketVideoConfig.clusterPort,
          events: {
            onStop: this.onStop
          }
        }, null, 0)
        this.$emit('onStart')
      }
    },
    // 视频回放
    playbackVideo (item) {
      // startTime与endTime跨文件的话 就会不准确
      this.player.PlaybackVideo(this.dataId, this.channelId, 2, 0, 0, 0, 1, item.startTime, item.endTime, 0, {
        clusterHost: config.socketVideoConfig.clusterHost,
        clusterPort: config.socketVideoConfig.clusterPort,
        events: {
          onStop: this.onStop
        }
      }, null, 0, 0)
      this.$emit('onStart')
    },
    // 视频回放控制   暂时无用
    playbackVideoControl (type, speed, time) {
      console.log(type, speed, time);
      this.player.PlaybackVideoControl(this.dataId, this.channelId, type, speed, time, 0, {
        clusterHost: config.socketVideoConfig.clusterHost,
        clusterPort: config.socketVideoConfig.clusterPort,
        events: {
          onStop: this.onStop
        }
      })
    },
    resize (width, height) {
      this.player.Resize(width, height);
    },
    onStop () {
      this.$emit('onStop')
    },
    close () {
      this.erd.uninstall(this.$refs.player)
      this.player.Stop(0)
      this.player = null
    },
    // 时间转时间戳
    dateToUnixLong (date) {
      return Date.parse(this.parseDate(date)) / 1000;
    },
    parseDate (date) {
      let t = Date.parse(date);
      if (isNaN(t)) {
        return new Date(Date.parse(date.replace(/-/g, '/')));
      } else {
        return new Date(t);
      }
    },
    // 时间戳转时间
    time1078ToTime (time1078) {
      return '20' + time1078.substring(0, 2) + '-' + time1078.substring(2, 4) + '-' + time1078.substring(4, 6)
        + ' ' + time1078.substring(6, 8) + ':' + time1078.substring(8, 10) + ':' + time1078.substring(10, 12)
    }
  },
}
</script>

<style lang='scss' scoped>
</style>