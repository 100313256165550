import axios from '../http.interceptor'

export default {
  // 获取字典大类列表获取，一级传0，二级传一级的id
  getCostDictionariesList: (params) => axios.post('/hss/costdict/getitemListByParentId', params),

  // 新增字典大类
  createCostDictionaries: (params) => axios.post('/hss/costdict/insert', params),

  // 修改字典大类
  editCostDictionaries: (params) => axios.post('/hss/costdict/update', params),

  // 删除字典大类
  deleteCostDictionaries: (params) => axios.post('/hss/costdict/deleteByid', params),

  // 获取明细列表
  getCostDictionariesItemList: (params) => axios.post('/hss/costdictitem/getitemList', params),

  // 获取明细全部列表
  getCostDictionariesItemAllList: (params) => axios.post('/hss/costdict/getitemList', params),

  // 明细新增
  createCostDictionariesItem: (params) => axios.post('/hss/costdictitem/insert', params),

  // 明细删除
  deleteCostDictionariesItem: (params) => axios.post('/hss/costdictitem/deleteByid', params),

  // 明细修改
  editCostDictionariesItem: (params) => axios.post('/hss/costdictitem/update', params),

  // 获取明细详情
  getCostDictionariesItemDetail: (params) => axios.post('/hss/costdictitem/getitemByid', params),

  // 车辆油费列表
  getCarCostOilList: (params) => axios.post('/hss/tloilcostcfg/getList', params),

  // 新增油费
  createCarCostOil: (params) => axios.post('/hss/tloilcostcfg/insert', params),

  // 删除油费
  deleteCarCostOil: (params) => axios.post('/hss/tloilcostcfg/deleteByid', params),

  // 修改油费
  editCarCostOil: (params) => axios.post('/hss/tloilcostcfg/update', params),

  // 油费详情
  getCarCostOilDetail: (params) => axios.post('/hss/tloilcostcfg/getitemByid', params),

  // 导出油费
  exportCarCostOil: (params) => axios.post('/hss/tloilcostcfg/excelExport', params),

  // 导出油费模板
  exportCarCostOilTem: (params) => axios.post('/hss/tloilcostcfg/excelExportTemplate', params),

  // 车辆维修费列表
  getCarCostRepairList: (params) => axios.post('/hss/tlrepaircfg/getlist', params),

  // 新增维修费
  createCarCostRepair: (params) => axios.post('/hss/tlrepaircfg/insert', params),

  // 删除维修费
  deleteCarCostRepair: (params) => axios.post('/hss/tlrepaircfg/deleteByid', params),

  // 修改维修费
  editCarCostRepair: (params) => axios.post('/hss/tlrepaircfg/update', params),

  // 维修费详情
  getCarCostRepairDetail: (params) => axios.post('/hss/tlrepaircfg/getbyid', params),

  // 导出维修费
  exportCarCostRepair: (params) => axios.post('/hss/tlrepaircfg/excelExport', params),

  // 导出维修费模板
  exportCarCostRepairTem: (params) => axios.post('/hss/tlrepaircfg/excelExportTemplate', params),

  // 车辆保养费列表
  getCarCostMaintainList: (params) => axios.post('/hss/tlmaintaincfg/getlist', params),

  // 新增保养费
  createCarCostMaintain: (params) => axios.post('/hss/tlmaintaincfg/insert', params),

  // 删除保养费
  deleteCarCostMaintain: (params) => axios.post('/hss/tlmaintaincfg/deleteByid', params),

  // 修改保养费
  editCarCostMaintain: (params) => axios.post('/hss/tlmaintaincfg/update', params),

  // 保养费详情
  getCarCostMaintainDetail: (params) => axios.post('/hss/tlmaintaincfg/getbyid', params),

  // 导出保养费
  exportCarCostMaintain: (params) => axios.post('/hss/tlmaintaincfg/excelExport', params),

  // 导出保养费模板
  exportCarCostMaintainTem: (params) => axios.post('/hss/tlmaintaincfg/excelExportTemplate', params),

  // 车辆年检费列表
  getCarCostExamineList: (params) => axios.post('/hss/tlinspectioncfg/getlist', params),

  // 新增年检费
  createCarCostExamine: (params) => axios.post('/hss/tlinspectioncfg/insert', params),

  // 删除年检费
  deleteCarCostExamine: (params) => axios.post('/hss/tlinspectioncfg/deleteByid', params),

  // 修改年检费
  editCarCostExamine: (params) => axios.post('/hss/tlinspectioncfg/update', params),

  // 年检费详情
  getCarCostExamineDetail: (params) => axios.post('/hss/tlinspectioncfg/getbyid', params),

  // 导出年检费
  exportCarCostExamine: (params) => axios.post('/hss/tlinspectioncfg/excelExport', params),

  // 导出年检费模板
  exportCarCostExamineTem: (params) => axios.post('/hss/tlinspectioncfg/excelExportTemplate', params),

  // 车辆保险费列表
  getCarCostInsuranceList: (params) => axios.post('/hss/tlinsurancecfg/getlist', params),

  // 新增保险费
  createCarCostInsurance: (params) => axios.post('/hss/tlinsurancecfg/insert', params),

  // 删除保险费
  deleteCarCostInsurance: (params) => axios.post('/hss/tlinsurancecfg/deleteByid', params),

  // 修改保险费
  editCarCostInsurance: (params) => axios.post('/hss/tlinsurancecfg/update', params),

  // 保险费详情
  getCarCostInsuranceDetail: (params) => axios.post('/hss/tlinsurancecfg/getbyid', params),

  // 导出保险费
  exportCarCostInsurance: (params) => axios.post('/hss/tlinsurancecfg/excelExport', params),

  // 导出保险费模板
  exportCarCostInsuranceTem: (params) => axios.post('/hss/tlinsurancecfg/excelExportTemplate', params),

  // 车辆违章费列表
  getCarCostViolationList: (params) => axios.post('/hss/tlviolationcfg/getList', params),

  // 新增违章费
  createCarCostViolation: (params) => axios.post('/hss/tlviolationcfg/insert', params),

  // 删除违章费
  deleteCarCostViolation: (params) => axios.post('/hss/tlviolationcfg/deleteByid', params),

  // 修改违章费
  editCarCostViolation: (params) => axios.post('/hss/tlviolationcfg/update', params),

  // 违章费详情
  getCarCostViolationDetail: (params) => axios.post('/hss/tlviolationcfg/getitemByid', params),

  // 导出违章费
  exportCarCostViolation: (params) => axios.post('/hss/tlviolationcfg/excelExport', params),

  // 导出违章费模板
  exportCarCostViolationTem: (params) => axios.post('/hss/tlviolationcfg/excelExportTemplate', params),

  // 车辆车杂费列表
  getCarCostOtherList: (params) => axios.post('/hss/tlothercarcostcfg/getList', params),

  // 新增车杂费
  createCarCostOther: (params) => axios.post('/hss/tlothercarcostcfg/insert', params),

  // 删除车杂费
  deleteCarCostOther: (params) => axios.post('/hss/tlothercarcostcfg/deleteByid', params),

  // 修改车杂费
  editCarCostOther: (params) => axios.post('/hss/tlothercarcostcfg/update', params),

  // 车杂费详情
  getCarCostOtherDetail: (params) => axios.post('/hss/tlothercarcostcfg/getitemByid', params),

  // 导出车杂费
  exportCarCostOther: (params) => axios.post('/hss/tlothercarcostcfg/excelExport', params),

  // 导出车杂费模板
  exportCarCostOtherTem: (params) => axios.post('/hss/tlothercarcostcfg/excelExportTemplate', params),

  // 人员工资组成列表
  getPersonCostWageList: (params) => axios.post('/hss/tlempsalary/getList', params),

  // 新增人员工资组成
  createPersonCostWage: (params) => axios.post('/hss/tlempsalary/insert', params),

  // 删除人员工资组成
  deletePersonCostWage: (params) => axios.post('/hss/tlempsalary/deletebyid', params),

  // 修改人员工资组成
  editPersonCostWage: (params) => axios.post('/hss/tlempsalary/update', params),

  // 人员工资组成详情
  getPersonCostWageDetail: (params) => axios.post('/hss/tlempsalary/getDetail', params),

  // 导出人员工资组成
  exportPersonCostWage: (params) => axios.post('/hss/tlempsalary/excelExport', params),

  // 导出人员工资组成模板
  exportPersonCostWageTem: (params) => axios.post('/hss/tlempsalary/excelExportTemplate', params),

  // 人员杂费列表
  getPersonCostOtherList: (params) => axios.post('/hss/tlempincidental/getList', params),

  // 新增人员杂费
  createPersonCostOther: (params) => axios.post('/hss/tlempincidental/insert', params),

  // 删除人员杂费
  deletePersonCostOther: (params) => axios.post('/hss/tlempincidental/deletebyid', params),

  // 修改人员杂费
  editPersonCostOther: (params) => axios.post('/hss/tlempincidental/update', params),

  // 人员杂费详情
  getPersonCostOtherDetail: (params) => axios.post('/hss/tlempincidental/getDetail', params),

  // 导出人员杂费
  exportPersonCostOther: (params) => axios.post('/hss/tlempincidental/excelExport', params),

  // 导出人员杂费模板
  exportPersonCostOtherTem: (params) => axios.post('/hss/tlempincidental/excelExportTemplate', params),

  // 运营成本列表
  getOperatingCostList: (params) => axios.post('/hss/tlcostoperate/getList', params),

  // 新增运营成本
  createOperatingCost: (params) => axios.post('/hss/tlcostoperate/insert', params),

  // 删除运营成本
  deleteOperatingCost: (params) => axios.post('/hss/tlcostoperate/deletebyid', params),

  // 修改运营成本
  editOperatingCost: (params) => axios.post('/hss/tlcostoperate/update', params),

  // 运营成本详情
  getOperatingCostDetail: (params) => axios.post('/hss/tlcostoperate/getDetail', params),

  // 导出运营成本
  exportOperatingCost: (params) => axios.post('/hss/tlcostoperate/excelExport', params),

  // 导出运营成本模板
  exportOperatingCostTem: (params) => axios.post('/hss/tlcostoperate/excelExportTemplate', params),

  // 成本汇总 总成本列表
  getTotalCostList: (params) => axios.post('/hss/totalcost/gettotalList', params),

  // 成本汇总 总成本echarts
  getTotalCostEcharts: (params) => axios.post('/hss/totalcost/gettotalChars', params),

  // 成本汇总 车辆列表
  getCarCostList: (params) => axios.post('/hss/totalcost/getcarList', params),

  // 成本汇总 车辆echarts
  getCarCostEcharts: (params) => axios.post('/hss/totalcost/getcarChars', params),

  // 成本汇总 人员列表
  getEmployeesCostList: (params) => axios.post('/hss/totalcost/getempList', params),

  // 成本汇总 人员echarts
  getEmployeesCostEcharts: (params) => axios.post('/hss/totalcost/getempChars', params),

  // 成本汇总 运营列表
  getOperateCostList: (params) => axios.post('/hss/totalcost/getopeList', params),

  // 成本汇总 运营echarts
  getOperateCostEcharts: (params) => axios.post('/hss/totalcost/getOperateChars', params),

}