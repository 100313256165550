import modules from './modules'
import Home from '../views/admin/home/Home'
import Login from '../views/login/login'
import store from '@/store';

export default [{
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      norequirelogin: true
    }
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main/map'),
    meta: {
      permission: []
    }
  },
  {
    path: '/admin/equipmentMonitor',
    name: 'equipmentMonitor',
    component: () => import('../views/admin/systemSetting/equipmentMonitor'),
    meta: {
      permission: []
    }
  },
  {
    path: '/track',
    name: 'track',
    component: () => import('../views/main/track'),
    meta: {
      norequirelogin: true, // 是否不需要登录
      title: '轨迹页',
      permission: []
    }
  },
  {
    path: '/newTrack',
    name: 'newTrack',
    component: () => import('../views/main/newTrack'),
    meta: {
      norequirelogin: true, // 是否不需要登录
      title: '轨迹页',
      permission: []
    }
  },
  {
    path: '/videoSurveillance',
    name: 'videoSurveillance',
    component: () => import('../views/videoSurveillance'),
    meta: {
      title: '视频监控',
      permission: []
    }
  },
  {
    path: '/largeScreen',
    name: 'largeScreen',
    component: () => import('../views/largeScreen'),
    meta: {
      norequirelogin: true // 是否不需要登录
    },
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: to => {
      return store.getters.getAdminRedirect
    },
    component: Home,
    children: modules
  },
  {
    path: '/app/case',
    name: 'appCase',
    component: () => import('../views/app/case'),
    meta: {
      norequirelogin: true
    },
  },
  {
    path: '/app/location',
    name: 'appLocation',
    component: () => import('../views/app/location'),
    meta: {
      norequirelogin: true // 是否不需要登录
    },
  },
  { //  车载视频
    path: '/app/carVideo1',
    name: 'appCarVideo1',
    component: () => import('../views/app/carVideo1'),
    meta: {
      norequirelogin: true // 是否不需要登录
    },
  },
  {
    path: '*',
    redirect: '/main'
  }
]