<template>
  <Modal v-model="visible"
         @on-visible-change="onChangeVisible"
         title="视频监控"
         footer-hide
         :z-index="900"
         :width="width">
    <div class="flex flex-wrap j-between video-box"
         v-if="data">
      <div v-for="item in data.details"
           :key="item.channelid"
           :class="{'video-last':2 * (Math.ceil(data.details.length / 2))}"
           :style="{width:data.details.length==1 ? '768px' : '380px',height:data.details.length==1 ? '400px' : '200px'}">
        <SocketVideoPlayer :dataId="data.equipcode"
                           :channelId="item.channelid"
                           style="width:100%;height:100%"
                           ref="socketVideo"
                           v-if="data.eqptype==='车载监控'"></SocketVideoPlayer>
        <HttpVideoPlayer :dataId="data.equipcode"
                         :channelId="item.channelid"
                         style="width:100%;height:100%"
                         v-if="data.eqptype==='视频设备'"></HttpVideoPlayer>
      </div>
    </div>
  </Modal>
</template>

<script>
import bus from '@/utils/bus'
import SocketVideoPlayer from '@/components/common/SocketVideoPlayer';
import HttpVideoPlayer from '@/components/common/HttpVideoPlayer';
export default {
  components: {
    SocketVideoPlayer,
    HttpVideoPlayer
  },
  data () {
    return {
      visible: false,
      data: null,
      width: null
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.removeEventListener('resize', this.resetVideoSize)
    bus.$off('showPlayVideoModal')
  },
  methods: {
    init () {
      bus.$on('showPlayVideoModal', data => {
		  if(this.$store.state.statusType){
			  return false
		  }
        this.data = data
        if (this.data.details.length == 1) {
          this.width = 2 * 380 + 10 + 16 * 2
        } else if (this.data.details.length == 2) {
          this.width = 2 * 380 + 10 + 16 * 2
        } else {
          // 视频宽度 + 右边距 + padding left right
          // Math.ceil向上取整
          if (Math.ceil(this.data.details.length / 2) > 4) {
            this.width = 4 * 380 + 3 * 10 + 16 * 2
          } else {
            this.width = Math.ceil(this.data.details.length / 2) * 380 + (Math.ceil(this.data.details.length / 2) - 1) * 10 + 16 * 2
          }
        }
        this.visible = true
      })
    },
    // 清除全屏
    resetVideoSize () {
      window.addEventListener('resize', () => {
        // 判断全屏
        let isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (!isFullscreen) {
          if (this.$refs.socketVideo) {
            // 2021.9.7目前还不好用 加100延时测试(可能因为异步)
            setTimeout(() => {
              this.$refs.socketVideo.forEach(item => {
                // 跟样式一样
                if (data.details.length == 1) {
                  item.resize(768, 400)
                } else {
                  item.resize(380, 200)
                }
              })
            }, 500);
          }
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.data = null
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/modal";
.video-box {
  max-height: 630px;
  overflow-y: auto;
  div {
    // width: 380px;
    height: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .video-last {
    margin-right: 0;
  }
}
</style>