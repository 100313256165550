import axios from '../http.interceptor'

export default {
	// 获取语音通话账号
	getUserSig: (params) => axios.post('/hss/voiceIntercom/getUserSig', params),
	
	// 获取语音通话列表
	getVoiceIntercomGroup: (params) => axios.post('/hss/voiceIntercom/getVoiceIntercomGroup', params),
	
	// 创建语音通话群组
	createVoiceIntercomGroup: (params) => axios.post('/hss/voiceIntercom/createVoiceIntercomGroup', params),
	
  // 获取人员网格
  getMapPersonGrid: (params) => axios.post('hss/tlemployee/getGridList', params),

  // 获取天气
  getWeather: (params) => axios.post('/hss/weather/getCityWeather', params),

  // 获取地图标记点列表
  getMapMarkerList: (params) => axios.post('/hss/elementtotal/getAddrForMap', params),

  // 获取提醒设置
  getMapRemindDetail: (params) => axios.post('/hss/eventtoremind/detail', params),

  // 修改提醒设置
  editMapRemind: (params) => axios.post('/hss/eventtoremind/update', params),

  // 环卫要素搜索
  getMapSearchList: (params) => axios.post('/hss/elementtotal/getSearchList', params),

  // 人员搜索
  getMapSearchPersonList: (params) => axios.post('/hss/elementtotal/getEmpSearchList', params),

  // 车辆搜索
  getMapSearchCarList: (params) => axios.post('/hss/elementtotal/getCarSearchList', params),

  // 设施搜索
  getMapSearchFaclList: (params) => axios.post('/hss/elementtotal/getFaclSearchList', params),

  // 综合监管-要素概况
  getMapTypeTotalNumber: (params) => axios.post('/hss/elementtotal/getElementsList', params),

  // 综合监管-人员/车辆概况-人员
  getMapPersonSituation: (params) => axios.post('/hss/bigscreen/employeeOnGuard', params),

  // 综合监管-人员/车辆概况-车辆
  getMapCarSituation: (params) => axios.post('/hss/bigscreen/carOnGuard', params),

  // 综合监管-作业监管
  getMapWorkSupervise: (params) => axios.post('/hss/bigscreen/workSupervise', params),

  // 综合监管-当日异常
  getMapTodayException: (params) => axios.post('/hss/bigscreen/todayException', params),

  // 综合监管-当日异常-车辆异常类型
  getMapExceptionTypeCar: (params) => axios.post('/hss/bigscreen/expTypeCar', params),

  // 综合监管-当日异常-人员异常类型
  getMapExceptionTypePerson: (params) => axios.post('/hss/bigscreen/expTypePerson', params),

  // 综合监管-当日异常-异常车辆列表
  getMapExceptionCarList: (params) => axios.post('/hss/bigscreen/expCarList', params),

  // 综合监管-当日异常-异常人员列表
  getMapExceptionPersonList: (params) => axios.post('/hss/bigscreen/expPersonList', params),

  // 道路保洁-人员监控
  getMapPersonState: (params) => axios.post('/hss/bigscreen/personMonitor', params),

  // 道路保洁-人员配置
  getMapPersonWorkConfig: (params) => axios.post('/hss/empPlan/employeeConfig', params),

  // 道路保洁-作业出勤
  getMapPersonWorkState: (params) => axios.post('/hss/bigscreen/toDayAttendance', params),

  // 机械作业-车辆监控
  getMapCarState: (params) => axios.post('/hss/bigscreen/mechanicalCarMonitor', params),

  // 机械作业-作业资源
  getMapWorkResource: (params) => axios.post('/hss/bigscreen/workResource', params),

  // 机械作业-作业资源-作业路段
  getMapGuidepostList: (params) => axios.post('/hss/bigscreen/treeData', params),

  // 机械作业-作业实况
  getMapCarWorkScene: (params) => axios.post('/hss/bigscreen/jxzyWorkScene', params),

  // 机械作业-作业实况列表
  getMapCarWorkSceneList: (params) => axios.post('/hss/bigscreen/jxzyWorkSceneList', params),

  // 道路保洁-作业实况列表
  getDegreeCompletionList: (params) => axios.post('/hss/empPlan/getDegreeCompletionList', params),

  // 垃圾清运-应清点位
  getMapGarbageCleanPoint: (params) => axios.post('/hss/carTrack/cleanPoint', params),

  // 垃圾清运-作业实况
  getMapGarbageWorkScene: (params) => axios.post('/hss/bigscreen/ljqyWorkScene', params),

  // 垃圾清运-作业实况列表
  getMapGarbageWorkSceneList: (params) => axios.post('/hss/rptCarWork/list', params),

  // 垃圾清运-车辆监控
  getMapGarbageState: (params) => axios.post('/hss/bigscreen/pickupCarMonitor', params),

  // 车辆详情作业概况，作业日历
  getMapCarWorkList: (params) => axios.post('/hss/rptCarWork/detailList', params),

  // 车辆详情异常日历
  getMapCarViolationList: (params) => axios.post('/hss/carTrack/violationsAndAlarms', params),

  // 车辆、人员详情 作业记录
  getMapWorkRecordList: (params) => axios.post('/hss/worklog/listById', params),

  // 车辆详情 油量 速度
  getMapCarOikOrSpeed: (params) => axios.post('/hss/carTrack/lastCarTrack', params),

  // 人员详情作业概况，作业日历
  getMapPeosonWorkList: (params) => axios.post('/hss/rptEmployeeWork/list', params),

  // 人员详情异常日历
  getMapPeosonViolationList: (params) => axios.post('/hss/employeeTrack/getEmployeeAlarm', params),

  // 设施监控处理员数量
  getMapWorkTypeCount: (params) => axios.post('/hss/elementtotal/getWorkTypeNumber', params),

  // 设施监控转运车数量
  getMapCarTypeCount: (params) => axios.post('/hss/elementtotal/getCarTypeNumber', params),

  // 设施监控-当日异常-异常列表
  getMapFacilTodayException: (params) => axios.post('/hss/bigscreen/expFacilitiesList', params),

  // 垃圾桶详情 日历
  getMapTrashCaledar: (params) => axios.post('/hss/tlfacilities/selectCarWorkByDate', params),

  // 公厕详情 配置
  getMapTotalConfig: (params) => axios.post('/hss/tlfacilities/selectTlequipByFacId', params),

  // 垃圾桶详情 作业记录
  getMapTrashWorkRecord: (params) => axios.post('/hss/tlfacilities/selectLjpyWorkPlan', params),

  // 古树 养护记录
  getMapTreeMaintainList: (params) => axios.post('/hss/treemaintain/list', params),

  // 古树 养护天数
  getMapTreeMaintainDays: (params) => axios.post('/hss/treemaintain/getConserveDayNum', params),

  // 应急管理-应急预案库
  getMapEmergentIntend: (params) => axios.post('/hss/tlresplan/emergentPlanCalendar', params),

  // 应急管理-应急进行中计划
  getOngoingEmergentPlan: (params) => axios.post('/hss/tlemergentplan/emergentPlanCalendar', params),

  // 通知记录
  getNoticeList: (params) => axios.post('/hss/notice/list4app', params),
  getHummanTotal: (params) => axios.post('/hss/elementtotal/getHummanTotal', params), // 设施人流量统计
  getToiletNumMap: (params) => axios.post('/hss/elementtotal/getToiletNumMap', params), // 设施概况
  getToiletStatusNumMap: (params) => axios.post('/hss/elementtotal/getToiletStatusNumMap', params), // 设施监控  获取公厕及中转站 状态及数量
  getToiletDetailMap: (params) => axios.post('/hss/elementtotal/getToiletDetailMap', params), // 设施监控  获取公厕及中转站详情
  getGreenAreaMap: (params) => axios.post('/hss/elementtotal/getGreenAreaMap', params), // 园林古树  绿化面积计算
  getSensorStatusMap: (params) => axios.post('/hss/elementtotal/getSensorStatusMap', params), // 园林古树  传感器监测
  getGreenStatusMap: (params) => axios.post('/hss/elementtotal/getGreenStatusMap', params), // 园林古树  绿化情况
  getFaclAlarmNum: (params) => axios.post('/hss/elementtotal/getFaclAlarmNum', params), // 设施报警  报警数量
  getCarLocation: (params) => axios.post('/hss/elementtotal/getCarLocation', params), // 车辆定位
  getEmpLocation: (params) => axios.post('/hss/elementtotal/getEmpLocation', params), // 人员定位
  getDtuByFaclId: (params) => axios.post('/hss/elementtotal/getDtuByFaclId', params), // 获取设施 烟雾、湿度、氨气
  getManholeCoverByFaclId: (params) => axios.post('/hss/elementtotal/getManholeCoverByFaclId', params), // 获取井盖 烟雾、湿度、氨气
  getCarMoveStay: (params) => axios.post('/hss/elementtotal/getCarMoveStay', params), // 历史轨迹-轨迹节点

  // 历史轨迹-车辆轨迹节点
  getCarTrackNodeList: (params) => axios.post('/hss/carTrack/list', params),

  // 历史轨迹-车辆轨迹纠偏
  getCarCorrectTrack: (params) => axios.post('/hss/carTrackRevise/list', params),

  // 历史轨迹-分页车辆轨迹节点
  getCarTrackNodeListPage: (params) => axios.post('/hss/carTrack/selectPage', params),

  // 历史轨迹-车辆轨迹、报警、违规汇总
  getAllCarTrackNodeList: (params) => axios.post('/hss/carTrack/alarmAndViolationCarList', params),
  
  // 历史轨迹-电子围栏
  getCheckWorkGrid: (params) => axios.post('/hss/employeeTrack/getCheckWorkGrid', params),

  // 历史轨迹-车辆网格
  getCarTrackGrid: (params) => axios.post('/hss/carTrack/getCarTrackGrid', params),

  // 历史轨迹-人员轨迹节点
  getPersonTrackNodeList: (params) => axios.post('/hss/employeeTrack/list ', params),

  // 历史轨迹-分页人员轨迹节点
  getPersonTrackNodeListPage: (params) => axios.post('/hss/employeeTrack/selectPage', params),

  // 历史轨迹-人员轨迹、报警、违规汇总
  getAllPersonTrackNodeList: (params) => axios.post('/hss/employeeTrack/alarmAndViolationEmployeeList', params),

  // 历史轨迹-人员网格
  getPersonTrackGrid: (params) => axios.post('/hss/employeeTrack/getEmployeeTrackGrid', params),

  // 历史轨迹-总油耗总里程
  getMileageAndOilWear: (params) => axios.post('hss/carTrack/mileageAndOilWear', params),

  // 车辆作业类型
  getCarWorkType: (params) => axios.post('/hss/elementtotal/getCarJobType', params),

  // 收集点列表
  getBinList: (params) => axios.post('/hss/carTrack/cleanList', params),

  getCarRefuelList: (params) => axios.post('/hss/tloilcostcfg/getList', params), // 一张网-车辆详情-加油记录
  getCarRepairList: (params) => axios.post('/hss/tlrepaircfg/getlist', params), // 一张网-车辆详情-维修记录
}