export default [{
  path: 'grid',
  name: 'grid',
  component: () => import('../../views/admin/work/grid'),
  meta: {
    permission: []
  }
}, {
  path: 'roadClean-crewScheduling',
  name: 'roadClean-crewScheduling',
  component: () => import('../../views/admin/work/roadClean/crewScheduling'),
  meta: {
    permission: []
  }
}, {
  path: 'roadClean-attendance',
  name: 'roadClean-attendance',
  component: () => import('../../views/admin/work/roadClean/attendance'),
  meta: {
    permission: []
  }
}, {
  path: 'roadClean-violation',
  name: 'roadClean-violation',
  component: () => import('../../views/admin/work/roadClean/violation'),
  meta: {
    permission: []
  }
}, {
  path: 'roadClean-work',
  name: 'roadClean-work',
  component: () => import('../../views/admin/work/roadClean/work'),
  meta: {
    permission: []
  }
}, {
  path: 'mechanicalClean-crewScheduling',
  name: 'mechanicalClean-crewScheduling',
  component: () => import('../../views/admin/work/mechanicalClean/crewScheduling'),
  meta: {
    permission: []
  }
}, {
  path: 'mechanicalClean-exception',
  name: 'mechanicalClean-exception',
  component: () => import('../../views/admin/work/mechanicalClean/exception'),
  meta: {
    permission: []
  }
}, {
  path: 'mechanicalClean-work',
  name: 'mechanicalClean-work',
  component: () => import('../../views/admin/work/mechanicalClean/work'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageClean-crewScheduling',
  name: 'garbageClean-crewScheduling',
  component: () => import('../../views/admin/work/garbageClean/crewScheduling'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageClean-exception',
  name: 'garbageClean-exception',
  component: () => import('../../views/admin/work/garbageClean/exception'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageClean-work',
  name: 'garbageClean-work',
  component: () => import('../../views/admin/work/garbageClean/work'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageClean-standard',
  name: 'garbageClean-standard',
  component: () => import('../../views/admin/work/garbageClean/standard'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageTransit-crewScheduling',
  name: 'garbageTransit-crewScheduling',
  component: () => import('../../views/admin/work/garbageTransit/crewScheduling'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageTransit-exception',
  name: 'garbageTransit-exception',
  component: () => import('../../views/admin/work/garbageTransit/exception'),
  meta: {
    permission: []
  }
}, {
  path: 'garbageTransit-work',
  name: 'garbageTransit-work',
  component: () => import('../../views/admin/work/garbageTransit/work'),
  meta: {
    permission: []
  }
}, {
  path: 'worlLog',
  name: 'worlLog',
  component: () => import('../../views/admin/work/worlLog'),
  meta: {
    permission: []
  }
}, ]