import vm from '../../main.js'

export default {
  state: {
    workOrderLevelAllList: [],
    workOrderLevelAllListVisited: false,
    workOrderQuestTypeTreeList: [],
    workOrderQuestTypeTreeListVisited: false,
    emergencyLevelSelectList: [],
    emergencyLevelSelectListVisited: false,
    intendAllList: [],
    intendAllVisiter: false,
    emergencyTypeSelect: [],
    emergencyTypeSelectVisited: false,
    // 巡查案件
    patrolCaseKeyMap: {
      'id': 'caseId',
      'workOrderNumber': 'caseNo',
      'questBigType': 'caseIssueId',
      'questBigTypeName': 'caseIssueName',
      'questSmallType': 'caseIssueChildId',
      'questSmallTypeName': 'caseIssueChildName',
      'site': 'address',
      'lng': 'lng',
      'lat': 'lat',
      'facilityType': 'sheshiTypeId',
      'facilityId': 'sheshiId',
      'facility': 'sheshiName',
      'assignId': 'personId',
      'assign': 'personName',
      'platformAssign': 'isPlatformAssign',
      'level': 'caseLevelId',
      'levelName': 'caseLevelName',
      'time': 'timelimit',
      'CCId': 'ccUid',
      'CC': 'ccName',
      'sendSms': 'isSendSms',
      'desc': 'des',
      'picture': 'imgUrl',
      'laterPicture': 'imgUrlOk',
      'guideboard': 'isGuideboard',
      'isOverdue': 'isOverdue',
      'createTime': 'createTime',
      'createUid': 'createUid',
      'createName': 'createName',
      'status': 'status',
      'caseProcesses': 'caseProcesses',
      'consumingTime': 'consumingTime',
      'remark': 'remark'
    },
    // 应急等级
    emergencyLevelKeyMap: {
      id: 'id',
      level: 'level',
      status: 'status',
      color: 'color',
      remark: 'remark'
    },
    // 应急类型
    emergencyTypeKeyMap: {
      id: 'id',
      eventType: 'eventtype',
      remark: 'remark',
      status: 'status'
    },
    // 应急预案
    emergencyPlanKeyMap: {
      resplanName: 'resplanname',
      emergenttypeid: 'emergenttypeid',
      emergentlevelid: 'emergentlevelid',
      respondtime: 'respondtime',
      dealtime: 'dealtime',
      status: 'status',
      remark: 'remark',
      carList: 'cardateils',
      personList: 'empdateils',
      id: 'id',
      ids: 'ids',
    },
    // 应急计划
    emergencyPlanningKeyMap: {
      'id': 'id', //id
      'ids': 'ids', //id
      'name': 'planname', //计划名称
      'principalId': 'dutyperson', //责任人
      'principal': 'dutypersonname', //责任人
      'contingencyPlan': 'resplan', //应急预案
      'planPicture': 'jpgfileurl', //图片附件地址
      // 'voicefileurl': '', //音频附件地址
      // 'videofileurl': '', //视频附件地址
      'desc': 'detail', //现场描述
      'lng': 'workplacelon', //作业位置坐标 经度
      'lat': 'workplacelat', //作业位置坐标 维度
      'address': 'workplacedetail', //作业位置描述
      'gridId': 'gridId', //作业网格
      'gridName': 'gridname',
      'startTime': 'starttime',
      'endTime': 'endtime',
      'carList': 'cardetails',
      'personList': 'empdetails'
    }
  },
  getters: {
    // 获取工单等级所有列表
    getWorkOrderLevelAllList: state => state.workOrderLevelAllList,
    // 获取工单问题类型所有列表
    getWorkOrderQuestTypeTreeList: state => state.workOrderQuestTypeTreeList,
    // 获取应急等级
    getEmergencyLevelSelectList: state => state.emergencyLevelSelectList,
    // 获取应急类型
    getEmergencyTypeSelectList: state => state.emergencyTypeSelect,
    // 获取预案下拉框
    getIntendAllList: state => state.intendAllList,
  },
  mutations: {
    // 设置工单等级所有列表
    setWorkOrderLevelAllList(state, arr) {
      state.workOrderLevelAllList = arr
      state.workOrderLevelAllListVisited = true
    },
    // 设置工单问题类型所有列表
    setWorkOrderQuestTypeTreeList(state, arr) {
      state.workOrderQuestTypeTreeList = arr
      state.workOrderQuestTypeTreeListVisited = true
    },
    // 获取应急等级
    setEmergencyLevelSelectList(state, arr) {
      state.emergencyLevelSelectList = arr
      state.emergencyLevelSelectListVisited = true
    },
    // 应急类型
    setEmergencyTypeSelect(state, arr) {
      state.emergencyTypeSelect = arr
      state.emergencyTypeSelectVisited = true
    },
    // 预案下拉框
    setIntendAllList(state, arr) {
      state.intendAllList = arr
      state.intendAllVisited = true
    },
  },
  actions: {
    // 更新工单等级所有列表
    updateWorkOrderLevelAllList({
      commit,
      state
    }, ignorant) {
      if (!state.workOrderLevelAllListVisited || ignorant) {
        vm.$http.getWorkOrderLevelAllList().then(res => {
          if (res.code === 200) {
            commit('setWorkOrderLevelAllList', res.result)
          }
        })
      }
    },
    // 更新工单问题类型所有列表
    updateWorkOrderQuestTypeTreeList({
      commit,
      state
    }, ignorant) {
      if (!state.workOrderQuestTypeTreeListVisited || ignorant) {
        vm.$http.getWorkOrderQuestTypeTreeList().then(res => {
          if (res.code === 200) {
            commit('setWorkOrderQuestTypeTreeList', res.result)
          }
        })
      }
    },
    // 新增/修改巡查案件
    createOrEditPatrolCase({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.patrolCaseKeyMap)
      if (params.caseId) {
        return new Promise((resolve, reject) => {
          vm.$http.editPatrolCase(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createPatrolCase(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    // 获取巡查案件详情
    getPatrolCaseDetail({
      commit,
      state
    }, caseId) {
      return new Promise((resolve, reject) => {
        vm.$http.getPatrolCaseDetail({
          caseId
        }).then(res => {
          if (res.code === 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.patrolCaseKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 指派巡查案件
    assignPatrolCase({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.patrolCaseKeyMap)
      return new Promise((resolve, reject) => {
        vm.$http.assignPatrolCase(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 改派巡查案件
    reassignmentPatrolCase({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.patrolCaseKeyMap)
      return new Promise((resolve, reject) => {
        vm.$http.reassignmentPatrolCase(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 处理巡查案件
    managePatrolCase({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.patrolCaseKeyMap)
      return new Promise((resolve, reject) => {
        vm.$http.managePatrolCase(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 应急等级下拉
    updateEmergencyLevelSelectList({
      commit,
      state
    }, ignorant) {
      if (!state.emergencyLevelSelectListVisited || ignorant) {
        vm.$http.getEmergencyLevelSelectList().then(res => {
          if (res.code === 200) {
            commit('setEmergencyLevelSelectList', res.result)
          }
        })
      }
    },
    // 更新预案下拉框
    updateIntendAllList({
      commit,
      state
    }, ignorant) {
      if (!state.intendAllVisited || ignorant) {
        vm.$http.getEmergencyPlanSelectList({
          status: '1'
        }).then(res => {
          if (res.code === 200) {
            commit('setIntendAllList', res.result)
          }
        })
      }
    },
    // 新增/修改应急等级
    cearteOrEditEmergencyLevel({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.emergencyLevelKeyMap)
      }
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEmergencyLevel(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createdEmergencyLevel(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    // 获取应急等级详情
    getEmergencyLevelDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEmergencyLevelDetail({
          id
        }).then(res => {
          if (res.code == 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.emergencyLevelKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 应急类型下拉
    updateEmergencyTypeSelect({
      commit,
      state
    }, ignorant) {
      if (!state.emergencyTypeSelectVisited || ignorant) {
        vm.$http.getEmergencyTypeSelectList().then(res => {
          if (res.code === 200) {
            commit('setEmergencyTypeSelect', res.result)
          }
        })
      }
    },
    // 新增/修改应急类型
    cearteOrEditEmergencyType({
      state
    }, form) {
      let params = {
        data: vm.$keyMapNew(form, state.emergencyTypeKeyMap)
      }
      console.log(params);
      if (params.data.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEmergencyType(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createdEmergencyType(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    // 获取应急类型详情
    getEmergencyTypeDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEmergencyTypeDetail({
          id
        }).then(res => {
          if (res.code == 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.emergencyTypeKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改应急预案
    cearteOrEditEmergencyPlan({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.emergencyPlanKeyMap)
      if (params.id) {
        return new Promise((resolve, reject) => {
          console.log(params);
          vm.$http.editEmergencyPlan(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createdEmergencyPlanGetList(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    disableBatchEmergencyPlan({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.emergencyPlanKeyMap)
      return new Promise((resolve, reject) => {
        console.log(params);
        vm.$http.disableBatchEmergencyPlan(params).then(res => {
            vm.$Message.info(res.message)
            if (res.code === 200) {
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取应急预案详情
    getEmergencyPlanDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEmergencyPlanDetail({
          id
        }).then(res => {
          console.log(res, '应急预案详情');
          if (res.code == 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.emergencyPlanKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
    // 新增/修改 应急计划
    cearteOrEditEmergencyPlanning({
      state
    }, form) {
      let params = vm.$keyMapNew(form, state.emergencyPlanningKeyMap)
      //   data: vm.$keyMapNew(form, state.emergencyPlanningKeyMap)
      // }
      if (params.id) {
        return new Promise((resolve, reject) => {
          vm.$http.editEmergentPlanning(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          vm.$http.createdEmergentPlanning(params).then(res => {
              vm.$Message.info(res.message)
              if (res.code === 200) {
                resolve()
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    // 获取应急计划详情
    getEmergencyPlanningDetail({
      state
    }, id) {
      return new Promise((resolve, reject) => {
        vm.$http.getEmergentPlanningDetail({
          id
        }).then(res => {
          if (res.code == 200) {
            let data = res.result
            let newKeyMap = vm.$swapKeyValue(state.emergencyPlanningKeyMap)
            let newData = vm.$keyMapNew(data, newKeyMap)
            resolve(newData)
          } else {
            reject(res)
          }
        })
      })
    },
  },
}