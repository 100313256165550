<template>
  <Menu ref="menu"
        :width="width"
        :theme="theme"
        :accordion="accordion"
        :active-name="activeName"
        :open-names="openName"
        class="sliderMenu">
    <template v-for="(item,index) in data">
      <template v-if="item.children&&item.children.length>0">
        <Submenu :name="item.name"
                 :key="index">
          <template slot="title">
            <Icon :custom="'i-icon '+item.icon" />
            {{item.title}}
          </template>
          <template v-for="(items,indexs) in item.children">
            <MenuItem :name="items.name"
                      :to="items.url"
                      replace
                      :target="items.target||'_self'"
                      :key="indexs">{{items.title}}</MenuItem>
          </template>
        </Submenu>
      </template>
      <template v-else>
        <MenuItem :name="item.name"
                  :to="item.url"
                  replace
                  :target="item.target||'_self'"
                  :key="index">
        <Icon :custom="'i-icon '+item.icon" />
        {{item.title}}</MenuItem>
      </template>
    </template>
  </Menu>
</template>

<script>
export default {
  props: {
    data: Array,
    openName: Array,
    activeName: String
  },
  data () {
    return {
      width: '176px',
      theme: 'dark',
      accordion: true,
    }
  },
  watch: {
    openName () {
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
      })
    },
    activeName () {
      this.$nextTick(() => {
        this.$refs.menu.updateActiveName();
      })
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
  }
}
</script>

<style lang="scss" scoped>
.sliderMenu {
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  background: #2e3034;
  ::v-deep {
    .ivu-menu {
      background: #222930;
    }
    .ivu-menu-item-active {
      color: #fff !important;
      background: #25bb96 !important;
    }
  }
}
</style>
