import axios from '../http.interceptor'

export default {
  // 获取部门树列表
  getDepartmentTreeList: () => axios.post('/hss/sysdepart/getTreeList'),

  // 获取部门列表
  getDepartmentList: (params) => axios.post('/hss/sysdepart/getDepartList', params),

  // 获取部门详情
  getDepartmentDetail: (params) => axios.post('/hss/sysdepart/getDepartById', params),

  // 新增部门
  createDepartment: (params) => axios.post('/hss/sysdepart/insertDepart', params),

  // 修改部门
  editDepartment: (params) => axios.post('/hss/sysdepart/updateDepart', params),

  // 删除部门
  deleteDepartment: (params) => axios.post('/hss/sysdepart/deleteDepartById', params),
  
}