export default [{
  path: 'patrolCase',
  name: 'patrolCase',
  component: () => import('../../views/admin/patrolMaintain/patrolCase'),
  meta: {
    permission: []
  }
}, {
  path: 'workViolations',
  name: 'workViolations',
  component: () => import('../../views/admin/patrolMaintain/workViolations'),
  meta: {
    permission: []
  }
}, {
  path: 'runAlarm',
  name: 'runAlarm',
  component: () => import('../../views/admin/patrolMaintain/runAlarm'),
  meta: {
    permission: []
  }
}, {
  path: 'emergencyDispatch',
  name: 'emergencyDispatch',
  component: () => import('../../views/admin/patrolMaintain/emergencyDispatch'),
  meta: {
    permission: []
  }
}, {
  path: 'thirdSystem',
  name: 'thirdSystem',
  component: () => import('../../views/admin/patrolMaintain/thirdSystem'),
  meta: {
    permission: []
  }
}, ]