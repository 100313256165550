import axios from 'axios'
import md5 from 'js-md5'
import config from '@/config/constantconfig'

// 设施视频点播关闭接口
export const closeFlacDemandVideo = (item) => {
  console.log(item);
  let appId = config.faclVideoAppid
  let deviceId = item.equipcode
  let channel = item.channelid
  let timestamp = new Date().getTime()
  let params = {
    appId,
    deviceId,
    channel,
    timestamp,
  }
  let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&timestamp=${timestamp}&secret=123456`)
  params.sign = sign
  axios.post(`${config.httpVideoConfig}/api/playNVR/close`, params).then(res => {
    if (res.code === 200) {
      // this.$Message.info(res.msg)
    } else {
      // this.$Message.info(res.msg)
    }
  })
}